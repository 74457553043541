import "./index.less";

import vsf, { definePage } from "@vs/vsf-boot";
import classNames from "classnames";
import dayjs from "dayjs";
import * as echarts from "echarts";
import React, { useEffect, useRef, useState } from "react";

import bg from "@/assets/images/bg.png";
import down from "@/assets/images/down.png";
import empty from "@/assets/images/empty.png";
import Jump from "@/assets/images/Jump.png";
import righ from "@/assets/images/righ.png";
import trend from "@/assets/images/trend.png";
import up from "@/assets/images/up.png";
import warn from "@/assets/images/warn.png";
import line from "@/assets/images/line.png";
import {
  echartsColor,
  echartsColorList,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
} from "@/config";
import ContentHeader from "@/module/ContentHeader";
import GuideBtn from "@/module/GuideBtn";
import { handleToFixed } from "@/utils";
import { Breadcrumb } from "antd";

const ProductionManagement = (props) => {
  const { global } = props?.stores || {};
  const { isLoading, startLoading, stopLoading, userInfo } = global || {};
  const [date, setDate] = useState(
    props.location.state?.date || dayjs()?.format("YYYY-MM")
  );
  const [data, setData] = useState<any>({});
  const [currentTab, setCurrentTab] = useState({
    key: props.routes.query.id || "1",
  });
  const [thirdData, setThirdData] = useState<any>();
  const [mouseoverBar, setMouseoverBar] = useState<any>();
  const [barData, setBarData] = useState<any>();
  const [materialData, setMaterialData] = useState<any>();

  const barRef = useRef<any>();
  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYY-MM"));
  };
  const initData = () => {
    startLoading();
    Promise.all(
      [
        handleQueryMainIndexManagerEo(date),
        currentTab?.key === "1" && handleQueryProductPage(date),
        currentTab?.key === "2" && handleQueryMaterialPurchaseTab(date),
        currentTab?.key === "3" && handleQueryWorkshopProductionPage(date),
        currentTab?.key === "4" && handleQueryStockPage(date),
      ].filter((item) => item)
    ).finally(() => {
      stopLoading();
    });
  };
  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, currentTab]);

  useEffect(() => {
    if (barData) {
      handleBarChart(
        "TabCotent1-bottom-chart",
        "bar",
        barData?.xData,
        barData?.barList,
        "售后厂区库存",
        45
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barData, mouseoverBar]);
  useEffect(() => {
    if (materialData) {
      handleBarChart(
        "TabCotent4-chart",
        "line",
        materialData?.xData,
        materialData?.barList,
        "采购完成率趋势"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialData]);
  barRef?.current?.on("mouseover", function (params) {
    if (params.componentType === "series") {
      // params.seriesType === 'bar' 表示触发的是柱状图的事件
      if (params.seriesType === "bar") {
        // params.dataIndex 表示鼠标当前移入的是哪个柱子
        console.log("鼠标移入了柱子", params?.name);
        setMouseoverBar(params?.name);
        // 你可以在这里编写你需要执行的逻辑
      }
    }
  });
  barRef?.current?.on("mouseout", function (params) {
    if (params.componentType === "series") {
      // params.seriesType === 'bar' 表示触发的是柱状图的事件
      if (params.seriesType === "bar") {
        // params.dataIndex 表示鼠标当前移出的是哪个柱子
        console.log("鼠标移出了柱子", params.dataIndex);
        setMouseoverBar("");
        // 你可以在这里编写你需要执行的逻辑
      }
    }
  });
  const handleChart = (
    id,
    chartX: any = [],
    dataChart: any = [],
    grid: any = "10%"
  ) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 24,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        // selectedMode: true,
        selectedMode: false,
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 0,
        containLabel: true,
        top: 80,
        right: 0,
        bottom: 0,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: dataChart,
    };
    dom && option && myChart.setOption(option);
  };

  const handleBarChart = (
    id,
    type: string,
    chartX: any = [],
    dataChart: any = [],
    name?: string,
    rotate?: number
  ) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    barRef.current = myChart;
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },

      legend: {
        show: false,
        left: 0,
        top: 24,
        itemWidth: 12,
        itemHeight: 12,
        selectedMode: false,
        icon: "rect",
      },
      grid: {
        left: 0,
        containLabel: true,
        top: 40,
        right: 0,
        bottom: 40,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("M月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate,
        },
      },
      yAxis: [
        {
          type: "value",
          axisTick: {
            lineStyle: {
              color: echartsXYColor,
            },
          },
          axisLabel: {
            formatter: "{value}",
            color: echartsXYColor,
            fontSize: echartsXYSize,
          },
          splitNumber: 4,
        },
        {
          type: "value",
          splitNumber: 4,
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}%",
            color: echartsXYColor,
            fontSize: echartsXYSize,
          },
        },
      ],
      series: [
        {
          data: dataChart[0],
          type,
          smooth: true,
          showSymbol: false,
          name,
          lineStyle: {
            // color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // opacity: 0.1,
          },
          itemStyle: {
            // color: echartsColor?.echartsColor_0052D9,
            opacity: mouseoverBar ? 0.5 : 1,
          },

          emphasis: {
            itemStyle: {
              // color: echartsColor?.echartsColor_0052D9,
              opacity: 1,
            },
          },
          barWidth: 18,
        },
        // {
        //   data: dataChart[1],
        //   type: 'line',
        //   yAxisIndex: 1,
        //   smooth: true,
        //   showSymbol: false,
        //   name: '排产率',
        //   tooltip: {
        //     valueFormatter: function (value) {
        //       return value + '%';
        //     }
        //   },
        //   itemStyle: {
        //     color: echartsColor?.echartsColor_EF7E60,
        //   },
        //   lineStyle: {
        //     color: echartsColor?.echartsColor_EF7E60,
        //     width: 2,
        //   },
        // },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const handleClickTab = (value: { key: number }) => {
    setCurrentTab(value);
    props.history.replace(`/dashboard/production/overview?id=${value.key}`);
  };

  const handleQueryProductPage = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryProductPage_b73e46({
        yearAndMonth: dayjs(date)?.format("YYYYMM"),
      });

    if (data?.code === 200) {
      const chartX = data?.data?.afterStockLine?.map(
        (v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chartBarData = data?.data?.afterStockLine?.map((v) => {
        return {
          value: handleToFixed(v?.proportion, 2),
          itemStyle: {
            color:
              v?.proportion > 0 ? echartsColor?.echartsColor_0052D9 : "#FF8B38",
          },
        };
      });

      const prodPlannedRate = data?.data?.prodPlannedRateLine?.map((v) =>
        handleToFixed(v?.proportion * 100, 2)
      );

      const requirePlanLine = {
        data: data?.data?.requirePlanLine?.map((v) =>
          handleToFixed(v?.proportion, 2)
        ),
        name: "营管中心要货计划",
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          // color: echartsColor?.echartsColor_2FC25B, // 设置折线的颜色为红色
          width: 2, // 设置折线宽度为 2
          // ... 可以设置更多线条样式属性
        },
      };
      const stockCountLine = {
        data: data?.data?.stockCountLine?.map((v) =>
          handleToFixed(v?.proportion, 2)
        ),
        name: "出库量",
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          // color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
          width: 2, // 设置折线宽度为 2
          // ... 可以设置更多线条样式属性
        },
      };
      const stockLine = {
        data: data?.data?.stockLine?.map((v) =>
          handleToFixed(v?.proportion, 2)
        ),
        name: "库存量",
        type: "bar",
        barWidth: 18,
      };
      // console.log(chartBarData, chartX, [requirePlanLine, stockCountLine]);
      setBarData({ barList: [chartBarData, prodPlannedRate], xData: chartX });
      handleChart(
        "TabCotent1-top-chart",
        chartX,
        [requirePlanLine, stockCountLine, stockLine],
        90
      );
    }
  };
  const handleQueryMaterialPurchaseTab = async (date) => {
    const data = await vsf?.services?.queryMaterialPurchaseTab({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });

    if (data?.code === 200) {
      const chartX = data?.data?.completeLine?.map(
        (v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chartBarData = data?.data?.completeLine?.map((v) => {
        return {
          value: handleToFixed(v?.proportion * 100, 2),
        };
      });
      // console.log(chartBarData, chartX, [requirePlanLine, stockCountLine]);
      setMaterialData({ ...data.data, barList: [chartBarData], xData: chartX });
    }
  };
  const handleQueryWorkshopProductionPage = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryWorkshopProductionPage_32655e(
        {
          yearAndMonth: dayjs(date)?.format("YYYYMM"),
        }
      );

    if (data?.code === 200) {
      console.log(data, "----");
      setThirdData(data?.data);
      const chartX = data?.data?.capacityProportion?.map(
        (v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chartDataLits = [
        {
          value: "settingCount",
          name: "配制",
          color: echartsColor?.echartsColor_E68F58,
        },
        {
          value: "bottleCount",
          name: "灌装",
          color: echartsColor?.echartsColor_1890FF,
        },
        {
          value: "packageCount",
          name: "包装",
          color: echartsColor?.echartsColor_CF63D8,
        },
      ];

      handleChart(
        "TabCotent2-left-chart",
        chartX,
        renderChart(data?.data?.capacityProportion, chartDataLits, 2, 100)
      );
      handleChart(
        "TabCotent2-rigth-chart",
        chartX,
        renderChart(data?.data?.capacityMaxProportion, chartDataLits, 2)
      );
    }
  };

  const handleQueryStockPage = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryStockPage_ba75a9({
        yearAndMonth: dayjs(date)?.format("YYYYMM"),
      });

    if (data?.code === 200) {
      console.log(data, "----");
      const chartX = data?.data?.map(
        (v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chartList = [
        {
          value: "inStock",
          name: "入库量",
          color: echartsColor?.echartsColor_6F95F8,
        },
        {
          value: "outStock",
          name: "出库量",
          color: echartsColor?.echartsColor_0052D9,
        },
      ];
      const chartData = chartList?.map((v) => {
        return {
          data: data?.data?.map((item) => Math?.round(item?.[v?.value])),
          name: v?.name,
          type: "line",
          smooth: true,
          showSymbol: false,
          lineStyle: {
            // color: v?.color, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        };
      });
      const stockLine = {
        data: data?.data?.map((v) => Math?.round(v.stock)),
        name: "库存量",
        type: "bar",
        barWidth: 18,
      };

      console.log(chartData, "chartData");
      handleChart("TabCotent3-charts", chartX, [...chartData, stockLine]);
    }
  };

  const handleQueryMainIndexManagerEo = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryMainIndexManagerEo_f767f3(
        {
          yearAndMonth: dayjs(date)?.format("YYYYMM"),
        }
      );
    if (data?.code === 200) {
      console.log(data);
      setData(data?.data);
    }
  };

  const renderChart = (data, list, tofixed = 2, num = 1) => {
    return list?.map((v) => {
      return {
        data: data?.map((item) =>
          handleToFixed(item?.[v?.value] * num, tofixed)
        ),
        name: v?.name,
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          // color: v?.color, // 设置折线的颜色为红色
          width: 2, // 设置折线宽度为 2
          // ... 可以设置更多线条样式属性
        },
      };
    });
  };
  const handleToCost = () => {
    vsf.history.push("/dashboard/production/lidu_workshop", {
      date: dayjs(date)?.format("YYYY-MM"),
    });
  };
  // handleToFixed()
  const rendrTab = (value) => {
    return (
      <div
        //   className="tab"
        className={classNames(`tab`, {})}
      >
        <div
          // className="title"
          className={classNames("title", {
            activeTitle: currentTab?.key == value?.key,
          })}
        >
          <div>
            {/* <span></span> */}
            {value?.title}
            {value?.key == "3" && <div onClick={handleToCost}>下钻分析</div>}
          </div>
        </div>
        <div
          className={classNames("tab-content", {
            activeTab: currentTab?.key == value?.key,
          })}
          onClick={() => {
            handleClickTab(value);
          }}
        >
          {/* <div className="warn">
            {value?.isWarn && (
              <>
                <img src={warn}></img>
                完成率低，描述描述
              </>
            )}
          </div> */}
          <div className="rate">
            {value?.rate}
            <span>{value?.unit}</span>
          </div>
          <div className="rate-name">{value?.rateName}</div>
          <div className="rate-text">{value?.bottom}</div>
        </div>
      </div>
    );
  };

  const renderTabCotent = () => {
    return (
      <div className="TabCotent1">
        <div className="TabCotent1-top">
          <div className="TabCotent-header">
            <div>营管中心要货计划 vs 出库量</div>
            <div className="unit">单位：件</div>
          </div>
          <div className="TabCotent1-top-chart" id="TabCotent1-top-chart"></div>
        </div>
        <div className="TabCotent1-bottom">
          <div className="TabCotent-header">
            <div>售后厂区库存</div>
            <div className="unit">单位：件</div>
            <div className="unit">
              指标计算逻辑 = 生产计划 + 在制品 + 库存 - 要货计划 - 指令未发
            </div>
          </div>
          <div
            className="TabCotent1-top-chart"
            id="TabCotent1-bottom-chart"
          ></div>
        </div>
      </div>
    );
  };
  const renderTabCotent2 = () => {
    return (
      <div className="TabCotent2">
        <div className="TabCotent2-top">
          <div className="left">
            <div className="left-header">当月产能</div>
            <div className="num">
              {thirdData?.capacity}
              <span>件</span>
            </div>
            <div className="unit">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={thirdData?.capacityCompare > 0 ? up : down}
              ></img>
              <span>
                {handleToFixed(Math?.abs(thirdData?.capacityCompare) * 100, 2)}%
              </span>
            </div>
          </div>
          <div className="left">
            {" "}
            <div className="left-header">历史最大产能</div>
            <div className="num">
              {thirdData?.historyCapacity}
              <span>件</span>
            </div>
            <div className="unit">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={thirdData?.historyCapacityCompare > 0 ? up : down}
              ></img>
              <span>
                {handleToFixed(
                  Math?.abs(thirdData?.historyCapacityCompare) * 100,
                  2
                )}
                %
              </span>
            </div>
          </div>
        </div>
        <div className="TabCotent2-bottom">
          <div className="left">
            <div className="TabCotent-header">
              <div>产能利用率</div>
              <div className="unit">
                口径说明：当月产能÷截止当月最大产能<span>单位：%</span>
              </div>
            </div>
            <div
              className="TabCotent2-left-chart"
              id="TabCotent2-left-chart"
            ></div>
          </div>
          <div className="left">
            <div className="TabCotent-header">
              <div>各工序历史最大日均产能</div>
              <div className="unit">单位：件</div>
            </div>
            <div
              className="TabCotent2-left-chart"
              id="TabCotent2-rigth-chart"
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const renderTabCotent3 = () => {
    return (
      <div className="TabCotent3">
        <div className="TabCotent-header">
          <div>成品出入库对比</div>
          <div className="unit">单位：件</div>
        </div>
        <div className="TabCotent3-charts" id="TabCotent3-charts"></div>
      </div>
    );
  };

  const renderTabCotent4 = () => {
    return (
      <div className="TabCotent4">
        {/* <div className="empty">
          <img src={empty}></img>
          <div>正在建设中，敬请期待</div>
        </div> */}
        <div className="card">
          <div className="card-item">
            <div className="label">采购达标的物料种类数量</div>
            <div className="num-box">
              <span className="num">
                {handleToFixed(materialData?.complianceCount, 0)}
              </span>
              <span className="unit">个</span>
            </div>
          </div>
          <img src={line} className="line"></img>
          <div className="card-item">
            <div className="label">应采购的物料种类数量</div>
            <div className="num-box">
              <span className="num">
                {handleToFixed(materialData?.shouldCount, 0)}
              </span>
              <span className="unit">个</span>
            </div>
          </div>
        </div>
        <div className="TabCotent4-bottom">
          <div className="TabCotent-header">
            <div>采购完成率趋势</div>
            <div className="unit">单位：%</div>
            <div className="unit">
              口径：采购达标的物料种类数量/应采购的物料种类数量
            </div>
          </div>
          <div className="num-box">
            <span className="num">
              {handleToFixed(data?.materialPurchase * 100, 2)}
            </span>
            <span className="unit">%</span>
          </div>
          <div className="TabCotent4-chart" id="TabCotent4-chart"></div>
        </div>
      </div>
    );
  };

  const handleNavgate = () => {
    vsf.navigateTo("/dashboard/overview");
  };

  const breadcrumb = () => {
    return (
      <Breadcrumb separator=">" style={{ fontSize: 16 }}>
        <Breadcrumb.Item>
          <a onClick={handleNavgate} style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            生产管理
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span style={{ fontWeight: 600 }}>生产分析</span>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  return (
    <div className="ProductionManagement-container">
      <ContentHeader
        value={date}
        defaultValue={dayjs(date)}
        show={""}
        onChange={handleDateChange}
        title={breadcrumb()}
        info="涪陵制药厂生产情况全生命周期分析，包括生产排产、物料采购、车间生产、仓储入库及流向市场。"
      />
      <div className="ProductionManagement-content">
        {/* <div className="GuideBtn">
          隐藏分析指引
          <GuideBtn title={''} renderContent={''} value={''}></GuideBtn>
        </div> */}
        <div className="ProductionManagement-header">
          <div className="header-center">
            <div className="ProductionManagement-rate">
              {/* <img src={trend}></img>
            <div>累计下单达成率</div> */}
              <div className="icon">
                <img src={trend}></img>
                <div>累计下单达成率</div>
              </div>
              <div className="rate-unit">
                <div>{handleToFixed(data?.annualPlanRate * 100, 1)}</div>
                <div>%</div>
              </div>
              <div className="year">
                较上年
                <img
                  style={{ marginInline: 4 }}
                  src={data?.annualPlanRateCompare > 0 ? up : down}
                ></img>
                <span>
                  {handleToFixed(data?.annualPlanRateCompare * 100, 1)}%
                </span>
              </div>
            </div>
          </div>
          {/* <GuideBtn title={''} renderContent={''} value={''}></GuideBtn> */}
        </div>
        <img src={bg} className="bg"></img>
        {/* <div className="ProductionManagement-rate">
          <div className="rate-unit">
            <div>{handleToFixed(data?.annualPlanRate, 1)}</div>
            <div>%</div>
          </div>
          <div className="year">
            较上年
            <img
              style={{ marginInline: 4 }}
              src={data?.annualPlanRateCompare > 0 ? up : down}
            ></img>
            <span>{handleToFixed(data?.annualPlanRateCompare, 1)}%</span>
          </div>
        </div> */}
        <div className="ProductionManagement-content-flex">
          <div className="header"></div>
          <div className="content-flex-tab">
            {rendrTab({
              isWarn: false,
              rate: handleToFixed(data?.deliveryRate * 100, 2) ?? 0,
              rateName: "排产率",
              bottom: "生产部",
              unit: "%",
              title: "生产排产",
              key: "1",
            })}
            <div className="img-div">
              <img src={righ} className="righ-img"></img>
            </div>

            {rendrTab({
              isWarn: false,
              rate: handleToFixed(data?.materialPurchase * 100, 2) ?? 0,
              rateName: "采购完成率",
              bottom: "采购部",
              unit: "%",
              title: "物料采购",
              key: "2",
            })}
            <div className="img-div">
              <img src={righ} className="righ-img"></img>
            </div>
            {rendrTab({
              isWarn: false,
              rate: handleToFixed(data?.workshopProduction * 100, 2),
              rateName: "最差周生产计划完成率",
              bottom: "生产部、车间、设备部",
              unit: "%",
              title: "车间生产",
              key: "3",
            })}
            <div className="img-div">
              <img src={righ} className="righ-img"></img>
            </div>
            {rendrTab({
              isWarn: false,
              rate: Math?.round(data?.stock),
              rateName: "库存量",
              bottom: "生产部、物流部",
              unit: "件",
              title: "仓储入库,流入市场",
              key: "4",
            })}
          </div>
          {currentTab?.key == "1" && renderTabCotent()}
          {currentTab?.key == "3" && renderTabCotent2()}
          {currentTab?.key == "4" && renderTabCotent3()}
          {currentTab?.key == "2" && renderTabCotent4()}
        </div>
      </div>
    </div>
  );
};
export default definePage(ProductionManagement);
