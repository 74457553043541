import { initPager } from "../../utils";
import { Select, Table, TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { MointorContext } from "../..";
import { getNumPercent, transNumToFixed } from "../../utils";
import "./index.less";
import { getMonthSummary } from "../../services";
import { isNil } from "lodash";

const filters = [
  { label: "全部", value: "all" },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
];

interface IColumns {
  key: React.Key;
  month: number;
  monthProdValue: number;
  monthlyPlan: number;
  monthlyPlanFinishRate: number;
  prodSpec: string;
  product: string;
  specification: string;
  stockInPack: number;
  stockInTenthsd: number;
  year: number;
}

const getClassNames = (className) => `product-monitor-summary-${className}`;

const SummaryByMonth = () => {
  const { year, specs } = useContext(MointorContext);
  const [pager, setPager] = useState(initPager);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [orderBy, setOrderBy] = useState({ col: "month", od: 1 });
  const [month, setMonth] = useState("all");

  useEffect(() => {
    queryMonthSummary(initPager, orderBy, month);
    setPager(initPager);
  }, [year, specs]);

  const queryMonthSummary = (
    page: { current: number; pageSize: number },
    orderBy: { col: string; od: number },
    month: string
  ) => {
    setLoading(true);
    getMonthSummary({
      year,
      specs,
      page,
      month: month === "all" ? "" : month,
      ...orderBy,
    })
      .then((res: any) => {
        setTotal(res.data.total);
        setDataSource(res.data.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<IColumns> = [
    {
      title: "年",
      dataIndex: "year",
      key: "year",
      width: 80,
      fixed: "left",
    },
    {
      title: "月份",
      dataIndex: "month",
      key: "month",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: true,
      width: 80,
      fixed: "left",
    },
    {
      title: "品规",
      dataIndex: "prodSpec",
      key: "prodSpec",
      fixed: "left",
    },
    {
      title: "品种",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "规格",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: "当月总入库（万瓶/万盒）",
      dataIndex: "stockInTenthsd",
      key: "stockInTenthsd",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 220,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "当月总入库（件）",
      dataIndex: "stockInPack",
      key: "stockInPack",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "当月总产值（万元）",
      dataIndex: "monthProdValue",
      key: "monthProdValue",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "月计划完成率（%）",
      dataIndex: "monthlyPlanFinishRate",
      key: "monthlyPlanFinishRate",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 180,
      fixed: "right",
      render: (val: number) => {
        const color = isNil(val) ? "#999" : val >= 1 ? "#2BA471" : "#f00";
        return (
          <span style={{ color }}>{getNumPercent(val, false) || "-"}</span>
        );
      },
    },
  ];

  const handleTableChange: TableProps<IColumns>["onChange"] = (
    pagination,
    filter,
    sort,
    { action }
  ) => {
    if (action === "sort") {
      const { columnKey, order } = sort as any;
      const _orderBy: any = {
        col: columnKey,
        od: order === "ascend" ? 0 : order === "descend" ? 1 : null,
      };

      setPager(initPager);
      setOrderBy(_orderBy);
      queryMonthSummary(initPager, _orderBy, month);
    }
    if (action === "paginate") {
      const { current, pageSize } = pagination as any;
      setPager({
        current,
        pageSize,
      });
      queryMonthSummary({ current, pageSize }, orderBy, month);
    }
  };

  const handleSelectMonth = (val: string) => {
    setMonth(val);
    setPager(initPager);
    queryMonthSummary(initPager, orderBy, val);
  };

  return (
    <div className={getClassNames("by-month")}>
      <div className={getClassNames("by-month-header")}>
        <div>月产值统计</div>
        <div style={{ fontSize: 14, fontWeight: 400 }}>
          请选择月份：
          <Select
            options={filters}
            value={month}
            onChange={handleSelectMonth}
            style={{ width: 80 }}
          />
        </div>
      </div>
      <div className={getClassNames("by-month-tablewrap")}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
          pagination={{
            total,
            current: pager.current,
            pageSize: pager.pageSize,
          }}
        />
        {total > 0 && (
          <div className={getClassNames("by-month-total")}>
            共 {total} 项数据
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryByMonth;
