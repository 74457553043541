import { createServices } from "@vs/vsf-boot";
export default createServices({
  queryMarketFeedBack: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-market-feed-back",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  queryDersonCount: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-person-count",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  queryEnumselectcount: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-enum-select-count",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  manufacturingCost: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-total-manufacturing-cost",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  costByDonth: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-total-manufacturing-cost-by-month",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  queryPurchasePage: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-purchase-page",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  queryEnergyOverview: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-energy-overview",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  factorySelect: {
    method: "post",
    url: "/api/data-overview-entrance-web/factory-select",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  energySelect: {
    method: "post",
    url: "/api/data-overview-entrance-web/energy-select",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  overviewEnergy: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-energy-overview-energy",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  overviewFactory: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-energy-overview-factory",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  factoryAnalyze: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-li-du-factory-analyze",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  energyAnalyze: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-energy-analyze",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  energyAnalyzePie: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-energy-analyze-pie",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  electricityPage: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-electricity-page",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  electricityPagePie: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-electricty-analyze-pie",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  gasPage: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-nature-gas-page",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  queryHumanEfficiencyTab: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-human-efficiency-tab",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  queryMaterialPurchaseTab: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-material-purchase-tab",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  querySecurityCompliancePage: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-security-compliance-page",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 数据下载
  expoty: {
    method: "post",
    url: "/api/DataImportExport/expoty",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 数据上传
  dataImport: {
    method: "post",
    url: "/api/data-overview-entrance-web/data-import",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 数据上传分页
  importRecord: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-data-import-record-vo",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  // 是否存在相同数据文档
  dataIsExits: {
    method: "post",
    url: "/api/data-overview-entrance-web/data-is-exits",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  select: {
    method: "post",
    url: "/api/data-overview-entrance-web/query-data-select",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  downTemplate: {
    method: "post",
    url: "/api/DataImportExport/downTemplate",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  deleteData: {
    method: "post",
    url: "/api/data-overview-entrance-web/delete-data",
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
