import { CaretDownOutlined } from "@ant-design/icons";
import type { DatePickerProps, TabsProps } from "antd";
import { Breadcrumb, DatePicker, Tabs, ConfigProvider } from "antd";
import { Fragment, createContext, useState } from "react";
import Filter from "./components/Filter";
import SummaryByDay from "./components/SummaryByDay";
import SummaryByMonth from "./components/SummaryByMonth";
import SummaryByYear from "./components/SummaryByYear";
import moment from "moment";
import "./index.less";
import vsf from "@vs/vsf-boot";
import locale from "antd/lib/locale/zh_CN";

const dateFormat = "YYYYMMDD";

enum ETabKeys {
  DAY = "day",
  OVERVIEW = "overview",
}
interface IMointorContextProps {
  year: string;
  specs: string[];
  getSpecs?: (specs: string[]) => void;
  date: {
    dateStart: string;
    dateEnd: string;
  };
  setDate?: (date: IMointorContextProps["date"]) => void;
}
export const MointorContext = createContext<IMointorContextProps>({
  year: ETabKeys.DAY,
  specs: [],
  date: {
    dateStart: moment().startOf("year").format(dateFormat),
    dateEnd: moment().format(dateFormat),
  },
});

const getClassNames = (className) => `product-monitor-${className}`;

const Monitor = () => {
  const [activeTab, setActiveTab] = useState<ETabKeys>(ETabKeys.DAY);
  const [specs, setSpecs] = useState<string[]>([]);
  const [year, setYear] = useState<string>(moment().format("YYYY"));

  const [date, setDate] = useState<IMointorContextProps["date"]>({
    dateStart: moment().startOf("year").format(dateFormat),
    dateEnd: moment().format(dateFormat),
  });

  const onChange = (key: string) => {
    setActiveTab(key as ETabKeys);
  };

  const items: TabsProps["items"] = [
    {
      key: "day",
      label: "日生产",
    },
    {
      key: "overview",
      label: "概览",
    },
  ];

  const handleChangeYear = (date: moment.Moment | null, dateString: string) => {
    setYear(dateString);
  };

  const handleDisabledDate: DatePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleNavgate = () => {
    vsf.navigateTo("/dashboard/overview");
  };

  return (
    <ConfigProvider locale={locale}>
      <MointorContext.Provider
        value={{
          specs,
          getSpecs: setSpecs,
          year,
          date,
          setDate,
        }}
      >
        <div className={getClassNames("page")}>
          <div className={getClassNames("page-header")}>
            <Breadcrumb separator=">" style={{ fontSize: 16 }}>
              <Breadcrumb.Item>
                <a onClick={handleNavgate}>管理驾驶舱</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span style={{ fontWeight: 600 }}>产能监控</span>
              </Breadcrumb.Item>
            </Breadcrumb>
            <div>
              <span style={{ paddingRight: 10 }}>时间</span>
              <DatePicker
                picker="year"
                value={moment(year)}
                onChange={handleChangeYear}
                allowClear={false}
                disabledDate={handleDisabledDate}
                suffixIcon={<CaretDownOutlined />}
              />
            </div>
          </div>

          <Filter />
          <div className={getClassNames("page-filterwrap")}>
            <Tabs activeKey={activeTab} items={items} onChange={onChange} />
          </div>
          <div style={{ overflow: "auto" }}>
            {activeTab === ETabKeys.DAY && <SummaryByDay />}
            {activeTab === ETabKeys.OVERVIEW && (
              <Fragment>
                <SummaryByYear />
                <SummaryByMonth />
              </Fragment>
            )}
          </div>
        </div>
      </MointorContext.Provider>
    </ConfigProvider>
  );
};

export default Monitor;
