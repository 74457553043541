import { initPager } from "../../utils";
import { Column, ColumnConfig } from "@ant-design/plots";
import { Empty, Spin, Table, TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { MointorContext } from "../../index";
import { getNumPercent, transNumToFixed } from "../../utils";
import "./index.less";
import { getYearSummary } from "../../services";
import { isNil, includes } from "lodash";

interface IColumns {
  key: React.Key;
  monthProdValue: number;
  monthlyPlan: number;
  monthlyPlanFinishRate: number;
  prodSpec: string;
  product: string;
  specification: string;
  stockInPack: number;
  stockInTenthsd: number;
  year: number;
}
const getClassNames = (className) => `product-monitor-summary-${className}`;

const SummaryByYear = () => {
  const { year, specs } = useContext(MointorContext);
  const [pager, setPager] = useState(initPager);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [orderBy, setOrderBy] = useState({ col: "", od: 0 });
  const [trendList, setTrendList] = useState([]);
  const [trendLoading, setTrendLoading] = useState(false);
  const [unFinishedRate, setUnFinishedRate] = useState([]);

  useEffect(() => {
    queryYearSummary(initPager, orderBy);
    queryYearSummaryTrend();
    setPager(initPager);
  }, [year, specs]);

  const queryYearSummary = (
    page: { current: number; pageSize: number },
    orderBy: { col: string; od: number }
  ) => {
    setLoading(true);
    getYearSummary({
      year,
      specs,
      page,
      ...orderBy,
    })
      .then((res: any) => {
        setTotal(res.data.total);
        setDataSource(res.data.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const queryYearSummaryTrend = () => {
    setTrendLoading(true);
    getYearSummary({
      year,
      specs,
      col: "yearlyPlanFinishRate",
      od: 1,
      page: {
        current: 1,
        pageSize: 10000000,
      },
    })
      .then((res: any) => {
        const data = res.data.data?.map((item) => ({
          key: item.prodSpec,
          value: item.yearlyPlanFinishRate * 100,
          type: item.product + item.specification,
          name: item.product,
        }));
        setTrendList(data || []);
        const _data = res.data.data
          ?.filter((item) => item?.yearlyPlanFinishRate < 1)
          ?.map((item) => item?.product + item?.specification);
        setUnFinishedRate(_data);
      })
      .finally(() => {
        setTrendLoading(false);
      });
  };

  const handleTableChange: TableProps<IColumns>["onChange"] = (
    pagination,
    filter,
    sort,
    { action }
  ) => {
    if (action === "sort") {
      const { columnKey, order } = sort as any;
      const _orderBy: any = {
        col: columnKey,
        od: order === "ascend" ? 0 : order === "descend" ? 1 : null,
      };

      setPager(initPager);
      setOrderBy(_orderBy);
      queryYearSummary(initPager, _orderBy);
    }
    if (action === "paginate") {
      const { current, pageSize } = pagination as any;
      setPager({
        current,
        pageSize,
      });
      queryYearSummary({ current, pageSize }, orderBy);
    }
  };

  const columns: ColumnsType<IColumns> = [
    {
      title: "品规",
      dataIndex: "prodSpec",
      key: "prodSpec",
      fixed: "left",
    },
    {
      title: "品种",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "规格",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: "年总入库（万瓶/万盒）",
      dataIndex: "stockInTenthsd",
      key: "stockInTenthsd",
      sorter: true,
      align: "right",
      width: 200,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "年总入库（件）",
      dataIndex: "stockInPack",
      key: "stockInPack",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "年总产值（万元）",
      dataIndex: "yearProdValue",
      key: "yearProdValue",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => (isNil(val) ? "-" : transNumToFixed(val)),
    },

    {
      title: "年计划完成率（%）",
      dataIndex: "yearlyPlanFinishRate",
      key: "yearlyPlanFinishRate",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 180,
      fixed: "right",
      render: (val: number) => {
        const color = isNil(val) ? "#999" : val >= 1 ? "#2BA471" : "#f00";
        return (
          <span style={{ color }}>{getNumPercent(val, false) || "-"}</span>
        );
      },
    },
  ];

  const config: ColumnConfig = {
    data: trendList,
    yField: "value",
    xField: "key",
    isGroup: true,
    seriesField: "type",
    color: (item: any) => {
      if (includes(unFinishedRate, item.type)) {
        return "#F4885A";
      }
      return "#0052D9";
    },
    marginRatio: 0,
    legend: false,
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      title: "年计划完成率",
      customItems: (originalItems) => {
        const _originalItems = originalItems.map((item) => ({
          ...item,
          name: item.data.type,
          value: Number(item.value).toFixed(2) + "%",
        }));
        return _originalItems;
      },
    },
    dodgePadding: 0,
    minColumnWidth: 16,
    maxColumnWidth: 20,
    scrollbar: {
      type: "horizontal",
    },
  };

  return (
    <div className={getClassNames("by-year")}>
      <div className={getClassNames("by-year-header")}>年产值统计</div>
      <div className={getClassNames("by-year-tablewrap")}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
          pagination={{
            total,
            current: pager.current,
            pageSize: pager.pageSize,
          }}
        />
        {total > 0 && (
          <div className={getClassNames("by-year-total")}>
            共 {total} 项数据
          </div>
        )}
      </div>
      <Spin spinning={trendLoading}>
        <div className={getClassNames("by-year-trendwrap")}>
          <div className={getClassNames("by-year-title")}>
            年计划完成率趋势
            <span className={getClassNames("by-year-unit")}>单位：%</span>
          </div>
          <div id="column-bar-id"></div>
          {trendList.length > 0 ? <Column {...config} /> : <Empty />}
        </div>
      </Spin>
    </div>
  );
};

export default SummaryByYear;
