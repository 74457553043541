import { CSSProperties, useEffect, useRef } from "react";
import * as echarts from "echarts";
import _ from "lodash";

interface IProps {
  onClick: () => void;
  id: string;
  chartX: string[];
  dataChart: any[];
  color: CSSProperties;
}

const LineChart: React.FC<IProps> = ({
  id,
  chartX,
  dataChart,
  color,
  onClick,
}) => {
  var myChart = useRef<any>(null);

  useEffect(() => {
    if (dataChart) {
      const dom = document.getElementById(id);
      myChart.current = echarts.init(dom);
      const option = {
        grid: {
          show: false,
          left: 0,
          containLabel: true,
          top: 0,
          right: 0,
          bottom: 0,
        },
        xAxis: {
          type: "category",
          scale: false,
          data: chartX,
          show: false,
          boundaryGap: false,
        },
        yAxis: {
          show: false,
          scale: false,
          boundaryGap: false,
          position: "right",
        },

        series: [
          {
            data: dataChart,
            type: "line",
            areaStyle: {
              opacity: 0.1,
              color: color,
            },
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: color, // 设置折线的颜色为红色
              width: 2, // 设置折线宽度为 2
            },
          },
        ],
      };
      option && myChart.current.setOption(option);
    }
  }, [dataChart]);

  useEffect(() => {
    const handleResize = () => {
      myChart.current?.resize();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    var ro = new ResizeObserver(
      _.debounce((entries) => {
        for (let entry of entries) {
          myChart.current?.resize();
        }
      }, 100)
    );

    ro.observe(document.getElementById(id) as any);
    return () => ro.disconnect();
  }, []);

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div
      id={id}
      style={{ width: "100%", height: "100%" }}
      onClick={handleClick}
    ></div>
  );
};

export default LineChart;
