import "./index.less";
import vsf, { definePage, renderRoutes, RouteConfig } from "@vs/vsf-boot";
import { Layout, Menu, Page } from "@vs/vsf-kit";
import React, { useEffect, useState, useRef } from "react";
import VConsole from "vconsole";
import Icon from "@/module/Icon";
import logo2 from "@/assets/images/logo2.png";
// import MyMainBaoNav from '@/module/MyMainBaoNav';
import LoadingModal from "@/module/LoadingModal";
import Header from "./header";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;

function getItem(label, key, path, icon, children) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}

const adminMenu = [
  {
    key: "/user",
    icon: <Icon type="icon-xitong" size={16} />,
    label: "账户管理",
    path: "/user",
  },
];
let UNLISTEN;

const gitMenu = (routes, user) => {
  return routes
    ?.filter((item) => item.label && (!user || item.path != "/user"))
    ?.filter((item) => !item?.isHide)
    ?.map((item) => {
      if (item.routes)
        return getItem(
          item.label,
          item.path,
          item.path,
          <Icon type={item.icon} size={16} />,
          gitMenu(item.routes)
        );
      return getItem(
        item.label,
        item.path,
        item.path,
        <Icon type={item.icon} size={16} />
      );
    });
};

const Container = (props) => {
  const { route } = props;
  const { global } = props?.stores || {};
  const { userData, userInfo, getUserInfo } = global || {};
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const countRef = useRef([]);
  const collapsedRef = useRef(false);

  const vConsole = new VConsole();

  let items = [];
  if (userInfo.role === "USER") {
    items = gitMenu(route.routes || [], "user");
  } else {
    items = gitMenu(route.routes || []);
  }
  const onClick = (v) => {
    if (v.key) {
      vsf.navigateTo(v.key);
    }
  };

  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setSelectedKeys(selectedKeys);
    // localStorage.setItem('selectedKeys', selectedKeys);
  };

  const onOpenChange = (openKeys) => {
    console.log(openKeys, "onOpenChange");
    setOpenKeys(openKeys);
    countRef.current = openKeys;
  };

  const selectKeys = () => {
    const target = window?.location?.pathname;
    const value = target?.split("/");
    const openKeys = [
      ...new Set([
        ...countRef.current,
        ...value.reduce((a, b) => [
          ...a,
          a.length ? a[a.length - 1] + "/" + b : "",
          "/" + b,
        ]),
      ]),
    ];

    // 侧边栏收起时，不设置展开菜单
    if (!collapsedRef.current) {
      setOpenKeys(openKeys);
      countRef.current = openKeys;
    }
    setSelectedKeys([target]);
  };

  function scrollToTopOfDiv() {
    var div = document.getElementById("layout_main");
    div.scrollTop = 0; // 将 div 的滚动位置设置为 0
  }

  useEffect(() => {
    selectKeys();
    getUserInfo();
    UNLISTEN = props.history?.listen(() => {
      getUserInfo();
      selectKeys();
      scrollToTopOfDiv();
    });
    return () => {
      UNLISTEN && UNLISTEN();
    };
  }, []);
  // 路由权限控制
  useEffect(() => {
    setTimeout(() => {
      if (!userInfo?.id)
        return vsf.navigateTo(`/login?from=${window.location.pathname}`);
      // if (userInfo?.role === 'ADMIN' && props.location.pathname !== '/user') return vsf.navigateTo('/user');
      if (userInfo?.role === "USER" && props.location.pathname === "/user")
        return vsf.navigateTo("/dashboard/overview");
    });
  }, [userInfo]);

  // console.log(vConsole, "vConsole");
  return (
    <div className="vsf-layout">
      <LoadingModal />
      <div className="vsf-layout-header">
        <Header />
      </div>
      <div className="vsf-layout-content">
        <Sider
          className="vsf-layout-sider"
          theme="light"
          width={208}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => {
            setCollapsed(value);
            collapsedRef.current = value;
          }}
          breakpoint="xxl"
          trigger={
            !collapsed ? (
              <span>
                <CaretLeftOutlined />
                收起菜单栏
              </span>
            ) : (
              <span>
                展开
                <CaretRightOutlined />
              </span>
            )
          }
        >
          {/* <div className="vsf-layout-title">AI for Healthcare管理后台</div> */}
          <Menu
            onClick={onClick}
            onSelect={onSelect}
            onOpenChange={onOpenChange}
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            items={items}
            expandIcon={(SubMenuProps, isSubMenu) => {
              return SubMenuProps.isOpen ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              );
            }}
          />
          {!collapsed ? (
            <div className="sider-logo">
              <img src={logo2} alt="" />
            </div>
          ) : null}
        </Sider>
        <div className="layout-content">
          <div id="layout_main" className="vsf-layout-main">
            <Layout>
              <Page id="vsf-layout-page" className="vsf-layout-page">
                {renderRoutes(route.routes, route.redirect)}
              </Page>
            </Layout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Container);
