import api from './api';
import vp2037902 from './vp2037902';
import vp2038202 from './vp2038202';
import vp2038203 from './vp2038203';
import vp2038204 from './vp2038204';
import vp2038205 from './vp2038205';
import vp2038206 from './vp2038206';
import vp2038207 from './vp2038207';
import vp2038208 from './vp2038208';
import vp2038209 from './vp2038209';
import vp2038210 from './vp2038210';
import vp2038211 from './vp2038211';
import vp2038212 from './vp2038212';
import vp2038213 from './vp2038213';
import vp2038214 from './vp2038214';
import vp2038701 from './vp2038701';
import vp2039901 from './vp2039901';
import vp2040301 from './vp2040301';

export default {
  ...vp2037902,
  ...vp2038202,
  ...vp2038203,
  ...vp2038204,
  ...vp2038205,
  ...vp2038206,
  ...vp2038207,
  ...vp2038208,
  ...vp2038209,
  ...vp2038210,
  ...vp2038211,
  ...vp2038212,
  ...vp2038213,
  ...vp2038214,
  ...vp2038701,
  ...vp2039901,
  ...vp2040301,
  ...api,
};
