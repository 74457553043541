import { createServices } from '@vs/vsf-boot';
export default createServices({
  CoreIndexBoardController_findCoreIndexGoal_74ba98: {
    method: 'post',
    url: '/api/data-analysis/find-core-index-goal',
    parameterFomatter: (data?: { year: number }) => data,
    responseFormatter: (_, __, data?: CoreIndexGoalVo89B7A8) => data,
  },
  CoreIndexBoardController_findCoreIndexMi_9a9632: {
    method: 'post',
    url: '/api/data-analysis/find-core-index-mi',
    parameterFomatter: (data?: { year: number; month: number }) => data,
    responseFormatter: (_, __, data?: CoreIndexMiVo89B7A8) => data,
  },
  CoreIndexBoardController_findOperateIndex_1cb6a5: {
    method: 'post',
    url: '/api/data-analysis/find-operate-index',
    parameterFomatter: (data?: { year: number; month: number }) => data,
    responseFormatter: (_, __, data?: OperateIndexVo89B7A8) => data,
  },
  CoreIndexBoardController_findTime_e7291f: {
    method: 'post',
    url: '/api/data-analysis/find-time',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
});
