import '@/assets/Oswald-Medium.ttf';
import '@/assets/fond.css';
import '@vs/vsf-kit/dist/umd/index.css';
import './style/myvars.less';
import './style/global.less';
import './style/reset.less';
import './style/vars.less';
import './api';
import 'moment/locale/zh-cn';

import { runApp } from '@vs/vsf-boot';
import { vsfKitGlobal } from '@vs/vsf-kit';
import moment from 'moment';

import routes from '@/router';
import { loadEnums } from '@/stores/dbenums';
import * as stores from '@/stores/index';

import services from './services';
// 设置 moment 的全局语言环境为中文
moment.locale('zh-cn');

import('./components').then(() => {
  loadEnums();
  runApp(
    {
      stores,
      services,
      router: {
        config: routes,
      },
    },
    vsfKitGlobal,
  );
});
