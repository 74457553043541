import { Button, Input, Space } from 'antd';
import eye from '@/assets/images/eye.png'
import eyeInvisible from '@/assets/images/eyeInvisible.png'
import React from 'react';
const App = (props) => {
    return (
        <Input.Password
            readOnly
            value={props.value}
            bordered={false}
            style={{ width: '100%', paddingRight: 24, paddingLeft: 0 }}
            iconRender={(visible) => (<div style={{ width: 28, height: 24, cursor: 'pointer' }}><img style={visible ? { width: 20 } : { width: 16, marginLeft: 2 }} src={visible ? eye : eyeInvisible}></img></div>)}
        />
    );
};
export default App;
