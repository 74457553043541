import axios from "axios";
import { message } from "antd";

interface IProps {
  data?: any;
  method?: "POST" | "GET";
  url?: string;
  headers?: any;
}

async function request(params: IProps) {
  const { url, data, method, headers } = params;

  const queryData = {
    method,
    headers: {},
    data: {},
    params: {},
  };

  if (method === "POST") {
    if (headers) {
      queryData.headers = headers;
    } else {
      queryData.headers = {
        "Content-Type": "application/json",
      };
    }
  }

  if (data) {
    method === "POST" ? (queryData.data = data) : (queryData.params = data);
  }

  return new Promise((resolve, reject) => {
    axios
      .request({
        url,
        ...queryData,
      })
      .then((res: any) => {
        if (res.data.success && res.data.resultCode === "200") {
          resolve(res.data);
        } else {
          reject();
          message.error({
            content: res.data.resultMsg || "请求异常",
          });
        }
      });
  });
}

export default request;
