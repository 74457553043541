import vsf, { vsService } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import storage from '@/utils/storage';

vsService.interceptors.response.use(
  (res) => {
    if (res?.data?.code === 301 && res?.data?.errMsg === 'Not logged in!')
      return res;
    if (res.status !== 200 || (res?.data?.code && res?.data?.code !== 200)) {
      // message.error(res?.data?.message || '请求错误');
    }
    return res;
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 301:
          // 返回 301 清除token信息并跳转到登录页面
          storage.remove('userData');
          storage.remove('userInfo');
          vsf.navigateTo(`/login?from=${window.location.pathname}`);
      }
    }
    return Promise.reject(err);
  },
);

vsService.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    const userData = storage.get('userData');
    if (userData) {
      config.headers['Authorization'] = `Bearer ${userData}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default vsService;
