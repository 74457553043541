import { createServices } from '@vs/vsf-boot';
export default createServices({
  PassRateController_getByDate_ea3e08: {
    method: 'post',
    url: '/api/data-analysis/get-by-date',
    parameterFomatter: (data?: { applydate: string }) => data,
    responseFormatter: (_, __, data?: PassRateVo89B7A8) => data,
  },
  PassRateController_findFinishProduct_4a0e15: {
    method: 'post',
    url: '/api/data-analysis/find-finish-product',
    parameterFomatter: (data?: { applydate: string }) => data,
    responseFormatter: (_, __, data?: YieldVo89B7A8[]) => data,
  },
  PassRateController_findFinishProductByOne_16d0f6: {
    method: 'post',
    url: '/api/data-analysis/find-finish-product-by-one',
    parameterFomatter: (data?: { applydate: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  PassRateController_findSemifinishedArticles_cf65d8: {
    method: 'post',
    url: '/api/data-analysis/find-semifinished-articles',
    parameterFomatter: (data?: { applydate: string }) => data,
    responseFormatter: (_, __, data?: SemiYieldVo89B7A8[]) => data,
  },
  PassRateController_findSemifinishedArticlesByOne_fc47f2: {
    method: 'post',
    url: '/api/data-analysis/find-semifinished-articles-by-one',
    parameterFomatter: (data?: { applydate: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  PassRateController_getBetweenDateComplain_e2118f: {
    method: 'post',
    url: '/api/data-analysis/get-between-date-complain',
    parameterFomatter: (data?: { year: number; month: string }) => data,
    responseFormatter: (_, __, data?: ComplainDrugVo89B7A8[]) => data,
  },
  PassRateController_getBetweenDate_f44874: {
    method: 'post',
    url: '/api/data-analysis/get-between-date',
    parameterFomatter: (data?: { applydate: string }) => data,
    responseFormatter: (_, __, data?: PassRateVo89B7A8[]) => data,
  },
  PassRateController_findComplainDrug_1b6ed5: {
    method: 'post',
    url: '/api/data-analysis/find-complain-drug',
    parameterFomatter: (data?: { year: number; month: string }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  PassRateController_getCompareDateQualityLoss_6d1449: {
    method: 'post',
    url: '/api/data-analysis/get-compare-date-quality-loss',
    parameterFomatter: (data?: { year: number; month: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  PassRateController_getByDateQualityLoss_515b05: {
    method: 'post',
    url: '/api/data-analysis/get-by-date-quality-loss',
    parameterFomatter: (data?: { year: number; month: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  PassRateController_findByAccessoriesPassRate_ead879: {
    method: 'post',
    url: '/api/data-analysis/find-by-accessories-pass-rate',
    parameterFomatter: (data?: {
      applydate: string;
      passRateEnum: PassRateEnum;
    }) => data,
    responseFormatter: (_, __, data?: TotalPassRatePlusVo89B7A8) => data,
  },
  PassRateController_getBetweenDateQualityLoss_2dee8c: {
    method: 'post',
    url: '/api/data-analysis/get-between-date-quality-loss',
    parameterFomatter: (data?: { year: number; month: string }) => data,
    responseFormatter: (_, __, data?: QualityLossVo89B7A8[]) => data,
  },
  PassRateController_findByName_298e5f: {
    method: 'post',
    url: '/api/data-analysis/find-by-name',
    parameterFomatter: (data?: {
      applydate: string;
      materialEnum: MaterialEnum;
    }) => data,
    responseFormatter: (_, __, data?: AccessoriesVo89B7A8[]) => data,
  },
});
