import React, { useEffect, useState } from "react";
import vsf, { definePage } from "@vs/vsf-boot";
import "./index.less";
import ContentHeader from "@/module/ContentHeader";
import dayjs from "dayjs";
import {
  echartsColor,
  echartsColorList,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
} from "@/config";
import * as echarts from "echarts";
import { handleToFixed, handleChartSize } from "@/utils";
import { useThrottle } from "ahooks";

const FactoryArea = (props) => {
  const { global } = props?.stores || {};

  const [date, setDate] = useState(
    props.location.state?.date
      ? dayjs(props.location.state?.date)?.format("YYYYMM")
      : dayjs()?.format("YYYYMM")
  );
  const [data, setdata] = useState<any>(null);
  const { startLoading, stopLoading } = global || {};

  useEffect(() => {
    // 监听窗口大小改变事件
    window.addEventListener("resize", () => {
      handleChartSize("FactoryArea-chart");
    });
    return () => {
      window.removeEventListener("resize", () => {
        handleChartSize("FactoryArea-chart");
      });
    };
  }, []);

  // window.removeEventListener;
  useEffect(() => {
    init();
  }, [date]);

  const init = async () => {
    startLoading();
    await handleFactoryAnalyze();
    // await handleQueryOutCount(date);
    stopLoading();
  };

  const handleFactoryAnalyze = async () => {
    const data = await vsf?.services?.factoryAnalyze({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      setdata(data?.data);
      const value = data?.data?.wanyuanAndProductLineEos;
      const chartx = value?.map(
        (v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const renderData = (data, type) => {
        return data?.map((v) => handleToFixed(v?.[type], 2));
      };
      const y1 =
        (renderData(value, "wanYuan")?.sort((a, b) => a - b)?.[
          renderData(value, "wanYuan")?.length - 1
        ]?.length -
          2) *
        8;
      const y2 =
        (renderData(value, "product")?.sort((a, b) => a - b)?.[
          renderData(value, "wanYuan")?.length - 1
        ]?.length -
          2) *
        10;
      console.log(y1, y2);
      handleChart(
        "FactoryArea-chart",
        chartx,
        [renderData(value, "wanYuan"), renderData(value, "product")],
        y1,
        y2
      );

      // setDate([
      //   ...data?.data?.lastEnergyForEnergyEoList,
      //   ...data?.data?.nowEnergyForEnergyEoList,
      // ]);
      // console.log("------");
    }
  };

  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYYMM"));
  };

  const handleChart = (
    id,
    chartX: any = [],
    dataChart: any = [],
    y1: any = 0,
    y2 = 0
  ) => {
    console.log(y1, y2, "------");
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 10,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        // selectedMode: true,
        selectedMode: false,
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 0,
        top: 80,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: [
        {
          name: "(元)",
          position: "left",
          nameTextStyle: {
            // align: "left",
            padding: [0, 0, 0, -y1],
          },
          type: "value",
          axisTick: {
            lineStyle: {
              color: echartsXYColor,
            },
          },
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
            color: echartsXYColor,
            fontSize: echartsXYSize,
          },
          // show: false,
        },
        {
          position: "right",
          // offset: -100,
          name: "(万元)",
          type: "value",
          nameTextStyle: {
            // align: "left",
            padding: [0, -y2, 0, 0],
          },
          alignTicks: true,
          axisTick: {
            lineStyle: {
              color: echartsXYColor,
            },
          },
          axisLabel: {
            formatter: "{value}",
            color: echartsXYColor,
            fontSize: echartsXYSize,
          },
          // show: false,
        },
      ],
      series: [
        {
          data: dataChart?.[0],
          type: "line",
          yAxisIndex: 0,
          smooth: true,
          showSymbol: false,
          name: "万元产值能耗金额",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          lineStyle: {
            //   color: echartsColor?.echartsColor_EF7E60,
            width: 2,
          },
        },
        {
          data: dataChart?.[1],
          type: "line",
          yAxisIndex: 1,
          // yAxisIndex: 0,
          smooth: true,
          showSymbol: false,
          name: "产值",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          lineStyle: {
            //   color: echartsColor?.echartsColor_EF7E60,
            width: 2,
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };
  return (
    <div className="FactoryArea-container">
      {" "}
      <ContentHeader
        defaultValue={dayjs(date)}
        onChange={handleDateChange}
        title="厂区能源"
        secondBreadcrumb="能源管理"
        handleNavgateSecondTab={() => vsf.navigateTo("/dashboard/enery/index")}
        info="涪陵制药厂能源消耗与产值间的动态关系"
        value={date}
        show={""}
      />
      <div className="content">
        <div className="content-header">
          <div className="header-title">涪陵制药厂能源分析</div>
        </div>
        <div className="second-content">
          <div className="second-header">万元产值能耗总额vs产值趋势图</div>
          <div className="first-content">
            <div className="first-first">
              <div className="first-num">
                {handleToFixed(data?.energyTotal, 2)} <span>万元</span>
              </div>
              <div className="unit">能耗总额</div>
            </div>
            <div className="first-second">=</div>
            <div className="first-third">
              <div className="first-num">
                {handleToFixed(data?.wanyuanAndProduct, 2)}
                <span>元/万元</span>
              </div>
              <div className="unit">万元产值能耗金额</div>
            </div>
            <div className="first-second">x</div>
            <div className="first-third">
              <div className="first-num">
                {handleToFixed(data?.product, 2)} <span>{"  "}万元</span>
              </div>
              <div className="unit">产值</div>
            </div>
          </div>
          <div className="FactoryArea-chart" id="FactoryArea-chart"></div>
        </div>
      </div>
    </div>
  );
};
export default definePage(FactoryArea);
