import { RouteConfig } from '@vs/vsf-boot';

import Root from '@/layouts';
import Login from '@/module/login';
import Index from '@/pages';

import pageRoutes from './routes';

const routes: RouteConfig[] = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/',
    component: Root,
    redirect: '/user',
    routes: [
      {
        path: '/index',
        exact: true,
        component: Index,
      },
      ...pageRoutes,
    ],
  },
];

export default routes;
