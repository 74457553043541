import { createServices } from '@vs/vsf-boot';
export default createServices({
  FlUserController_createUser_c1c424: {
    method: 'post',
    url: '/api/user-account/create-user',
    parameterFomatter: (data?: { flUserEo: FlUserEoAfd016 }) => data,
    responseFormatter: (_, __, data?: FlUserEoAfd016) => data,
  },
  FlUserController_delete_b3963f: {
    method: 'post',
    url: '/api/user-account/delete',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FlUserController_login_ebffd9: {
    method: 'post',
    url: '/api/user-account/login',
    parameterFomatter: (data?: { userName: string; passWord: string }) => data,
    responseFormatter: (_, __, data?: FlUserEoAfd016) => data,
  },
  FlUserController_queryUser_4ed5db: {
    method: 'post',
    url: '/api/user-account/query-user',
    parameterFomatter: (data?: {
      userName: string;
      page: number;
      size: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: FlUserEoAfd016[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FlUserController_updateById_08ab79: {
    method: 'post',
    url: '/api/user-account/update-by-id',
    parameterFomatter: (data?: { eo: FlUserEoAfd016 }) => data,
    responseFormatter: (_, __, data?: FlUserEoAfd016) => data,
  },
  FlUserController_queryUserName_d1b007: {
    method: 'post',
    url: '/api/user-account/query-user-name',
    parameterFomatter: (data?: { username: string }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FlUserController_loginUser_544008: {
    method: 'post',
    url: '/api/user-account/login-user',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: FlUserEoAfd016) => data,
  },
  DataOverviewController_queryDataOverview_463c69: {
    method: 'post',
    url: '/api/data-overview/query-data-overview',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: OverviewSingleEoB57Eed) => data,
  },
  DataOverviewController_queryCostManager_ab5035: {
    method: 'post',
    url: '/api/data-overview/query-cost-manager',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: CostManagerEoB57Eed) => data,
  },
  DataOverviewController_queryMainIndexManagerEo_f767f3: {
    method: 'post',
    url: '/api/data-overview/query-main-index-manager-eo',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: MainIndexManagerEoB57Eed) => data,
  },
  DataOverviewController_queryProductPage_b73e46: {
    method: 'post',
    url: '/api/data-overview/query-product-page',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: ProductPageEoB57Eed) => data,
  },
  DataOverviewController_queryStockPage_ba75a9: {
    method: 'post',
    url: '/api/data-overview/query-stock-page',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: StockPageEoB57Eed[]) => data,
  },
  DataOverviewController_queryWorkshopProductionPage_32655e: {
    method: 'post',
    url: '/api/data-overview/query-workshop-production-page',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: WorkshopProductionPageEoB57Eed) => data,
  },
  DataOverviewController_queryOutCount_48bf04: {
    method: 'post',
    url: '/api/data-overview/query-out-count',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
