import vsf, { definePage } from '@vs/vsf-boot';
import React from 'react';
import loadingImg from '@/assets/images/loading.png'
import './index.less'
// 创建一个全局的Loading组件
const LoadingIndicator = (props) => {
    const { global } = props?.stores || {};
    const {
        isLoading,
    } = global || {};

    if (!isLoading) return null;

    return (
        <div className='loading-modal'>
            <div className='loding-content'>
                <img src={loadingImg} alt="" />加载中，请稍等...
            </div>
        </div>
    );
};
export default definePage(LoadingIndicator)