// import React from 'react';
import vsf, { definePage } from "@vs/vsf-boot";
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAction,
  Modal,
  Button,
  Input,
  Drawer,
  Form,
  VSFormItem,
  VSForm,
  UploadFile,
} from "@vs/vsf-kit";
import {
  ExclamationCircleFilled,
  SearchOutlined,
  CloseCircleFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { message, Upload } from "antd";
import PassWordText from "@/module/PasswordText";
import React, { useState, useCallback, useEffect, useRef } from "react";
import searchImg from "@/assets/images/search.png";
import slash from "@/assets/images/slash.png";
import download from "@/assets/images/download.png";
// import FileUpload from '@/module/FileUpload';
import error from "@/assets/images/error.png";
import success from "@/assets/images/check.png";
import "./index.less";
import classNames from "classnames";
import { extractTables } from "@/utils/xlsx";
import storage from "@/utils/storage";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/zh_CN";
import "dayjs/locale/zh-cn";
const regex1 = new RegExp(/^[A-Za-z0-9@._]{6,30}$/);
const regex2 = new RegExp(/^[A-Za-z0-9@._!#$%&]{6,30}$/);
// import './index.less';

const AliyunOSSUpload = ({ value, onChange }) => {
  const [OSSData, setOSSData] = useState();
  const [uploadDara, setUploadDara] = useState(null);
  const UploadRef = useRef();
  const handleChange = ({ fileList }) => {
    // console.log('Aliyun OSS:', uploadDara, fileList?.[0]);
    const value = fileList?.[0];
    setUploadDara({ ...value, ...uploadDara });
    onChange?.([{ ...value, ...uploadDara }]);
  };
  const onRemove = (file) => {
    const files = (value || []).filter((v) => v.url !== file.url);
    if (onChange) {
      onChange(files);
    }
  };
  const getExtraData = (file) => ({
    key: file.url,
    OSSAccessKeyId: OSSData?.accessId,
    policy: OSSData?.policy,
    Signature: OSSData?.signature,
  });
  const beforeUpload = async (file) => {
    // console.log(file, 'file');
    const response = await extractTables(file);
    console.log(response, "file");
    setUploadDara(response?.[0]);
    return false;
  };
  const uploadProps = {
    name: "file",
    fileList: value,
    action: OSSData?.host,
    onChange: handleChange,
    onRemove,
    data: getExtraData,
    beforeUpload,
    // name:
  };
  // console.log(UploadRef, uploadDara, 'UploadRef');
  return (
    <Upload
      {...uploadProps}
      itemRender={(item) => {
        // console.log(item, OSSData, uploadDara);
        return false;
      }}
      showUploadList={false}
      maxCount={1}
      onDrop={(value) => {
        console.log(value, "----");
      }}
      ref={UploadRef}
    >
      {/* <div className="Upload-text">
        <div className="upoad-dev">
          <div className="upload">点击上传</div>
          <img src={slash}></img>
          <div>拖拽到此区域</div>
        </div>
      </div> */}
      {!uploadDara ? (
        <div className="Upload-text">
          <div className="upoad-dev">
            <div className="upload">点击上传</div>
            <img src={slash}></img>
            <div>拖拽到此区域</div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(`Upload-obj`, {
            borderErr: uploadDara?.status == "error",
          })}
          onClick={(e) => {
            if (e.target?.id !== "upload1") e.stopPropagation();
          }}
        >
          <div className="Upload-obj-header">
            <div className="Upload-obj-header-header">
              {uploadDara?.name}{" "}
              <img src={uploadDara?.status !== "error" ? success : error}></img>
            </div>
            <div className="bottom">
              文件大小: {(uploadDara.size / 1024)?.toFixed(2)}K
            </div>
          </div>
          <div className="Upload-obj-bottom">
            <div id="upload1">重新上传</div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                UploadRef.current.fileList = [];
                setUploadDara(null);
              }}
            >
              删除
            </div>
          </div>
        </div>
      )}
    </Upload>
  );
};

const DataUpload = (props) => {
  const { global } = props?.stores || {};
  const { Dragger } = Upload;
  const { isLoading, startLoading, stopLoading, userInfo } = global || {};
  const [searchParams, setSearchParams] = useState();
  const [form] = Form.useForm();
  const [emailMessage, setEmailMessage] = useState(); //表单提示
  const [passwordMessage, setPasswordMessage] = useState();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isSumit, setIsSumit] = useState(true);
  const [user, setUser] = useState();
  const [isUserName, setIsUserName] = useState(false);
  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    handleSelectData();
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setUser(null);
    setIsSumit(true);
    setDisabled(false);
  };

  const handelRemoveClick = async (id) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.FlUserController_delete_b3963f?.({ id }); // Tips: 需要返回删除成功与否
    if (res.code === 200) {
      vsf.showToast("删除数据成功", "success");
      await vsf?.refs?.vSPromiseTable615999?.reload();
    } else {
      vsf.showToast("删除数据失败", "error");
    }
    return res?.code === 200;
  };

  const reqUserList = useCallback(async (params) => {
    console.log(params, "-----");
    // startLoading();
    const res = await vsf.services?.importRecord?.({
      page: params.pagination?.current ?? 1,
      size: params.pagination?.size ?? 20,
      ...params.extra,
    }); // TODO: 你可能需要整理返回数据格式
    // stopLoading();
    return {
      data: res?.data?.dataImportRecordList ?? [],
      total: res?.data?.count ?? 0,
    };
  }, []);

  const submit = async () => {
    const params = form.getFieldsValue();
    const name = storage?.get("userInfo")?.userName;
    const fileData = params?.file?.[0];
    console.log(fileData, params, "fileData");
    let dataList = fileData?.tableData2;
    // if (fileData?.tableHeaderKey && fileData?.tableData) {
    //   dataList = [fileData?.tableHeaderKey, ...fileData?.tableData];
    // }
    const findObj = selectData?.find(
      (v) => v?.tableEngName == params?.dataContent
    );
    const res = await vsf.services?.dataImport({
      dataImportRecordEo: {
        ...params,
        fileName: fileData?.name,
        dataList,
        statsPeriod: dayjs(params?.statsPeriod)?.format("YYYY年MM月"),
        uploader: name,
        dataContent: findObj?.tableChnName,
        file: null,
      },
    });
    if (res.code == 200) {
      vsf.showToast("上传数据成功", "success");
      setIsSumit(true);
      setDisabled(false);
      onClose();
      await vsf?.refs?.vSPromiseTableDataUpload?.reload();
    } else {
      Modal.confirm({
        title: "上传数据失败了",
        icon: <CloseCircleFilled style={{ color: "#D54941" }} />,
        content: res?.message,
        okText: "确定",
        cancelText: "取消",
        centered: true,
        closable: true,
        className: "my-modal-confirm",
        width: 480,
      });
    }
    return params;
  };

  const handleUpload = async (data) => {
    fetch("/api/DataImportExport/export", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: data?.id }),
    })
      .then((response) => response.blob()) // 转换为blob
      .then((blob) => {
        // const filename = data?.dataContent;
        const filename = data?.tableName;
        const blobUrl = window.URL.createObjectURL(blob); // 创建blob的URL
        const a = document.createElement("a"); // 创建一个<a>元素
        a.href = blobUrl;
        a.download = filename; // 设置下载文件的名称
        document.body.appendChild(a); // 将<a>元素添加到页面中
        a.click(); // 模拟点击以触发下载
        a.remove(); // 下载后移除元素
        window.URL.revokeObjectURL(blobUrl); // 清理blob URL
      })
      .catch((error) => console.error("Error:", error));
  };
  const handleIsDataIsExits = async () => {
    const params = form.getFieldsValue();
    const fileData = params?.file?.[0];
    const findObj = selectData?.find(
      (v) => v?.tableEngName == params?.dataContent
    );
    const res = await vsf.services?.dataIsExits({
      dataImportRecord: {
        ...params,
        dataContent: findObj?.tableChnName,
        statsPeriod: dayjs(params?.statsPeriod)?.format("YYYY年MM月"),
      },
    });
    if (res.code == 200) {
      if (res?.data) {
        Modal.confirm({
          title: "检测到已有相同数据文档，是否覆盖？",
          icon: <ExclamationCircleOutlined style={{ color: "#FFA900" }} />,
          content: (
            <div>
              已有一份数据内容为“{findObj?.tableChnName}”，数据统计周期为“
              {dayjs(params?.statsPeriod)?.format("YYYY年MM月")}
              ”的数据文档，请确认是否覆盖？
              <br />
              <br />
              选择「覆盖」，将替换原有文档。
            </div>
          ),
          okText: "确认覆盖",
          onOk: () => {
            submit();
          },
          cancelText: "取消",
          centered: true,
          closable: true,
          className: "my-modal-confirm",
          width: 480,
        });
      } else {
        submit();
      }
    }
  };
  const handleSelectData = async () => {
    const res = await vsf?.services?.select({});
    if (res?.code == 200) {
      setSelectData(res?.data);
    }
  };
  const handleDownTemplate = async () => {
    const param = form?.getFieldValue("dataContent");
    fetch("/api/DataImportExport/downTemplate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tableName: param }),
    })
      .then((response) => response.blob()) // 转换为blob
      .then((blob) => {
        console.log(blob, param, "blob");
        const findObj = selectData?.find((v) => v?.tableEngName == param);
        // const filename = findObj?.tableChnName;
        const filename = `${param}.csv`;
        const blobUrl = window.URL.createObjectURL(blob); // 创建blob的URL
        const a = document.createElement("a"); // 创建一个<a>元素
        a.href = blobUrl;
        a.download = filename; // 设置下载文件的名称
        document.body.appendChild(a); // 将<a>元素添加到页面中
        a.click(); // 模拟点击以触发下载
        a.remove(); // 下载后移除元素
        window.URL.revokeObjectURL(blobUrl); // 清理blob URL
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleDelValue = (value) => {
    console.log(value, "value");
    handleDel(value);
  };

  const handleDel = async (value) => {
    // deleteData
    const res = await vsf.services?.deleteData?.({ id: value?.id }); // Tips: 需要返回删除成功与否
    if (res.code === 200) {
      vsf.showToast("删除成功", "success");
      await vsf?.refs?.vSPromiseTableDataUpload?.reload();
    } else {
      vsf.showToast("删除失败", "error");
    }
  };

  const renderArr = (Arr) => {
    return [...new Set(Arr)];
  };
  return (
    <Section className="DataUpload">
      <div
        className="page-title"
        // onClick={() => {
        //   handleAA();
        // }}
      >
        数据上传
      </div>
      <VSPromiseTable
        vsid="08305"
        id="vSPromiseTableDataUpload"
        editable={{
          editType: "single",
          saveOnClickOutside: true,
          columnProps: {
            hideInTable: true,
            width: 80,
          },
        }}
        className="DataUpload-table"
        extraParams={searchParams}
        searchFormRender={(props, defaultDom, options) => {
          return (
            <div style={{ display: "flex", gap: 24, paddingBlock: 24 }}>
              <Button
                onClick={() => {
                  showDrawer();
                  // setDisabled(true);
                }}
                type="primary"
              >
                <PlusOutlined />
                数据
              </Button>
              <Input
                onPressEnter={(e) =>
                  setSearchParams(
                    e.target.value ? { condition: e.target.value.trim() } : {}
                  )
                }
                width={208}
                placeholder="请输入数据内容"
                suffix={
                  <img style={{ width: 14, height: 14 }} src={searchImg}></img>
                }
              />
            </div>
          );
        }}
        searchConfig={{
          autoSearch: true,
          optionButtons: [],
        }}
        onFetch={reqUserList}
        pagination={{
          defaultPageSize: 15,
          showSizeChanger: true,
          showQuickJumper: true,
        }}
      >
        <VSTableColumn
          dataIndex={["dataContent"]}
          title="数据内容"
          valueType="text"
          order={-2}
          fieldProps={{}}
          search
          render={(dom, record) => (
            <div className="dataContent">{record.dataContent}</div>
          )}
        />
        <VSTableColumn
          dataIndex={["team"]}
          title="所属团队"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={["statsPeriod"]}
          title="数据统计周期"
          valueType="text"
          fieldProps={{}}
          // render={(dom, record) => <PassWordText value={record.userPwd} />}
        />
        <VSTableColumn
          dataIndex={["uploader"]}
          title="上传人员"
          valueType="text"
          // fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
        <VSTableColumn
          dataIndex={["uploadTime"]}
          title="上传时间"
          valueType="date"
          fieldProps={{ format: "YYYY/MM/DD HH:mm:ss" }}
        />
        <VSTableColumn
          // width={100}
          title="操作"
          valueType="option"
          preview
        >
          <VSTableAction
            onClick={(e, data) => {
              handleUpload(data);
            }}
          >
            下载
          </VSTableAction>
          <VSTableAction
            onClick={(e, data) => {
              // console.log(data, "-----");
              Modal.confirm({
                title: (
                  <span>
                    确定删除{data?.statsPeriod}
                    {data?.team}的数据吗？
                  </span>
                ),
                icon: <ExclamationCircleFilled style={{ color: "#FFA900" }} />,
                content: (
                  <>
                    <p>删除后数据将无法恢复。</p>
                    <p>您可通过数据上传功能重新上传对应周期数据。</p>
                  </>
                ),
                okText: "确定删除",
                cancelText: "取消",
                centered: true,
                onOk: () => handleDelValue(data),
                onCancel() {},
                closable: true,
                className: "my-modal-confirm",
                width: 480,
              });
            }}
          >
            删除
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
      <Drawer
        className="DataUpload-my-drawer"
        closable={false}
        width={452}
        title="上传数据"
        placement="right"
        footerStyle={{ borderTop: "none" }}
        headerStyle={{ borderBottom: "none" }}
        footer={
          <div className="my-drawer-footer">
            <Button
              className="btn"
              disabled={isSumit}
              onClick={() => {
                console.log(form.getFieldsValue());
                handleIsDataIsExits();
              }}
              type="primary"
            >
              确定
            </Button>
            <Button className="btn close" onClick={onClose}>
              取消
            </Button>
          </div>
        }
        destroyOnClose
        getContainer={() => document.getElementById("layout_main")}
        onClose={onClose}
        open={open}
      >
        <VSForm
          className="required-form"
          form={form}
          vsid="14719"
          style={{ height: "max-content" }}
          params={props.routes?.query}
          layout="vertical"
          onValuesChange={(changedValues, values) => {
            const arr = Object?.keys(values);
            const findArr = ["team", "dataContent", "statsPeriod"]?.filter(
              (v) => values?.[v]
            );
            console.log(changedValues, arr, values, "values");
            setDisabled(findArr?.length >= 3);
            setIsSumit(arr?.length < 4);
          }}
          // initialValues={user}
        >
          <VSFormItem
            label="部门"
            name="team"
            rules={[
              {
                required: true,
                message: "请选择部门",
                // validator: validateCustom,
              },
            ]}
            fieldProps={{
              placeholder: "请选择",
              allowClear: false,
            }}
            valueType="select"
            dataSource={renderArr(selectData?.map((v) => v?.team))}
          />
          <VSFormItem
            label="数据内容"
            name="dataContent"
            dataSource={selectData?.map((v) => {
              return {
                label: v?.tableChnName,
                value: v?.tableEngName,
              };
            })}
            valueType="select"
            rules={[
              {
                required: true,
                message: "请选择数据内容",
                // message: '请选择',
              },
            ]}
            fieldProps={{
              showSearch: true,
              placeholder: "请选择",
              allowClear: false,
            }}
          />
          <VSFormItem
            // readonly
            name="statsPeriod"
            label="数据统计周期"
            rules={[
              {
                required: true,
                message: "请选择月份",
              },
            ]}
            valueType="dateMonth"
            fieldProps={{
              format: "YYYY年MM月",
              locale: locale,
              placeholder: "请选择月份",
              allowClear: false,
            }}
          ></VSFormItem>
          <VSFormItem
            name={"file"}
            label="上传文件"
            rules={[
              {
                required: true,
              },
            ]}
            valueType="custom"
            className="upload-item"
          >
            <div
              // className="upoadBtn"
              className={classNames(`upoadBtn`, {
                upoadBtnD: !disabled,
              })}
              onClick={() => {
                disabled && handleDownTemplate();
              }}
            >
              <img src={download}></img>
              下载数据模板
            </div>
            <AliyunOSSUpload
              onChange={(value) => {
                if (value) {
                  form?.setFieldValue("file", value);
                  const fromData = form?.getFieldsValue();
                  const keys = Object?.keys(fromData);
                  const findArr = keys?.filter((v) => !fromData?.[v]);
                  // console.log(findArr,fromData, keys, "findArr");
                  setIsSumit(findArr?.length >= 1);
                }
              }}
            />

            {/* <FileUpload></FileUpload> */}
          </VSFormItem>
          <div className="data-text">数据要求说明：</div>
          <div className="text-item">
            <div>小数：</div>
            <div>
              通常保留小数点后两位数，数字中不出现千位分隔逗号（常用于金额），特殊要求见字段说明。
            </div>
            {/* <br /> */}
          </div>
          <div className="text-item">
            <div>百分比数值：</div>
            <div> 0.xxxx格式，保留小数点后四位，去除百分号</div>
          </div>
        </VSForm>
      </Drawer>
    </Section>
  );
};
export default DataUpload;
