/**
 * 分析指引内容
*/

import vsf, { definePage } from '@vs/vsf-boot';
import React, { useState } from 'react';
import Slice6 from '@/assets/images/Slice6@2x.png'
import Slice7 from '@/assets/images/Slice7@2x.png'
import Slice8 from '@/assets/images/Slice8@2x.png'
import Slice9 from '@/assets/images/Slice9@2x.png'
import Slice10 from '@/assets/images/Slice10@2x.png'
import './index.less'
const GuideContent = () => {
    return (
        <div className='guide-content'>
            <p className='p1'>涪陵制药厂的核心目标是保障生产的顺畅，高效完成营管中心的订单计划，以及在确保产品质量的前提下，实施严格的成本控制。</p>
            <p className='p1'>为了实现这些目标，驾驶舱数据产品根据涪陵制药厂整体的数字化指标体系，集成多个关键业务指标，通过实时数据和手工上传的数据分析来辅助决策，确保生产运营的优化和效率提升。</p>
            <img className='img1' src={Slice6} alt="" />
            <img className='img2' src={Slice7} alt="" />
            <div className='el-box'>
                <div className='label'>
                    <img className='img3' src={Slice8} alt="" />
                    <span>生产管理</span>
                </div>
                <div className='info'>
                    <p>在生产模块，驾驶舱将“保障生产”作为核心任务，确保生产活动能够按计划高效执行。 衡量生产效率的关键指标是“年计划达成率”，这直接关联到生产的整体绩效。 为了实现这一目标，驾驶舱采取全链路分析方法，从生产排产开始，到物料采购，再到车间生产，直至最终的产品仓储物流，每个环节都进行了精细化管理，确保每个环节都能高效衔接，减少任何可能的生产延误。</p>
                    <p className='title'>分析思路如下：</p>
                    <p className='title1'>年计划达成率</p>
                    <p>预期：按既定时间进度安排，预计年底前实现100%完成率。</p>
                    <p>若实际表现与预期一致：生产活动按计划顺利推进。</p>
                    <p>若实际表现未达预期：查看下钻链路分析以识别问题所在。</p>
                    <p className='title1'>生产排产环节评估：</p>
                    <p>排产率高于100%表明排产计划执行良好；</p>
                    <p>若排产率低于100%，说明排产量低于要货计划，需检查排产环节合理。</p>
                    <p className='title1'>物料采购环节评估：</p>
                    <p>采购完成率超过100%意味着物料供应充足；</p>
                    <p>若采购完成率低于100%，则物料可能无法及时到达，从而影响生产进度。</p>
                    <p className='title1'>车间生产环节分析：</p>
                    <p>生产计划按时完成的比率超过100%为正常；</p>
                    <p>若计划完成率低于100%，则需深入车间生产分析模块，从物料、设备和人效等多方面进行诊断，以确定生产延误的具体原因。</p>
                    <p className='title1'>仓储物流环节检查：</p>
                    <p>展示上月末的库存量，结合实际情况判断库存水位是否健康。</p>
                    <p>通过上述多维度的分析，我们可以全面掌握生产流程的每个环节，及时发现问题并采取措施，以确保生产活动能够高效、顺利地达到既定目标。</p>
                </div>
            </div>
            <div className='el-box'>
                <div className='label label1'>
                    <img className='img3' src={Slice9} alt="" />
                    <span>成本管理</span>
                </div>
                <div className='info'>
                    <p>在成本管理上，驾驶舱将成本细分为原材料成本、包装成本、直接人工成本以及制造费用成本等几个主要部分。
                        通过对每个部分的成本进行详细的拆解和分析，涪陵制药厂能够更加精准地掌握成本构成，发现成本节约的潜在点，进而采取措施进行优化，确保在实现成本领先的同时不牺牲产品质量和生产效率。</p>
                    <p>通过这种综合多方面因素的成本控制方法，涪陵制药厂在激烈的市场竞争中能够保持其产品的竞争力，实现可持续的健康发展。</p>
                </div>
            </div>
            <div className='el-box'>
                <div className='label label1'>
                    <img className='img3' src={Slice10} alt="" />
                    <span>风险管理</span>
                </div>
                <div className='info'>
                    <p>质量控制方面，驾驶舱采用全生命周期的分析方法，从物料采购的合格率，到半成品和成品的合格率，再到市场反馈，每个环节都设立了严格的质量标准并进行监管，同时明确责任部门，严格保障产品质量。</p>
                </div>
            </div>
        </div>
    );
};

export default GuideContent;
