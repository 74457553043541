/**
 * 页面头部
 * defaultValue： Date || undefined,
 * onChange: function,
 * title: string,
 * info: string,
 * show: boolean
 */
import vsf, { definePage } from '@vs/vsf-boot';
import { DatePicker , Breadcrumb} from "antd"
import React, { useState, useEffect } from 'react';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    CaretDownOutlined,
    CaretUpOutlined,
} from '@ant-design/icons';
import './index.less'
const ContentHeader = ({ value, defaultValue, onChange, title, info, show, picker = 'month',isShowBreadcrumb = true , secondBreadcrumb = '', handleNavgateSecondTab = {}, headerStyle={} }) => {
    const [open, setOpen] = useState(false);
    const [month, setMonth] = useState((dayjs(defaultValue)?.month() + 1) / 12 * 100);
    const [width, setWidth] = useState(0);
    const disabledDate = (current) => {
        // Can not select days after today
        return current && current > moment().endOf('day');
    };

    const onDateChange = (val) => {
        console.log(dayjs(val)?.month());
        setMonth((dayjs(val)?.month() + 1) / 12 * 100)
        onChange && onChange(val);
    }
    // const handleResize = () => {
    //     const node = document.getElementById('vsf-layout-page');
    //     if (node) {
    //         const nodeWidth = node.offsetWidth;
    //         console.log(nodeWidth, 'nodeWidth');
    //         setWidth(nodeWidth - 48 + 'px');
    //     }
    // };
    // useEffect(() => {
    //     handleResize();
    //     // 监听窗口大小变化
    //     window.addEventListener('resize', handleResize);
    //     // 组件卸载时移除监听器
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);
    // console.log(width);

    const handleNavgate = () => {
        vsf.navigateTo('/dashboard/overview');
    }

    return (
        <>
            {/* <div className='el-content-header'>
            </div> */}
            <div className='el-content-header-fixed'>
                <div className={`el-content-header-fixed-content ${isShowBreadcrumb?'breadcrumb-header':'common-header'}`} style={headerStyle}>
                    {
                        isShowBreadcrumb?  (
                            <Breadcrumb separator=">" style={{ fontSize: 16 }}>
                                <Breadcrumb.Item>
                                    <a onClick={handleNavgate}>管理驾驶舱</a>
                                </Breadcrumb.Item>
                                {
                                    secondBreadcrumb? (
                                        <Breadcrumb.Item>
                                            <a onClick={()=>_.isFunction(handleNavgateSecondTab)&&handleNavgateSecondTab()}>{secondBreadcrumb}</a>
                                        </Breadcrumb.Item>
                                    ) : null
                                }
                                <Breadcrumb.Item>
                                    <span style={{ fontWeight: 600 }}>{title}</span>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        ):(
                            <div className='el-content-header-left'>
                                <div className='el-content-header-left-title'>{title}</div>
                                <div className='el-content-header-left-info'>{info}</div>
                            </div>
                        )
                    }
                    <div className='el-content-header-right'>
                        {
                            typeof show === 'number' && <>
                                <div className='el-content-header-right-progress'>
                                    <span>时间进度</span>
                                    <div className='el-content-header-right-progress-box'>
                                        <span style={{ width: month?.toFixed(0) + '%', paddingRight: month ? 5 : 0, color: '#000' }}>{month.toFixed(0) + '%'}</span>
                                    </div>
                                </div>
                                <div className='el-content-header-right-line'>
                                </div>
                            </>
                        }
                        <span className='time'>时间</span>
                        <DatePicker
                            locale={locale}
                            format={picker === 'year' ? "YYYY年" : "YYYY年MM月"}
                            onChange={onDateChange}
                            picker={picker}
                            allowClear={false}
                            disabledDate={disabledDate}
                            // value={value}
                            onOpenChange={setOpen}
                            defaultValue={defaultValue}
                            suffixIcon={open ? <CaretUpOutlined /> : <CaretDownOutlined style={{ color: '#333333' }} />}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentHeader;
