import "./index.less";

import vsf, { definePage } from "@vs/vsf-boot";
import { Tabs } from "antd";
import classnames from "classnames";
import dayjs from "dayjs";
import * as echarts from "echarts";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatMoney, convertToPercentage, convertToFixed, isNumber } from '@/tools'

import down from "@/assets/images/down.png";
import up from "@/assets/images/up.png";
import Group10 from "@/assets/images/Group10.png";
import Group11 from "@/assets/images/Group11.png";
import {
  DrugName,
  echartsColor,
  echartsColorList,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
  riskPassRate,
  riskTabs,
} from "@/config";
import ContentHeader from "@/module/ContentHeader";
import GuideBtn from "@/module/GuideBtn";
import { handleToFixed } from "@/utils";

const defaultOption = {
  legend: {
    type: "plain",
    left: 0,
    data: ["Forest", "Steppe", "Desert", "Wetland"],
    selectedMode: false,
  },
  grid: {
    left: 0,
    containLabel: true,
    top: 40,
    right: 0,
    bottom: 24,
  },
  tooltip: {
    trigger: "axis",
    valueFormatter: (value) => {
      return value;
    },
  },
  xAxis: {
    axisTick: {
      lineStyle: {
        color: echartsXYColor,
      },
    },
    axisLabel: {
      formatter: "{value}",
      color: echartsXYColor,
      fontSize: echartsXYSize,
    },
  },
  yAxis: {
    type: "value",
    axisTick: {
      lineStyle: {
        color: echartsXYColor,
      },
    },
    axisLabel: {
      formatter: "{value}",
      color: echartsXYColor,
      fontSize: echartsXYSize,
    },
    // show: false,
  },
};

const Risk = (props) => {
  const { global, location } = props?.stores || {};
  const { startLoading, stopLoading } = global || {};
  const [date, setDate] = useState(
    props.location.state?.date || dayjs()?.format("YYYY-MM")
  );
  const [productRate, setProductRate] = useState<any>("0");
  const [articles, setArticles] = useState<any>("0");
  const [complaint, setComplaint] = useState<string>("0");
  const [loss, setLoss] = useState<string>();
  const [lastYear, setLastYear] = useState<number>(0);
  const [passRate, setPassRate] = useState<any>({});
  const [betweenDateList, setBetweenDateList] = useState<PassRateVo89B7A8[]>();
  const [secondTabitem, setSecondTabitem] = useState<any>({});
  const [findChart, setFindChart] = useState<any>({});
  const [securityCompliance, setSecurityCompliance] = useState<any>({});
  const [activeKey, setActiveKey] = useState<any>(props.routes.query?.id || "1");
  const [mouseoverBar, setMouseoverBar] = useState<any>();
  const [FeedBack, setFeedBack] = useState<any>();
  const firstChart = useRef<any>();
  const rateChart = useRef<any>();
  const materialsLeftChart = useRef<any>();
  const materialsRigthChart = useRef<any>();
  const barRef = useRef<any>();

  const handleBottomService = () => {
    const front = [
      handleFindFinishProduct(date),
      handleFindFindFinishProductByOne(date),
      handleFindSemifinishedArticles(date),
      handleFindSemifinishedArticlesByOne(date),
      handleGetBetweenDate(date),
      handleGetBetweenDateComplain(date),
      handleQueryMarketFeedBack(date),
    ];
    const after = () => {
      handleGetBetweenDateQualityLoss(date);
      handleGetByDateQualityLoss(date);
      handleGetCompareDateQualityLoss(date);
      handleGetByDate(date);
      handleGetBetweenDateList(date);
    };
    return {
      after,
      front,
    };
  };

  const handleTopService = () => {
    const front = [
      handleGetBetweenDateQualityLoss(date),
      handleGetByDateQualityLoss(date),
      handleGetCompareDateQualityLoss(date),
      handleGetByDate(date),
      handleGetBetweenDateList(date),
    ];
    const after = () => {
      handleFindFinishProduct(date);
      handleFindFindFinishProductByOne(date);
      handleFindSemifinishedArticles(date);
      handleFindSemifinishedArticlesByOne(date);
      handleGetBetweenDate(date);
      handleGetBetweenDateComplain(date);
      handleQueryMarketFeedBack(date);
    };
    return {
      after,
      front,
    };
  };
  const querySecurityCompliancePage = async () => {
    startLoading();
    const res = await vsf?.services?.querySecurityCompliancePage({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    stopLoading();
    setSecurityCompliance(res.data)
  }
  const ANCHOR_TYPE = {
    ANCHOR1: "anchor1",
    ANCHOR2: "anchor2",
    ANCHOR3: "anchor3",
    ANCHOR4: "anchor4",
  };
  const ANCHOR_S = {
    [ANCHOR_TYPE?.ANCHOR1]: handleTopService,
    [ANCHOR_TYPE?.ANCHOR2]: handleTopService,
    [ANCHOR_TYPE?.ANCHOR3]: handleBottomService,
    [ANCHOR_TYPE?.ANCHOR4]: handleBottomService,
  };

  const handleService = (all, after) => {
    startLoading();
    Promise.all(all)
      .then((values) => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
    after();
  };

  useEffect(() => {
    const anchorId = props.location.state?.anchorId;
    if (activeKey === '1') {
      if (anchorId) {
        const value = ANCHOR_S[anchorId]();
        handleService(value?.front, value?.after);
      } else {
        const value = ANCHOR_S["anchor1"]();
        handleService(value?.front, value?.after);
      }
    }
    if (activeKey === '2') {
      querySecurityCompliancePage();
    }
    // console.log(vsf?.services, '  queryMarketFeedBack');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, activeKey]);
  useEffect(() => {
    if (betweenDateList) {
      renderRateChart();
    }
    if (secondTabitem) {
      // 辅材
      handleFindByAccessoriesPassRate(date, secondTabitem?.PassRate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betweenDateList, secondTabitem]);
  useEffect(() => {
    if (findChart) {
      // console.log(findChart, 'findChart');
      renderMaterialsRigthChart([], []);
      handlefindByName(date, findChart?.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, findChart]);

  // 滚动到锚点
  useEffect(() => {
    const anchorId = props.location.state?.anchorId;
    if (anchorId) {
      const anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        // 无滚动效果
        // anchorElement.scrollIntoView();
        // 带滚动效果
        anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  barRef?.current?.on("mouseover", function (params) {
    if (params.componentType === "series") {
      // params.seriesType === 'bar' 表示触发的是柱状图的事件
      if (params.seriesType === "bar") {
        // params.dataIndex 表示鼠标当前移入的是哪个柱子
        console.log("鼠标移入了柱子", params?.name);
        setMouseoverBar(params?.name);
        // 你可以在这里编写你需要执行的逻辑
      }
    }
  });

  barRef?.current?.on("mouseout", function (params) {
    if (params.componentType === "series") {
      // params.seriesType === 'bar' 表示触发的是柱状图的事件
      if (params.seriesType === "bar") {
        // params.dataIndex 表示鼠标当前移出的是哪个柱子
        console.log("鼠标移出了柱子", params.dataIndex);
        setMouseoverBar("");
        // 你可以在这里编写你需要执行的逻辑
      }
    }
  });

  materialsLeftChart.current?.on("click", (params) => {
    if (params.seriesType === "bar") {
      setFindChart(params.data);
    }
  });

  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYY-MM"));
  };

  const handleQueryMarketFeedBack = async (date) => {
    // console.log('我掉了接口');
    const data = await vsf?.services?.queryMarketFeedBack({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code == 200) {
      setFeedBack(data?.data);
      console.log(data, "我是次数");
    }
  };

  const handleFindFinishProduct = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_findFinishProduct_4a0e15({
        applydate: dayjs(date)?.format("YYYYMM"),
      });
    if (data?.code == 200) {
      const chartX = data?.data?.map(
        (v) => dayjs(v?.timePeriod)?.format("YYYY-MM")
      );
      const dataChart = data?.data?.map((v) =>
        handleToFixed(v?.yieldRate * 100, 2)
      );
      handleChart("third-left-chart", chartX, dataChart);
    }
  };
  const handleFindFindFinishProductByOne = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_findFinishProductByOne_16d0f6({
        applydate: dayjs(date)?.format("YYYYMM"),
      });
    if (data?.code == 200) {
      if (data?.data >= 1) {
        const rate = data?.data * 100;
        setProductRate(rate);
      } else {
        const rate = (data?.data * 100)?.toFixed(2);
        setProductRate(rate);
      }
    }
  };

  const handlefindByName = async (date, name) => {
    const data = await vsf?.services?.PassRateController_findByName_298e5f({
      applydate: dayjs(date)?.format("YYYYMM"),
      materialEnum: name?.toUpperCase(),
    });
    if (data?.code == 200) {
      const chartx = data?.data?.map(
        (v) => dayjs(v?.timeRate)?.format("YYYY-MM")
      );
      const chartData = data?.data?.map((v) => {
        return (v?.rateAccessories * 100)?.toFixed(2);
      });
      renderMaterialsRigthChart(chartx, chartData);
    }
  };

  const handleFindSemifinishedArticles = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_findSemifinishedArticles_cf65d8({
        applydate: dayjs(date)?.format("YYYYMM"),
      });
    if (data?.code == 200) {
      const chartX = data?.data?.map(
        (v) => dayjs(v?.timePeriod)?.format("YYYY-MM")
      );
      const dataChart = data?.data?.map((v) =>
        handleToFixed(v?.semiYieldRate * 100, 2)
      );
      handleChart("third-rigth-chart", chartX, dataChart);
    }
  };
  const handleFindSemifinishedArticlesByOne = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_findSemifinishedArticlesByOne_fc47f2(
        {
          applydate: dayjs(date)?.format("YYYYMM"),
        }
      );
    if (data?.code == 200) {
      // const rate = (data?.data * 100)?.toFixed(2);
      // setArticles(rate);
      if (data?.data >= 1) {
        const rate = data?.data * 100;
        setArticles(rate);
      } else {
        const rate = (data?.data * 100)?.toFixed(2);
        setArticles(rate);
      }
    }
  };

  const handleGetCompareDateQualityLoss = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_getCompareDateQualityLoss_6d1449({
        year: dayjs(date)?.format("YYYY"),
        month: dayjs(date)?.format("MM"),
      });
    if (data?.code == 200) {
      setLastYear(data?.data * 100 ?? 0);
    }
  };

  const handleGetBetweenDate = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_findComplainDrug_1b6ed5({
        year: dayjs(date)?.format("YYYY"),
        month: dayjs(date)?.format("MM"),
      });
    if (data?.code == 200) {
      setComplaint(data?.data ?? 0);
    }
  };
  const handleGetBetweenDateComplain = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_getBetweenDateComplain_e2118f({
        // applydate: dayjs(date)?.format('YYYYMM'),
        year: dayjs(date)?.format("YYYY"),
        month: dayjs(date)?.format("MM"),
      });
    if (data?.code == 200) {
      const chartX = data?.data?.map(
        (v) => dayjs(v?.timePeriod)?.format("YYYY-MM")
      );
      const dataChart = data?.data?.map((v) => v?.rateNum);
      // console.log(chartX, dataChart, 'dataChart');
      // handleChart('third-top-chart', chartX, dataChart);
      // handleChart('third-bottom-chart', chartX, dataChart);
      handleBarChart("third-chart", chartX, dataChart);
    }
  };

  const handleGetBetweenDateQualityLoss = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_getBetweenDateQualityLoss_2dee8c({
        year: dayjs(date)?.format("YYYY"),
        month: dayjs(date)?.format("MM"),
      });
    if (data?.code == 200) {
      const chartX = data?.data?.map(
        (v) => dayjs(v?.timePeriod)?.format("YYYY-MM")
      );
      const dataChart = data?.data?.map((v) =>
        handleToFixed(v?.qualityLoss, 2)
      );
      renderFirstChart(chartX, dataChart);
    }
  };
  const handleGetByDateQualityLoss = async (date: string) => {
    const data =
      await vsf?.services?.PassRateController_getByDateQualityLoss_515b05({
        year: dayjs(date)?.format("YYYY"),
        month: dayjs(date)?.format("MM"),
      });
    if (data?.code == 200) {
      // console.log(data?.data, 'aaa');
      const rate = (data?.data ?? 0)?.toFixed(2);
      setLoss(rate);
    }
  };

  const handleFindByAccessoriesPassRate = async (date, passRateEnum) => {
    const data =
      await vsf?.services?.PassRateController_findByAccessoriesPassRate_ead879({
        applydate: dayjs(date)?.format("YYYYMM"),
        passRateEnum,
      });

    if (data?.code === 200) {
      const findata = data?.data?.[secondTabitem?.chartType];

      const arr = Object?.keys(findata ?? {})
        ?.map((v) => {
          return {
            value: DrugName[v],
            key: v,
            label: data?.data?.[secondTabitem?.chartType]?.[v],
          };
        })
        ?.filter((v) => v?.label > 0);
      const chartData = arr?.sort((a, b) => {
        return a?.label - b?.label;
      });
      const minNum = chartData?.[0];
      const chartDataFind = chartData
        ?.map((v, i) => {
          return {
            ...v,
            type: v?.value,
            value: (v?.label * 100)?.toFixed(2),
            name: v?.key,
            itemStyle: {
              color:
                (v?.label * 100)?.toFixed(2) ==
                  (minNum?.label * 100)?.toFixed(2) && minNum?.label < 1
                  ? echartsColor?.echartsColor_EF7E60
                  : echartsColor?.echartsColor_0052D9,
            },
          };
        })
        ?.filter((v) => v?.type);
      setFindChart(chartDataFind?.[0]);
      // console.log(chartDataFind, 'chartDataFind');
      const chartY = chartDataFind
        ?.map((v) => v?.key)
        ?.filter((v) => DrugName[v]);
      const gridArr = chartDataFind
        ?.map((v) => v?.type?.length)
        ?.sort((a, b) => a - b);
      // console.log(gridArr, chartDataFind, 'gridArr');
      // console.log(secondTabitem, 'secondTabitem');
      const grid = gridArr[gridArr?.length - 1] * 16;

      renderMaterialsLeftChart(chartY, chartDataFind, grid);
    }
  };

  const handleGetByDate = async (date) => {
    const data = await vsf?.services?.PassRateController_getByDate_ea3e08({
      applydate: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      setPassRate(data?.data);
    }
  };
  const handleGetBetweenDateList = async (date) => {
    const data = await vsf?.services?.PassRateController_getBetweenDate_f44874({
      applydate: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code == 200) {
      setBetweenDateList(data?.data);
      setSecondTabitem(renderRed(data?.data));
    }
  };
  const handleChart = (id, chartX = [], dataChart = []) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      ...defaultOption,
      grid: {
        left: 0,
        containLabel: true,
        top: 20,
        right: 0,
        bottom: 40,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      series: [
        {
          data: dataChart,
          type: "line",
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const handleClickSecondTabitem = (v) => {
    // console.log(v, 'v');
    setSecondTabitem(v);
  };

  const renderFirstChart = (chartX, dataChart, grid = 30) => {
    const dom = document.getElementById("first-chart");
    dom && (firstChart.current = echarts.init(dom));
    const option = {
      ...defaultOption,
      grid: {
        left: 0,
        containLabel: true,
        top: 40,
        right: 0,
        bottom: 24,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          alignWithLabel: true,
          interval: 1,
          length: 4,
          lineStyle: {
            color: echartsXYColor,
          },
        },

        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      series: [
        {
          data: dataChart,
          type: "line",
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        },
      ],
    };
    dom && option && firstChart.current.setOption(option);
  };

  const renderRateChart = () => {
    const dom = document.getElementById("rate-chart");
    dom && (rateChart.current = echarts.init(dom));
    const chartX = betweenDateList?.map(
      (v) => dayjs(v?.timePeriod)?.format("YYYY-MM")
    );
    const renderTypeDate = (type) => {
      return betweenDateList?.map((v) => (v[type] * 100)?.toFixed(2));
    };
    const renderOp = (type) => {
      return secondTabitem?.key === type ? 1 : 0.37;
    };
    const option = {
      ...defaultOption,
      color: echartsColorList,
      legend: {
        type: "plain",
        show: true,
        // left: 0,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
        left: 0,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          interval: 1,
          length: 4,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
      },
      series: [
        {
          data: renderTypeDate("rawMaterialRate"),
          type: "line",
          smooth: true,
          name: "原材料",
          showSymbol: false,
          lineStyle: {
            // color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
            opacity: renderOp("rawMaterialRate"),
          },
        },
        {
          name: "辅材",
          data: renderTypeDate("accessoriesRate"),
          type: "line",
          smooth: true,
          showSymbol: false,
          lineStyle: {
            // color: echartsColor?.echartsColor_2FC25B, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            opacity: renderOp("accessoriesRate"),
          },
        },
        {
          name: "包材",
          data: renderTypeDate("packageRate"),
          type: "line",
          smooth: true,
          showSymbol: false,
          lineStyle: {
            // color: echartsColor?.echartsColor_A350F6, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为
            opacity: renderOp("packageRate"),
          },
        },
      ],
    };
    dom && option && rateChart.current?.setOption(option);
  };

  const renderMaterialsLeftChart = (chartY, chartData, gild: any = "20%") => {
    const dom = document.getElementById("materials-left-chart");
    dom && (materialsLeftChart.current = echarts.init(dom));
    const option = {
      ...defaultOption,
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 20,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        show: false,
      },
      xAxis: {
        show: false,
        type: "value",
        axisTick: {
          show: false,
          alignWithLabel: true,
          interval: 1,
          length: 4,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
      },
      yAxis: {
        type: "category",
        data: chartY,
        // data: renderXData(chartValue),
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(245, 245, 245, 1)",
          },
        },
        axisLabel: {
          color: "rgba(0, 0, 0, 1)",
          fontSize: 14,
          width: 200, // 设置标签宽度限制
          // align: 'left',
          overflow: "truncate", // 超出宽度部分截断
          ellipsis: "...", // 超出部分用省略号表示
          formatter: (value) => {
            // console.log(value, 'value');
            return DrugName[value];
          },
        },
      },
      series: [
        {
          data: chartData,
          type: "bar",
          label: {
            color: "#FFFFFF",
            show: true,
            fontWeight: "bold",
            fontSize: echartsXYSize,
            position: [10, "30%", 0, 10],
            formatter: "{c}%",
          },
          barMaxWidth: 40,
        },
      ],
    };
    dom && option && materialsLeftChart.current.setOption(option);
  };

  const renderMaterialsRigthChart = (chartx, chartData) => {
    const dom = document.getElementById("materials-rigth-chart");
    dom && (materialsRigthChart.current = echarts.init(dom));
    const option = {
      ...defaultOption,
      grid: {
        left: 0,
        containLabel: true,
        top: 20,
        right: 0,
        bottom: 40,
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
        // formatter: (value) => {
        //   console.log(value, '---aaaaa-');
        //   return value;
        // },
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartx,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          // formatter: '{value}',
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
        },
      },
      series: [
        {
          data: chartData,
          type: "line",
          areaStyle: {
            opacity: 0.3,
            color: findChart?.itemStyle?.color,
          },
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: findChart?.itemStyle?.color, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        },
      ],
    };
    dom && option && materialsRigthChart.current.setOption(option);
  };

  const handleBarChart = (id, chartX: any = [], dataChart: any = []) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    barRef.current = myChart;
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 0,
        top: 20,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: [
        {
          data: dataChart,
          type: "bar",
          smooth: true,
          showSymbol: false,
          // name: '售后厂区库存',
          lineStyle: {
            color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // opacity: 0.1,
          },
          itemStyle: {
            color: "#FF8B38",
            // opacity: mouseoverBar ? 0.5 : 1,
          },

          emphasis: {
            itemStyle: {
              color: "#FF8B38",
              opacity: 1,
            },
          },
          barWidth: 18,
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const renderTabsDom = (riskTabs) => {
    return riskTabs?.map((v) => {
      return {
        ...v,
        children: v.key === '1' ? renderTabsQualityDom() : rendersafeDom(),
      };
    });
  };
  const renderRed = (v) => {
    const arr = riskPassRate
      ?.map((item) => {
        return {
          ...item,
          rate: v?.[item?.key],
        };
      })
      ?.sort((a, b) => {
        return a?.rate - b?.rate;
      });

    return arr?.[0];
  };
  const renderSecondTab = (v, value) => {
    // const data = {
    //   accessoriesRate: 0.2,
    //   packageRate: 1,
    //   rawMaterialRate: 0.2,
    // };
    const renderRed = (v, key) => {
      const arr = riskPassRate
        ?.map((item) => {
          return {
            ...item,
            rate: v?.[item?.key],
          };
        })
        ?.sort((a, b) => {
          return a?.rate - b?.rate;
        });
      return arr?.[0]?.rate === v?.[key] && arr?.[0]?.rate < 1;
    };
    return (
      <div
        className={classnames(`secondTab-item`, {
          activeSecondTabItem: v?.key == secondTabitem?.key,
          activeNumRed: renderRed(value, v?.key),
        })}
        onClick={() => {
          handleClickSecondTabitem(v);
        }}
      >
        <div className="title">{v?.label}</div>
        <div className="num">
          {handleToFixed(passRate[v?.key] * 100, 2)} <span>%</span>
        </div>
      </div>
    );
  };
  const handleIsNumber = (value) => {
    return typeof value === "number" || typeof value === "string";
  };
  const renderIsUp = (value) => {
    return value > 0 ? up : down;
  };

  console.log(FeedBack?.marketFeedBackEos, "FeedBack?.marketFeedBackEos");
  // 质量分析dom
  const renderTabsQualityDom = () => {
    return (
      <div className="tab-content">
        <div className="first">
          <div className="header-title" id="anchor1">
            核心指标
          </div>
          <div className="first-content">
            <div className="first-content-left">
              <div className="title">累计万元质量损失</div>
              <div className="content">
                <div
                  // className="price"
                  className={classnames(`price`, {
                    // firsterr: loss && Number(loss) > 0.5,
                  })}
                >
                  {/* {loss} */}
                  {handleIsNumber(loss) ? loss : "-"}
                  <span>元</span>
                </div>
                <div className="unit">
                  较上年
                  {handleIsNumber(lastYear) ? (
                    <>
                      <img
                        style={{ marginInline: 4 }}
                        src={lastYear > 0 ? up : down}
                      ></img>
                      <span>{lastYear?.toFixed(2)}%</span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <div className="first-content-rigth">
              <div className="rigth-header">
                <div className="title">累计万元质量损失月趋势</div>
                <div className="unit">单位：元</div>
              </div>
              <div className="first-chart" id="first-chart"></div>
            </div>
          </div>
        </div>
        <div className="second">
          <div className="header-title" id="anchor2">
            原辅料包材质检
          </div>
          <div className="second-content">
            <div className="second-header">
              {dayjs(date)?.format("MM")}月合格率 <span>点击合格率查看</span>
            </div>
            <div className="secondTab">
              {riskPassRate?.map((v) => renderSecondTab(v, passRate))}
            </div>
            <div className="rate-content">
              <div className="rigth-header">
                <div className="title">原辅料包材 - 合格率月趋势</div>
                <div className="unit">单位：%</div>
              </div>
              <div className="rate-chart" id="rate-chart"></div>
            </div>
            <div className="materials">
              <div className="materials-title">
                {secondTabitem?.value} <span>合格率分析</span>
              </div>
              <div className="materials-content">
                <div className="materials-left">
                  <div className="materials-left-header">月合格率</div>
                  <div
                    className="materials-left-chart"
                    id="materials-left-chart"
                  ></div>
                </div>
                <div className="materials-rigth">
                  <div className="materials-left-header">
                    {findChart?.type}合格率趋势
                  </div>
                  <div
                    className="materials-left-chart"
                    id="materials-rigth-chart"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="third third1">
          <div className="header-title" id="anchor3">
            成品半成品质检
          </div>
          <div className="third-content">
            <div className="third-content-left">
              <div
                // className="third-content-header"
                className={classnames(`third-content-header`, {
                  err: productRate && Number(productRate) < 100,
                })}
              >
                成品合格率
                <div>{productRate}</div>
                <span>%</span>
              </div>
              <div className="third-left-chart" id="third-left-chart"></div>
            </div>
            <div className="third-content-rigth">
              <div className="third-content-header">
                半成品合格率
                <div>{articles}</div>
                <span>%</span>
              </div>
              <div className="third-left-chart" id="third-rigth-chart"></div>
            </div>
          </div>
        </div>
        <div className="fifth">
          <div className="header-title" id="anchor4">
            市场反馈
          </div>
          <div className="third-content">
            <div className="third-header">
              <div className="warn">
                本年用户投诉总次数<span>{FeedBack?.feedBackYearCount} </span>次
              </div>
              <div className="warn">
                本月用户投诉次数<span>{complaint} </span>次
              </div>
            </div>
            {FeedBack?.marketFeedBackEos?.length > 0 && (
              <div className="third-warn-text">
                {FeedBack?.marketFeedBackEos?.map((v, i) => {
                  console.log("111111");
                  return (
                    v?.feedBackMonthCount > 0 && (
                      <div className="warn-item" key={i}>
                        <div className="warn-deta">{v?.month}月</div>
                        <div className="warn-deta">
                          {v?.feedBackMonthCount}次
                        </div>
                        <div className="warn-text">{v?.feedBackDetails}</div>
                      </div>
                    )
                  );
                })}
              </div>
            )}
            <div className="unit">单位：次</div>
            <div className="third-chart" id="third-chart"></div>
          </div>
        </div>
      </div>
    );
  };
  //安合规dom
  const rendersafeDom = () => {
    return <div className="safe-tab-content">
      <div className="safe-top-card">
        <div className="card-item">
          <div className="text-box">
            <div className="text">综合指标完成率</div>
            <div className="change">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(securityCompliance?.indicatorAchievmentRateCompare)}
              ></img>
              <span className="rate">
                {convertToPercentage(securityCompliance?.indicatorAchievmentRateCompare) + '%'}
              </span>
            </div>
          </div>
          <div className="num">
            {convertToFixed(securityCompliance?.indicatorAchievmentRate)}
            <span>%</span>
          </div>
        </div>
        <div className="stc-line"></div>
        <div className="card-item">
          <div className="text-box">
            <div className="text">培训项目数</div>
            <div className="change">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(securityCompliance?.safetyTrainingsCountsCompare)}
              ></img>
              <span className="rate">
                {convertToPercentage(securityCompliance?.safetyTrainingsCountsCompare) + '%'}
              </span>
            </div>
          </div>
          <div className="num">
            {securityCompliance?.safetyTrainingsCounts || '-'}
            <span>个</span>
          </div>
        </div>
      </div>
      <div className="body body1">
        <div className="header-title" id="anchor1">
          内控指标情况
        </div>
        <div className="body-content body1-content">
          <div className="body-card card1">
            <div className="top">
              <img src={Group10} alt="" />
              <div className="right-label">废水浓度</div>
            </div>
            <div className="center">
              <div className="num">
                {convertToFixed(securityCompliance?.codConcentration)}
                <span>mg/L</span>
              </div>
            </div>
            <div className="change">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(securityCompliance?.codConcentrationCompare)}
              ></img>
              <span className="rate">
                {convertToPercentage(securityCompliance?.codConcentrationCompare) + '%'}
              </span>
            </div>
          </div>
          <div className="body-card">
            <div className="top">
              <img src={Group11} alt="" />
              <div className="right-label">废气Nox的排放量</div>
            </div>
            <div className="center">
              <div className="num">
                {convertToFixed(securityCompliance?.so2Concentration)}
                <span>吨</span>
              </div>
            </div>
            <div className="change">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(securityCompliance?.so2ConcentrationCompare)}
              ></img>
              <span className="rate">
                {convertToPercentage(securityCompliance?.so2ConcentrationCompare) + '%'}
              </span>
            </div>
          </div>
          <div className="body-card">
            <div className="top">
              <img src={Group11} alt="" />
              <div className="right-label">废气NOx的排放浓度</div>
            </div>
            <div className="center">
              <div className="num">
                {convertToFixed(securityCompliance?.noxConcentration)}
                <span>mg/m³</span>
              </div>
            </div>
            <div className="change">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(securityCompliance?.noxConcentrationCompare)}
              ></img>
              <span className="rate">
                {convertToPercentage(securityCompliance?.noxConcentrationCompare) + '%'}
              </span>
            </div>
          </div>
          <div className="body-card">
            <div className="top">
              <img src={Group11} alt="" />
              <div className="right-label">废气SO<span className="so">2</span>的排放浓度</div>
            </div>
            <div className="center">
              <div className="num">
                {convertToFixed(securityCompliance?.totalWasteGasNox)}
                <span>mg/m³</span>
              </div>
            </div>
            <div className="change">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(securityCompliance?.totalWasteGasNoxCompare)}
              ></img>
              <span className="rate">
                {convertToPercentage(securityCompliance?.safetyTrainingsCountsCompare) + '%'}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="body body2">
        <div className="header-title" id="anchor2">
          目标考核
        </div>
        <div className="body-content body2-content">
          <div className="body-card">
            <div className="item">
              <div className="num-box">
                <span className={securityCompliance?.majorAccidentCount > 0 ? "num err" : "num"}>{isNumber(securityCompliance?.majorAccidentCount) ? securityCompliance?.majorAccidentCount : '-'}</span>
                <span className="unit">次</span>
              </div>
              <div className="label">重大及以上事故</div>
              <div className="info">不能发生</div>
            </div>
            <div className="item">
              <div className="num-box">
                <span className={securityCompliance?.minorAccidentCount > 5 ? "num err" : "num"}>{isNumber(securityCompliance?.minorAccidentCount) ? securityCompliance?.minorAccidentCount : '-'}</span>
                <span className="unit">次</span>
              </div>
              <div className="label">一般事故</div>
              <div className="info">{'全年 <= 5次'}</div>
            </div>
          </div>
          <div className="body-card">
            <div className="item">
              <div className="num-box">
                <span className={securityCompliance?.die > 0 ? "num err" : "num"}>{isNumber(securityCompliance?.die) ? securityCompliance?.die : '-'}</span>
                <span className="unit">人</span>
              </div>
              <div className="label">死亡人数</div>
              <div className="info">不能发生</div>
            </div>
            <div className="item">
              <div className="num-box">
                <span className={securityCompliance?.injuries > 5 ? "num err" : "num"}>{isNumber(securityCompliance?.injuries) ? securityCompliance?.injuries : '-'}</span>
                <span className="unit">人</span>
              </div>
              <div className="label">受伤人数</div>
              <div className="info">{'全年 <= 5人'}</div>
            </div>
          </div>
          <div className="body-card">
            <div className="item">
              <div className="num-box">
                <span className={securityCompliance?.millionYuanLoss > 10 ? "num err" : "num"}> {convertToPercentage(securityCompliance?.millionYuanLoss)}</span>
                <span className="unit">万元</span>
              </div>
              <div className="label">百万元产值生产性安全事故损失额</div>
              <div className="info">{'全年 <= 10元'}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="body body3">
        <div className="header-title" id="anchor3">
          过程考核
        </div>
        <div className="body-content body3-content">
          <div className="body-card">
            <div className="label">安全分数</div>
            <div className="num-box">
              <span className="num">-</span>
              <span className="unit">分</span>
            </div>
            <div className="info"></div>
          </div>
          <div className="body-card">
            <div className="label">一般事故</div>
            <div className="num-box">
              <span className="num">-</span>
              <span className="unit">次</span>
            </div>
            <div className="info">每个扣 2分</div>
          </div>
          <div className="body-card">
            <div className="label">隐患事故</div>
            <div className="num-box">
              <span className="num">-</span>
              <span className="unit">次</span>
            </div>
            <div className="info">每个扣 0.1分</div>
          </div>
        </div>
      </div> */}
    </div>
  }
  // console.log(new Date(date), secondTabitem, 'date');

  return (
    <div className="risk-container">
      <ContentHeader
        value={date}
        defaultValue={dayjs(date)}
        show={""}
        // defaultValue={yourDefaultValue}
        onChange={handleDateChange}
        title="风险管理"
        info="涪陵制药厂质量分析与安全合规指标分析"
      />
      <div className="risk-container-content">
        <div className="guide">
          {/* 隐藏分析指引 */}
          {/* <GuideBtn title={''} renderContent={''} value={''}></GuideBtn> */}
        </div>
        <Tabs
          activeKey={activeKey}
          onChange={setActiveKey}
          items={renderTabsDom(riskTabs)}
        ></Tabs>
      </div>
    </div>
  );
};
export default definePage(Risk);
