import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Row, Col, Popover } from '@vs/vsf-kit';
import React, { useState, useCallback, useEffect } from 'react';
import ContentHeader from '@/module/ContentHeader';
import dayjs from 'dayjs';
import { formatMoney, convertToPercentage, convertToFixed, isNumber } from '@/tools'
import { lastYearEnum as flagLastYearEnum, expectEnum as flagExpectEnum } from '@/config'
import up from '@/assets/images/up.png';
import down from '@/assets/images/down.png';
import headerBg from '@/assets/images/header-bg1.png';
import questionImg from '@/assets/images/question.png'
import './index.less'
// 绩效指标卡片
const CardDom1 = ({ data }) => {
    return <div className='card-dom1'>
        <div className='card-dom1-title'>{data.title}
            <span>
                <Popover content={data.info}>
                    <img style={{ width: 18, height: 18, marginBottom: 2 }} src={questionImg} alt="" />
                </Popover>
            </span>
        </div>
        <div className='card-dom1-box'>
            <div className='card-dom1-box-l'>
                <div className='card-dom1-num'>{data.all}{data.key === 'costOverOprtRvn' && <span>%</span>}</div>
                <div className='card-dom1-text'>累计</div>
            </div>
            <div className='card-dom1-box-r'>
                <div className='card-dom1-num'>{data.goal}{data.key === 'costOverOprtRvn' && <span>%</span>}</div>
                <div className='card-dom1-text'>目标</div>
            </div>
        </div>
        {data.expect?.percentage === '-' ?
            <div className='card-dom1-sign'>-</div> :
            data.expect?.expectEnum ?
                <div className='card-dom1-sign'><span className={`sign-tag sign-up-tag`}>超预期</span>超出预期 {data.expect?.percentage}%</div> :
                <div className='card-dom1-sign'><span className={`sign-tag sign-down-tag`}>未达预期</span>距离预期 {data.expect?.percentage}%</div>
        }
    </div>
}
// 经营指标卡片
const CardDom2 = ({ data }) => {
    return <div className='card-dom2'>
        <div className='card-dom2-title'>
            {data.title}
            {<span>
                <Popover content={data.info}>
                    <img style={{ width: 18, height: 18, marginBottom: 2 }} src={questionImg} alt="" />
                </Popover>
            </span>}
        </div>
        <div className='card-dom2-box'>
            {data.all}{data.key !== 'profitAll' && <span>{data.key !== 'laborPrdtvty' ? '%' :  data.key === 'laborPrdtvty' ? "万元/人" : '元/人'}</span>}
        </div>
        <div className='card-dom2-change'>
            较上年
            {
                data.expect?.percentage === '-' ? '-' : <>
                    <img style={{ marginInline: 4 }} src={data.expect?.lastYearEnum ? up : down}></img><span>{data.expect?.percentage}%</span></>
            }
        </div>
    </div>
}
// 绩效指标
const coreIndexMiVo = [
    {
        title: '营业收入(亿元)',
        key: 'revenue',
        all: 'revenueAll',
        expect: 'revenueExpect',
        info: <><p>营业收入：考核（合并）口径的全年累计营业收入</p><p>营业收入目标：集团考核的全年营业收入目标</p></>
    },
    {
        title: '净利润(万元)',
        key: 'income',
        all: 'incomeAll',
        expect: 'incomeExceed',
        info: <><p>净利润：考核（合并）口径的全年累计净利润</p><p>净利润目标：集团考核的全年净利润目标</p></>
    },
    {
        title: '经营活动现金流量(万元)',
        key: 'oprtCashFlow',
        all: 'oprtCashFlowAll',
        expect: 'oprtCashFlowExpect',
        info: <><p>经营活动现金流量：考核（合并）口径的全年累计经营活动现金流量</p><p>经营活动现金流量目标：集团考核的全年计划经营活动现金流量</p></>
    },
    {
        title: '科技支出总额(万元)',
        key: 'techCost',
        all: 'techCostAll',
        expect: 'techCostExpect',
        info: <><p>科技支出总额：全年累计的科技支出总额</p><p>科技支出总额目标：集团制定的全年科技支出总额</p></>
    },
    {
        title: '成本费用占营收比重',
        key: 'costOverOprtRvn',
        all: 'costOverOprtRvnAll',
        expect: 'costOverOprtRvnExpect',
        info: <><div>成本费用占营收比重：累计成本费用/累计考核（合并）口径的营业收入，其中成本费用剔除了研发费用</div><p>成本费用占营收比重目标：集团考核的成本费用占总营业比重上限，其中成本费用剔除了研发费用</p></>
    },
    {
        title: '应收账款周转天数(日)',
        key: 'rvnTorDays',
        all: 'rvnTorDaysAll',
        expect: 'rvnTorDaysExpect',
        info: <><p>应收账款周转天数：考核（合并）口径计算得到的应收账款周转天数</p><p>应收账款周转天数目标：集团考核（合并）口径的全年周转天数</p></>
    },
    {
        title: '存货周转天数(日)',
        key: 'invTorDays',
        all: 'invTorDaysAll',
        expect: 'invTorDaysExpect',
        info: <><p>存货周转天数：考核（合并）口径计算得到存货周转天数</p><p>存货周转天数目标：集团考核（合并）口径的全年存货周转天数</p></>
    },
]
// 工资总额
const coreIndexMiVo1 = [
    {
        title: '工资总额(万元)',
        key: 'salary',
        all: 'salaryAll',
        expect: 'salaryExpect',
        info: <><p>工资总额：涪陵药厂口径累计发放的工资总额</p><p>工资总额目标：集团制定的涪陵药厂口径工资总额</p></>
    },
]
// 一增
const operateIndexVo1 = [
    {
        title: '利润总额(万元)',
        all: 'profitAll',
        key: 'profitAll',
        expect: 'profitLastYear',
        info: '利润总额：考核（合并）口径的累计利润总额'
    },
]
// 一稳
const operateIndexVo2 = [
    {
        title: '资产负债率',
        all: 'asstLbltRatio',
        expect: 'asstLbltRatioLastYear',
        info: '资产负债率：法人（报表）口径；资产负债率=总负债/总资产'
    },
]
// 四提升
const operateIndexVo3 = [
    {
        title: '研发经费投入强度',
        all: 'researchCostRate',
        expect: 'researchCostRateLastYear',
        info: '研发经费投入强度：法人（报表）口径；研发经费强度=累计研发经费投入/累计营业收入'
    },
    {
        title: '全员劳动生产率',
        key: 'laborPrdtvty',
        all: 'laborPrdtvty',
        expect: 'laborPrdtvtyLastYear',
        info: '全员劳动生产率：法人（报表）口径；全员劳动生产率=全员劳动生产总值/平均人数'
    },
    {
        title: '净资产收益率',
        all: 'roe',
        expect: 'roeLastYear',
        info: '净资产收益率：法人（报表）口径；净资产收益率=累计总利润/净资产'
    },
    {
        title: '营业现金比率',
        all: 'oprtCashRatio',
        expect: 'oprtCashRatioLastYear',
        info: '营业现金比率：法人（报表）口径；营业现金比率=累计法人主体经营活动现金流/累计法人主体收入'
    },
]
const FinancialIndicators = (props) => {
    const { global } = props?.stores || {};
    const {
        isLoading,
        startLoading,
        stopLoading,
        userInfo,
    } = global || {};
    const [date, setDate] = useState(dayjs()?.format('YYYY-MM'));
    const [coreIndexGoal, setCoreIndexGoal] = useState();
    const [coreIndexMi, setCoreIndexMi] = useState();
    const [operateIndex, setOperateIndex] = useState();
    const [time, setTime] = useState();

    const reqCoreIndexGoal = async (year) => {
        const res = await vsf.services.CoreIndexBoardController_findCoreIndexGoal_74ba98({ year })
        if (res.code === 200) {
            setCoreIndexGoal(res.data)
        }
    }
    const reqCoreIndexMi = async (year, month) => {
        const res = await vsf.services.CoreIndexBoardController_findCoreIndexMi_9a9632({ year, month })
        if (res.code === 200) {
            setCoreIndexMi(res.data)
        }
    }
    const reqOperateIndex = async (year, month) => {
        const res = await vsf.services.CoreIndexBoardController_findOperateIndex_1cb6a5({ year, month })
        if (res.code === 200) {
            setOperateIndex(res.data)
        }
    }
    const findTime = async () => {
        const res = await vsf.services.CoreIndexBoardController_findTime_e7291f()
        if (res.code === 200) {
            setTime(res.data)
        }
    }
    const handleDateChange = (value) => {
        setDate(dayjs(value)?.format('YYYY-MM'));
    };
    // 格式化绩效指标数据
    const initCoreIndexMiVo = (coreIndexMiVo, coreIndexGoal, coreIndexMi) => {
        if (!coreIndexGoal || !coreIndexMi) return coreIndexMiVo.map(item => ({ ...item, all: '-', goal: '-', expect: { percentage: '-' } }));
        return coreIndexMiVo.map(item => {
            if (item.key === 'revenue') {
                return ({
                    ...item,
                    goal: formatMoney(coreIndexGoal[item.key], '亿元'),
                    all: formatMoney(coreIndexMi[item.all], '亿元'),
                    expect: {
                        expectEnum: flagExpectEnum[coreIndexMi[item.expect].expectEnum],
                        percentage: convertToPercentage(coreIndexMi[item.expect].percentage),
                    }
                })
            }
            if (item.key === 'costOverOprtRvn') {
                return ({
                    ...item,
                    goal: convertToPercentage(coreIndexGoal[item.key]),
                    all: convertToPercentage(coreIndexMi[item.all]),
                    expect: {
                        expectEnum: flagExpectEnum[coreIndexMi[item.expect].expectEnum],
                        percentage: convertToPercentage(coreIndexMi[item.expect].percentage),
                    }
                })
            }
            if (item.key === 'rvnTorDays' || item.key === 'invTorDays') {
                return ({
                    ...item,
                    goal: isNumber(coreIndexGoal[item.key]) ? coreIndexGoal[item.key] : '-',
                    all: isNumber(coreIndexMi[item.all]) ? coreIndexMi[item.all] : '-',
                    expect: {
                        expectEnum: flagExpectEnum[coreIndexMi[item.expect].expectEnum],
                        percentage: convertToPercentage(coreIndexMi[item.expect].percentage),
                    }
                })
            }
            return ({
                ...item,
                goal: formatMoney(coreIndexGoal[item.key], '万元'),
                all: formatMoney(coreIndexMi[item.all], '万元'),
                expect: {
                    expectEnum: flagExpectEnum[coreIndexMi[item.expect].expectEnum],
                    percentage: convertToPercentage(coreIndexMi[item.expect].percentage),
                }
            })
        });
    }
    // 格式化经营指标数据
    const initOperateIndex = (operateIndexVo, operateIndex) => {
        if (!operateIndex) return operateIndexVo.map(item => ({ ...item, all: '-', expect: { percentage: '-' } }));
        return operateIndexVo.map(item => {
            if (item.key === 'profitAll' || item.key === "laborPrdtvty") {
                return ({
                    ...item,
                    all: formatMoney(operateIndex?.[item.all], "万元"),
                    expect: {
                        lastYearEnum: flagLastYearEnum[operateIndex[item.expect].lastYearEnum],
                        percentage: convertToPercentage(operateIndex[item.expect].percentage),
                    }
                })
            }
            return ({
                ...item,
                all: convertToPercentage(operateIndex[item.all]),
                expect: {
                    lastYearEnum: flagLastYearEnum[operateIndex[item.expect].lastYearEnum],
                    percentage: convertToPercentage(operateIndex[item.expect].percentage),
                }
            })
        });
    }

    useEffect(() => {
        initOperateIndex(operateIndexVo3, operateIndex)
    }, [operateIndex])
    useEffect(() => {
        findTime();
    }, []);
    const initData =()=> {
        const dateArr = date.split('-')
        const year = dateArr[0]
        const month = dateArr[1]
        startLoading();
        Promise.all([reqCoreIndexGoal(year, month), reqCoreIndexMi(year, month), reqOperateIndex(year, month)]).finally(()=> {
            stopLoading();
        });
    }
    useEffect(() => {
        initData()
    }, [date]);
    return (
        <div className='financial_indicators'>
            <ContentHeader
                onChange={handleDateChange}
                title="核心指标看板"
                info="太极集团与国药集团核心子公司财务指标"
                show={time * 100 || 0}
                defaultValue={dayjs(date)}
                isShowBreadcrumb={false}
                headerStyle={{backgroundImage:`url(${headerBg})`}}
            />
            <div className='financial_indicators-card1'>
                <div className='financial_indicators-card1-top'>
                    <div className='financial_indicators-card1-top-title'>
                        绩效指标
                    </div>
                </div>
                <div className='financial_indicators-card1-body'>
                    <Row gutter={[16, 20]} >
                        {initCoreIndexMiVo(coreIndexMiVo, coreIndexGoal, coreIndexMi).map(item => {
                            return <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={12}
                                xl={8}
                                xxl={6}
                                key={item.title}
                            ><CardDom1 data={item || {}} />
                            </Col>
                        })}
                    </Row>
                </div>
            </div>
            <div className='financial_indicators-card2'>
                <div className='financial_indicators-card2-top'>
                    <div className='financial_indicators-card2-top-title'>
                        经营指标
                    </div>
                </div>
                <div className='financial_indicators-card2-body'>
                    <Row gutter={[28, 20]} >
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={8}
                            xxl={8}>
                            <div className='financial_indicators-card2-body-item'>
                                <div className='item-header'>
                                    工资总额
                                </div>
                                {initCoreIndexMiVo(coreIndexMiVo1, coreIndexGoal, coreIndexMi).map(item => {
                                    return <CardDom1 data={item || {}} key={item.title} />
                                })}
                            </div>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={8}
                            xxl={8}>
                            <div className='financial_indicators-card2-body-item'>
                                <div className='item-header'>
                                    一增
                                </div>
                                {initOperateIndex(operateIndexVo1, operateIndex).map(item => {
                                    return <CardDom2 data={item || {}} key={item.title} />
                                })}
                            </div>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={8}
                            xxl={8}>
                            <div className='financial_indicators-card2-body-item'>
                                <div className='item-header'>
                                    一稳
                                </div>
                                {initOperateIndex(operateIndexVo2, operateIndex).map(item => {
                                    return <CardDom2 data={item || {}} key={item.title} />
                                })}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='financial_indicators-card3'>
                    <div className='item-header'>
                        四提升
                    </div>
                    <Row gutter={[28, 20]} >
                        {initOperateIndex(operateIndexVo3, operateIndex).map(item => {
                            return (
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={8}
                                    xxl={6}
                                    key={item.title}
                                >
                                    <CardDom2 data={item || {}} />
                                </Col>)
                        })}
                    </Row>
                </div>
            </div>
        </div>
    );
};
export default definePage(FinancialIndicators);
