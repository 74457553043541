export const STORAGE_KEY_PREFIX = "fuling-index";
export const ICON_URL = "//at.alicdn.com/t/c/font_4354655_70lnultxrbm.js";
// 图表横纵坐标颜色
export const echartsXYColor = "#333333";
export const echartsXYSize = 14;
// 图表颜色色卡
export const echartsColor = {
  echartsColor_0052D9: "#0052D9",
  echartsColor_2FC25B: "#2FC25B",
  echartsColor_A350F6: "#A350F6",
  echartsColor_60C5D8: "#60C5D8",
  echartsColor_6F95F8: "#6F95F8",
  echartsColor_EF7E60: "#EF7E60",
  echartsColor_EFBE55: "#EFBE55",
  echartsColor_3A7C9D: "#EFBE55",
  echartsColor_E68F58: "#E68F58",
  echartsColor_1890FF: "#1890FF",
  echartsColor_CF63D8: "#CF63D8",
  echartsColor_647AA2: "#647AA2",
  echartsColor_A75E6F: "#A75E6F",
};

export const echartsColorList = [
  "#0052D9",
  "#2FC25B",
  "#A350F6",
  "#60C5D8",
  "#6F95F8",
  "#EF7E60",
  "#EFBE55",
  "#E68F58",
  "#1890FF",
  "#CF63D8",
  "#647AA2",
  "#A75E6F",
];

export const PortraitChart = ["#6F95F8", "#60C5D8"];

// export default {

// };

export const riskTabs = [
  {
    label: "质量分析",
    key: "1",
    children: "hahaha1",
  },
  {
    label: '安全合规',
    key: '2',
    // disabled: true,
  },
];

export const riskPassRate = [
  {
    label: "原材料合格率",
    key: "rawMaterialRate",
    value: "原材料",
    PassRate: "MATERIAL_RATE",
    chartType: "materialRate",
  },
  {
    label: "辅料合格率",
    key: "accessoriesRate",
    value: "辅料",
    PassRate: "ACCESSORIES_RATE",
    chartType: "accessoriesRate",
  },
  {
    label: "包材合格率",
    key: "packageRate",
    value: "包材",
    PassRate: "PACKAGE_RATE",
    chartType: "packageRate",
  },
];

// export const staff = [
//   {label:'长期合同工',value:'',}
// ]

export const echartsXData = [
  // '11月',
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
  "2023-12",
];

export const AnchorList = [
  { href: "#material", label: "物料" },
  { href: "#device", label: "设备" },
  { href: "#efficiency", label: "人效" },
];

export const chartBarSelect = [
  {
    value: "BZ",
    label: "包装",
  },
  {
    value: "PZ",
    label: "配制",
  },
  {
    value: "MJSS",
    label: "灭菌甩水",
  },
  {
    value: "GZ",
    label: "灌装",
  },
  // {
  //   value: 'MATERIAL',
  //   label: '物料',
  // },
  // {
  //   value: 'DEVICE',
  //   label: '设备',
  // },
];

export const waterList = [
  {
    label: "平均每批水量",
    value: "avgWaterVolumePerBatch",
  },
  { label: "上限", value: "waterQuotaUpLine" },
  { label: "下限", value: "waterQuotaDownLine" },
];

export const waterLine = [
  // {
  //   label: "配制区域",
  //   value: "pzLine",
  //   key: "pz",
  // },
  // { label: "灌装&灭菌区域", value: "gzLine", key: "gz" },
  // { label: "包装区域", value: "bzLine", key: "bz" },
  // 配制&灌装&灭菌区域
  {
    label: "配制&灌装&灭菌区域",
    value: "pzAndGzAndBzLine",
    key: "pzAndGzAndBz",
  },
  { label: "包装1区", value: "bz1Line", key: "bz1" },
  { label: "包装2区", value: "bz2Line", key: "bz2" },
];

export const electricityList = [
  {
    label: "平均每批电量",
    value: "avgElecVolumePerBatchLine",
  },
  { label: "上限", value: "electricityQuotaUpLine" },
  { label: "下限", value: "electricityQuotaDownLine" },
];

export const avgList = [
  {
    label: "平均每批蒸汽量",
    value: "avgPerSteamLine",
  },
  { label: "上限", value: "avgPerSteamUpLine" },
  { label: "下限", value: "avgPerSteamDownLine" },
];

export const avg = [
  {
    label: "灭菌工序",
    value: "sterilizationProcess",
  },
  { label: "配制工序", value: "pzProcess", key: "gz" },
  // { label: "公摊", value: "publicShare", key: "bz" },
];

export const electricitypie = [
  {
    label: "重点生产设备",
    value: "productEquipment",
    // key: "pz",
  },
  {
    label: "公共设备",
    value: "publicEquipment",
    // key: "gz"
  },
  // { label: "公摊", value: "publicShare", key: "bz" },
];

export const ageList = [
  {
    label: "25岁及以下",
    value: "25",
    color: "#FFF1E7",
  },
  {
    label: "26-29岁",
    value: "29",
    color: "#FFE09F",
  },
  {
    label: "30-35岁",
    value: "35",
    color: "#FFAB95",
  },
  {
    label: "36-40岁",
    value: "40",
    color: "#FFBC7E",
  },
  {
    label: "41-45岁",
    value: "45",
    color: "#83CAEE",
  },
  {
    label: "46-50岁",
    value: "50",
    color: "#62B4FF",
  },
  {
    label: "51-55岁",
    value: "55",
    color: "#93B7F8",
  },
  {
    label: "56-59岁",
    value: "59",
    color: "#BDCEFF",
  },
];

export const manufacturingCos = [
  {
    label: "人工",
    value: "totalManufactoringCost",
  },
  {
    label: "设备",
    value: "device",
  },
  {
    label: "能源",
    value: "energy",
  },
  {
    label: "辅助生产转入",
    value: "prodHelpCost",
  },
  {
    label: "技术改造费用",
    value: "techCost",
  },
  {
    label: "其他",
    value: "otherCost",
  },
];

export const costByDonth = [
  {
    label: "其他",
    value: "otherCost",
  },
  {
    label: "辅助生产转入",
    value: "prodHelpCost",
  },
  {
    label: "技术改造费用",
    value: " techCost",
  },
  {
    label: "能源",
    value: "energy",
  },
  {
    label: "设备",
    value: "device",
  },
  {
    label: "人工",
    value: "totalManufactoringCost",
  },
];

export const energyList = [
  { value: "water", label: "水" },
  { value: "electricity", label: "电" },
  { value: "naturalGas", label: "天然气" },
  { value: "steam", label: "外购蒸汽" },
];

export const FactoryListD = ["石鼓溪", "南湖", "李渡", "龙桥"];

export enum SelectTypeName {
  BZ = "包装",
  PZ = "配制",
  MJSS = "灭菌甩水",
  GZ = "灌装",
}

export enum DrugName {
  gancao = "甘草浸膏",
  xiangyou = "广藿香油",
  lizhi = "聚山梨酯80",
  rouguiyou = "肉桂油",
  erchun = "聚乙二醇400",
  cangshu = "苍术",
  baizhi = "白芷",
  fuling = "茯苓",
  dafupi = "大腹皮",
  banxia = "半夏",
  chenpi = "陈皮",
  houpu = "厚朴",
  koufuyeneihe = "藿香正气口服液内盒",
  xiguan = "吸管",
  koufuyebiaoqian = "藿香正气口服液标签",
  shuomingshu = "藿香正气口服液说明书",
  hegezheng = "产品合格证",
  dipenggui = "低硼硅玻璃管制口服液体瓶",
}

// 核心财务指标经营指标 升 降
export const lastYearEnum = {
  RISE: true,
  FALL: false,
};

// 核心财务指标人均指标 超预期 距离预期
export const expectEnum = {
  EXCEED: true,
  NO_EXCEED: false,
};
