import MyPopover from "@/module/MyPopover";
import warnImg from "@/assets/images/warn.png";
import './index.less';
import classNames from 'classnames';

const getClassName = (style)=>`dashboard-indicator-card-${style}`;

const IndicatorCard = ({
  popoverTitle,  // 注释内容的标题
  popoverInfo, // 注释内容的信息
  warn, // 注释展示的警告语
  onClick, // 卡片点击的函数
  title, // 卡片标题
  unit = '%', // 数据单位
  flag, // 右下角的部门标志
  rate, // 右下角的占比字样
  data, // 展示的数据
  titleStyle, // 标题的颜色
  isShowError, // 是否展示错误标志
})=>{
 
  const renderRate = (value) => {
    return ((value ?? 0) * 100)?.toFixed(2);
  };

  return (
    <MyPopover
      title={popoverTitle}
      info={popoverInfo}
      warn={warn}
    >
      <div className="dashboard-indicator-card" onClick={onClick}>
        <div className={getClassName('title')} style={titleStyle}>{title}</div>
        <div className={classNames(getClassName('rate'),{
         'rate-error': isShowError,
        })}>
          {data}
          <span className={getClassName("unit")}>{unit}</span>
        </div>
        {flag ? <div className={getClassName('flag')}>{flag}</div> : null}
        {rate ? <div className={getClassName('flag')}>占比 <span style={{color:'#000'}}>{rate}</span></div> : null}
        {isShowError && (
            <img className={getClassName("errimg")} src={warnImg} alt="" />
        )}
      </div>
    </MyPopover>
  )
}

export default IndicatorCard;