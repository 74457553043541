import _ from "lodash";

export const initPager = {
  current: 1,
  pageSize: 10,
};

export const transNumToFixed = (num: number, to: number = 2) => {
  if (typeof num === "number") {
    return num.toFixed(to);
  }
  return num;
};

export const getNumPercent = (num: number, showUnit: boolean = true) => {
  if (typeof num === "number") {
    return `${transNumToFixed(num * 100)}${showUnit ? "%" : ""}`;
  }
  return num;
};
