import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Popover } from '@vs/vsf-kit';
import * as echarts from 'echarts';
import { DatePicker, Drawer, Tooltip } from 'antd';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ContentHeader from '@/module/ContentHeader';
import GuideBtn from '@/module/GuideBtn';
import up from '@/assets/images/up.png';
import down from '@/assets/images/down.png';
import layer from '@/assets/images/layer.png';
import money from '@/assets/images/money.png';
import trend from '@/assets/images/trend.png';
import NumbersScroll from '@/module/NumbersScroll';
import LineArrow from '@/module/LineArrow';
import GuideContent from './GuideContent';
import {
  echartsColor,
  echartsXYColor,
  echartsXYSize,
} from '@/config';
import './index.less';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {  CloseCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { formatCurrency } from '@/tools';
import { handleToFixed } from '@/utils';
import IndicatorCard from './IndicatorCard';
import arrow1 from '@/assets/images/arrow1.svg';
import arrow2 from '@/assets/images/arrow2.svg';
import arrow3 from '@/assets/images/arrow3.svg';
import headerBg from '@/assets/images/header-bg2.png';
import LineChart from './LineChart';

const OrganizeBrain = (props) => {
  const { global } = props?.stores || {};
  const { isLoading, startLoading, stopLoading, userInfo } = global || {};
  const [date, setDate] = useState(dayjs()?.format('YYYY-MM'));
  const [data, setData] = useState(null);
  const [drawerShow, setDrawerShow] = useState(false);
  const [number, setNumber] = useState(38960);
  const [currentChart, setCurrentChart] = useState({});
  const [elementstyle, setElementstyle] = useState({ width: 1200 });
  const [outCount, setOutCount] = useState(0);
  const timerRef = useRef();
  const onChange = (date, dateString) => {
    // console.log(date, 'date');
    setDate(dayjs(date)?.format('YYYY-MM'));
  };

  const handleQueryDataOverview = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryDataOverview_463c69({
        yearAndMonth: dayjs(date)?.format('YYYYMM'),
      });
    if (data?.code === 200) {
      console.log(data);
      setData(data?.data);
    }
  };

  const handleQueryOutCount = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryOutCount_48bf04({
        yearAndMonth: dayjs(date)?.format('YYYYMM'),
      });
    if (data?.code === 200) {
      console.log(data, '111');
      setOutCount(0);
      setOutCount(data?.data);
    }
  };
  useEffect(async () => {
    startLoading();
    await handleQueryDataOverview(date);
    await handleQueryOutCount(date);
    stopLoading();
    // let timer;
  }, [date]);

  useEffect(() => {
    clearInterval(timerRef?.current);
    timerRef.current = setInterval(() => {
      console.log('我是定时器', date);
      // handleQueryDataOverview(date);
      handleQueryOutCount(date);
    }, 60 * 1000);
    return () => {
      clearInterval(timerRef?.current);
    };
  }, []);

  useEffect(() => {
    if (currentChart) {
      const chartX = currentChart?.list?.map((v) =>
        dayjs(v?.yearAndMonth)?.format('YYYY-MM'),
      );
      const dataChart = currentChart?.list?.map((v) => {
        // unit
        return currentChart?.unit == '%'
          ? handleToFixed(v?.proportion * 100, 2)
          : handleToFixed(v?.proportion, 2);
      });
      handleDrawerChart('dash-Drawer-chart', chartX, dataChart);
    }
  }, [currentChart]);

  const handleChartClick = (value) => {
    setDrawerShow(true);
    setCurrentChart(value);
  };

  const handleDrawerChart = (id, chartX = [], dataChart = [], color) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      // ...defaultOption,
      grid: {
        left: 0,
        top: 20,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => {
          return value;
        },
      },
      xAxis: {
        type: 'category',
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format('MM月');
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: '{value}',
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: [
        {
          data: dataChart,
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const renderNum = (num, multiple) => {
    return num / multiple;
  };

  const renderproduceExpect = (rate, date) => {
    return rate > dayjs(date)?.format('MM') / 12 ? '超预期' : '未达预期';
  };

  const renderExpect = (value, num) => {
    return value < num ? '超预期' : '未达预期';
  };
  const renderRate = (value) => {
    return ((value ?? 0) * 100)?.toFixed(2);
  };

  const renderRateUnit = (value) => {
    return `${((value ?? 0) * 100)?.toFixed(2)}%`;
  };

  const renderIsUp = (value) => {
    return value > 0 ? up : down;
  };

  const renderDecimal = (value, num = 2) => {
    return value?.toFixed(num);
  };

  const renderNum1 = (value, num = 2) => {
    if (typeof value === 'number' || typeof value === 'string') {
      return value?.toFixed(num);
    } else {
      return '-';
    }
  };

  const renderErr = (value) => {
    return value < 1;
  };
  const toRisk = (anchorId, path) => () => {
    vsf.history.push(`/dashboard/risk?id=${'1'}`, {
      date: dayjs(date)?.format('YYYY-MM'),
      anchorId,
    });
  };
  const toCost = (anchorId) => () => {
    vsf.history.push(`/dashboard/cost`, {
      date: dayjs(date)?.format('YYYY-MM'),
      anchorId,
    });
  };
  const toProduction = (path) => () => {
    vsf.history.push(`/dashboard/production/overview?id=${path}`, {
      date: dayjs(date)?.format('YYYY-MM'),
    });
  };
  const handleIsNumber = (value) => {
    return typeof value === 'number' || typeof value === 'string';
  };
  // 根据屏幕宽度计算动画的相关尺寸
  function calculateSectorProperties(centralAngleDegrees) {
    const element = document.getElementById('myElement');
    const radius = element.offsetWidth / 2;
    // 将度数转换为弧度
    const centralAngleRadians = centralAngleDegrees * (Math.PI / 180);

    // 计算垂线的长度（h）
    const h = radius * Math.sin(centralAngleRadians / 2) * 2;

    // 计算垂线到圆心的距离（d）
    const d = radius - radius * Math.cos(centralAngleRadians / 2);
    setElementstyle({
      perpendicularLength: h,
      distanceToCenter: d,
      width: radius * 2,
    });
    return { perpendicularLength: h, distanceToCenter: d };
  }

  useEffect(() => {
    calculateSectorProperties(80);
    const handleResize = () => {
      calculateSectorProperties(80);
    };
    // 监听窗口大小变化
    window.addEventListener('resize', handleResize);
    // 组件卸载时移除监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="dashboard">
      <ContentHeader
        defaultValue={dayjs(date)}
        onChange={onChange}
        title="管理驾驶舱"
        info="涪陵制药厂数字化指标体系核心看板 （藿香正气口服液10支装）"
        isShowBreadcrumb={false}
        headerStyle={{backgroundImage:`url(${headerBg})`}}
      />

      <div className="dashboard-content">
        <div className="right-box">
          <div className="top">
            <div className="num-box">
              <NumbersScroll
                split={true}
                value={outCount}
                numberStyle={{
                  fontSize: 34,
                  color: '#000',
                  fontFamily: 'Oswald',
                  fontWeight: 500,
                  letterSpacing: '-1px',
                }}
              />
            </div>
            <span>件</span>
          </div>
          <div className="text">2024年产量</div>
        </div>
        <div className="hand">
          <div className="out-stock">
            <div className="title">累计出库量</div>
            <div className="num-scroll-box1">
              <div className="annual-output fontB">
                {formatCurrency(data?.outCount)}
              </div>
              <span className="unit">万件</span>
            </div>
          </div>
          <div
            style={{ height: elementstyle.distanceToCenter + 30 }}
            id="myElement"
            className="arrow"
          >
            <div
              style={{ width: elementstyle.width, height: elementstyle.width }}
              className="bg"
            ></div>
            <LineArrow
              width={elementstyle.width}
              height={elementstyle.distanceToCenter}
              style={{ position: 'absolute' }}
            />
            <div
              style={{ width: elementstyle.perpendicularLength + 40 }}
              className="arrow-bottom"
            >
              <div className="text1">生产</div>
              <div className="text2">
                质量
                <span className={classNames(`sign-tag`, `sign-down-tag`, {
                      'sign-up-tag': data?.wanyuanQualityLoss < 1,
                    })}
                >
                  {renderExpect(data?.wanyuanQualityLoss, 1)}
                </span>
              </div>
              <div className="text3">成本</div>
            </div>
            <div className="info-btn">
              <div className="change header-change" style={{ marginTop: 10 }}>
                较上年
                <img
                  style={{ marginInline: 4 }}
                  src={renderIsUp(data?.outCountCompare)}
                ></img>
                <span className="rate">
                  {renderRateUnit(data?.outCountCompare)}
                </span>
              </div>
              <GuideBtn renderContent={() => <GuideContent />} />
            </div>
          </div>
        </div>
        <div className="card-list">
          <div className="card" style={{ background: 'linear-gradient(180deg, #BDCCFF 0%, rgba(255, 255, 255, 0.00) 12%)'}}>
            <div className="card-list-overview-card">
              <div className="head">
                <div className="title">
                  <img className="icon-l" src={trend} alt="" />
                  年计划达成率<span>(%)</span>
                  {/* <span className="sign-tag sign-down-tag">
                    {renderproduceExpect(data?.annualPlanRate, date)}
                  </span> */}
                </div>
                <Popover
                  content={
                    <>
                      <p>累计入库量/年生产计划；累计计算至所选月份</p>
                      <p>累计周期：从当年1月累计至今</p>
                      <p>同比：与当年相同的累计周期</p>
                    </>
                  }
                >
                  <QuestionCircleFilled style={{fontSize: 20, color: 'rgba(0,0,0,0.15)', cursor: 'pointer'}}/>
                </Popover>
              </div>
              <div className="bottom">
                <div className="num-box">
                  <div className="num">
                    {renderRate(data?.annualPlanRate)}
                  </div>
                  <div className="change">
                    较上年
                    <img
                      style={{ marginInline: 4 }}
                      src={renderIsUp(data?.annualPlanRateCompare)}
                    ></img>
                    <span className="rate">
                      {renderRateUnit(data?.annualPlanRateCompare)}
                    </span>
                  </div>
                </div>
              </div>
              <Tooltip title="点击查看图表详情" placement="top">
               <div className="echarts">
                  <LineChart
                    id="echarts1"
                    chartX={data?.annualPlanRateLine?.map((v) => v?.yearAndMonth)}
                    dataChart = {data?.annualPlanRateLine?.map((v) =>
                    handleToFixed(v?.proportion || 0, 2))}
                    color="#6C54FF"
                    onClick={()=>
                       handleChartClick({
                        list: data?.annualPlanRateLine,
                        title: "年计划达成率",
                        rate: data?.annualPlanRate,
                        planRate: data?.annualPlanRateCompare,
                        unit: "%",
                        isUnit: true,
                        text: "累计入库量/年生产计划；累计计算至所选月份，累计周期：从当年1月累计至今，同比：与当年相同的累计周期",
                      })
                    }
                  /> 
                </div> 
               </Tooltip>
            </div>
            <div className="card-group-list list1">
              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="排产率"
                  popoverInfo="（月生产计划+库存+在制品）/（营管中心要货计划+指令未发）"
                  warn={data?.deliveryRate < 1 && '排产率<100%！'}
                  onClick={toProduction('1')}
                  title="生产排产：排产率"
                  flag="生产部"
                  data={renderRate(data?.deliveryRate)}
                  isShowError={data?.deliveryRate < 1}
                />
              </div> 
              <img src={arrow1} />
              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="采购完成率"
                  popoverInfo="采购达标的物料种类数量/应采购的物料种类数量；
                  采购达标指：实际采购量大于等于当月计划采购量的物料种类；
                  应采购指：当月有采购计划的物料种类。"
                  warn={data?.materialPurchase < 1 && '采购完成率<100%！'}
                  onClick={toProduction('2')}
                  title="物料采购：采购完成率"
                  flag="采购部"
                  data={renderRate(data?.materialPurchase)}
                  isShowError={data?.materialPurchase < 1}
                />
              </div>
              <img src={arrow1} />
              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="最差周生产计划完成率"
                  popoverInfo="周实际生产量/周生产计划；计算所选月份中最差的周情况"
                  warn={ data?.workshopProduction < 1 && '最差周生产计划完成率<100%！'}
                  onClick={toProduction('3')}
                  title="车间生产：周计划完成率"
                  flag="车间/设备部"
                  data={renderRate(data?.workshopProduction)}
                  isShowError={data?.workshopProduction < 1}
                />
              </div>
              <img src={arrow1} />
              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="库存量"
                  popoverInfo="当月成品库存量"
                  onClick={toProduction('4')}
                  title="入库出库：库存量"
                  flag="生产/物流部"
                  data={data?.stock}
                  unit="件"
                  isShowError={false}
                />
              </div>
          </div>
          </div>
          <div className="card" style={{ background: 'linear-gradient(180deg, #BDDBFF 0%, rgba(255, 255, 255, 0.00) 12%)'}}>
            <div className="card-list-overview-card">
              <div className="head">
                <div className="title">
                  <img className="icon-l" src={layer} alt="" />
                  累计万元质量损失 <span>(元)</span>
                </div>
                <Popover content="累计质量损失/万元">
                  <QuestionCircleFilled style={{fontSize: 20, color: 'rgba(0,0,0,0.15)', cursor: 'pointer'}}/>
                </Popover>
              </div>
              <div className="bottom">
                <div className="num-box">
                  <div
                    // className="num prick"
                    className={classNames(`num`, {
                      prick: data?.wanyuanQualityLoss > 1,
                    })}
                  >
                    {/* {data?.wanyuanQualityLoss?.toFixed(2)} */}
                    {handleIsNumber(data?.wanyuanQualityLoss)
                      ? data?.wanyuanQualityLoss?.toFixed(2)
                      : '-'}
                  </div>
                  <div className="change">
                    <span>VS</span>
                    <span className="rate">&nbsp;目标 1元</span>
                  </div>
                </div>
              </div>
              <Tooltip title="点击查看图表详情" placement="top">
                <div className="echarts">
                  <LineChart
                    id="echarts2"
                    chartX={ data?.wanyuanQualityLossLine?.map((v) => v?.yearAndMonth)}
                    dataChart = { data?.wanyuanQualityLossLine?.map((v) =>
                    handleToFixed(v?.proportion || 0, 2))}
                    color="#0094FF"
                    onClick={()=>
                      handleChartClick({
                        list: data?.wanyuanQualityLossLine,
                        title: '累计万元质量损失',
                        rate: data?.wanyuanQualityLoss,
                        unit: '元',
                        isUnit: false,
                        text: '累计质量损失/万元',
                      })
                    }
                  /> 
                </div>
              </Tooltip>
            </div>
           
            <div className="card-group-list list2">
              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="物料合格率"
                  popoverInfo="当月物料合格批次/物料检验批次；"
                  warn={data?.materialQualityCheck < 1 && '物料合格率<100%！'}
                  onClick={toRisk('anchor2')}
                  title="物料质检：物料合格率"
                  flag="质检部"
                  data={renderRate(data?.materialQualityCheck)}
                  titleStyle={{ background: '#ECF7FF' }}
                  isShowError={ data?.materialQualityCheck < 1 }
                />
              </div>
              <img src={arrow2} />

              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="半成品合格率"
                  popoverInfo="当月半成品合格批次/半成品检验批次；"
                  warn={data?.incompleteQualityCheck < 1 && '半成品合格率<100%！'}
                  onClick={toRisk('anchor3')}
                  title="半成品质检：半成品合格率"
                  flag="质检部"
                  data={renderRate(data?.incompleteQualityCheck)}
                  titleStyle={{ background: '#ECF7FF' }}
                  isShowError={ data?.incompleteQualityCheck < 1 }
                />
              </div>
              <img src={arrow2} />

              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="成品合格率"
                  popoverInfo="当月成品合格批次/成品检验批次；"
                  warn={data?.completeQualityCheck < 1 && '成品合格率<100%！'}
                  onClick={toRisk('anchor3')}
                  title="成品质检：成品合格率"
                  flag="质检部"
                  data={renderRate(data?.completeQualityCheck)}
                  titleStyle={{ background: '#ECF7FF' }}
                  isShowError={ data?.completeQualityCheck < 1 }
                />
              </div>
              <img src={arrow2} />

              <div className="card-group-item">
                <IndicatorCard
                  popoverTitle="市场反馈"
                  popoverInfo="当月用户投诉总数"
                  warn={
                    data?.marketFeedback > 0 &&
                    `${dayjs(date)?.format('MM月')}投诉${data?.marketFeedback
                    }次，${data?.warnFeedbackReason}`
                  }
                  onClick={toRisk('anchor4')}
                  title="市场反馈：用户投诉数"
                  flag="质保部"
                  data={data?.marketFeedback}
                  unit="次"
                  titleStyle={{ background: '#ECF7FF' }}
                  isShowError={ data?.marketFeedback > 0 }
                />
              </div>
            </div>
          </div>
          <div className="card" style={{ background: 'linear-gradient(180deg, #FFF4BB 0%, rgba(255, 255, 255, 0.00) 12%)'}}>
            <div className="card-list-overview-card">
              <div className="head">
                <div className="title">
                  <img className="icon-l" src={money} alt="" />
                  累计单盒成本  <span>(元/盒)</span>
                  {/* <span className="sign-tag sign-down-tag">未达预期</span> */}
                </div>
                <Popover
                  placement="topLeft"
                  content={
                    <>
                      <p>累计总成本/累计总产量；</p>
                      <p>累计计算至所选月份</p>
                    </>
                  }
                >
                  <QuestionCircleFilled style={{fontSize: 20, color: 'rgba(0,0,0,0.15)', cursor: 'pointer'}}/>
                </Popover>
              </div>
              <div className="bottom">
                <div className="num-box">
                  <div className="num">
                    {data?.accumulatedCost?.toFixed(2)}
                  </div>
                  <div className="change">
                    较上年
                    <img
                      style={{ marginInline: 4 }}
                      src={renderIsUp(data?.accumulatedCostCompare)}
                    ></img>
                    <span className="rate">
                      {renderRateUnit(data?.accumulatedCostCompare)}
                    </span>
                  </div>
                </div>
              </div>
              <Tooltip title="点击查看图表详情" placement="top">
                <div className="echarts">
                  <LineChart
                    id="echarts3"
                    chartX={data?.accumuLatedcostLine?.map((v) => v?.yearAndMonth)}
                    dataChart = {data?.accumuLatedcostLine?.map((v) =>
                      handleToFixed(v?.proportion || 0, 2))}
                    color="#F8C100"
                    onClick={()=>
                      handleChartClick({
                        list: data?.accumuLatedcostLine,
                        title: '累计单盒成本',
                        rate: data?.accumulatedCost,
                        planRate: data?.accumulatedCostCompare,
                        unit: '元/盒',
                        isUnit: true,
                        text: '累计总成本/累计总产量；累计计算至所选月份',
                      })
                    }
                  /> 
                </div> 
              </Tooltip>
            </div>
           
            <div className="card-group-list list3">
            <div className="card-group-item">
              <IndicatorCard
                popoverTitle="累计原辅料费用占比"
                popoverInfo="原辅料费用/单盒成本费用；累计计算至所选月份"
                onClick={toCost('rawMaterial')}
                title="原辅料：原辅料费用"
                rate={renderRateUnit(data?.rawMaterialCompare)}
                unit="元/盒"
                data={renderDecimal(data?.rawMaterialCost, 2)}
                titleStyle={{ background: '#FCF9E2' }}
                isShowError={ false }
              />
            </div>
            <img src={arrow3} />

            <div className="card-group-item">
              <IndicatorCard
                popoverTitle="累计包装材料费用占比"
                popoverInfo="包装材料费用/单盒成本费用；累计计算至所选月份"
                onClick={toCost('createCost')}
                title="包装：包装材料费用"
                rate={renderRateUnit(data?.packageMaterialCompare)}
                unit="元/盒"
                data={renderDecimal(data?.packageMaterialCost, 2)}
                titleStyle={{ background: '#FCF9E2' }}
                isShowError={ false }
              />
            </div>
            <img src={arrow3} />

            <div className="card-group-item">
              <IndicatorCard
                popoverTitle="累计制造费用占比"
                popoverInfo="制造费用/单盒成本费用；累计计算至所选月份"
                onClick={toCost('createCost')}
                title="制造：制造费用"
                rate={renderRateUnit(data?.createCompare)}
                unit="元/盒"
                data={renderDecimal(data?.createCost, 2)}
                titleStyle={{ background: '#FCF9E2' }}
                isShowError={ false }
              />
            </div>
            <img src={arrow3} />

            <div className="card-group-item">
              <IndicatorCard
                popoverTitle="累计直接人工费用占比"
                popoverInfo="直接人工费用/单盒成本费用；累计计算至所选月份"
                onClick={toCost('createCost')}
                title="直接人工：直接人工费用"
                rate={renderRateUnit(data?.personCompare)}
                unit="元/盒"
                data={renderDecimal(data?.personCost, 2)}
                titleStyle={{ background: '#FCF9E2' }}
                isShowError={ false }
              />
            </div>
          </div>
          </div>
        </div>
      </div>
      <Drawer
        // title=""
        placement="right"
        onClose={() => setDrawerShow(false)}
        size="large"
        open={drawerShow}
      >
        <div className="dash-Drawer">
          <div className="dash-Drawer-header">
            <div className="title">
              {currentChart?.title}
              <span className="unit">单位：{currentChart?.unit}</span>
            </div>
            <CloseCircleFilled
              onClick={() => setDrawerShow(false)}
              style={{ color: '#BEBEBE' }}
            ></CloseCircleFilled>
          </div>
          <div className="dash-text"> {currentChart?.text}</div>
          <div className="num">
            <div>
              {currentChart?.unit == '%'
                ? renderRate(currentChart?.rate)
                : renderNum1(currentChart?.rate, 2)}
            </div>
            <span> {currentChart?.unit}</span>
          </div>

          {currentChart?.isUnit && (
            <div className="num-up">
              较上年
              <img
                style={{ marginInline: 4 }}
                src={renderIsUp(currentChart?.planRate)}
              ></img>
              <span>{renderRateUnit(currentChart?.planRate)}</span>
            </div>
          )}
          {!currentChart?.isUnit && (
            <div className="num-vs">
              <div>VS</div>
              <span className="num">目标 1元</span>
            </div>
          )}
          <div className="dash-Drawer-chart" id="dash-Drawer-chart"></div>
        </div>
      </Drawer>
    </div>
  );
};
export default definePage(OrganizeBrain);
