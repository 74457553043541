import { definePage } from '@vs/vsf-boot';
import { Layout, Page, Section } from '@vs/vsf-kit';
import React from 'react';

const Index: React.FC = () => {
  return (
    <Layout>
      <Page title="空白模板">
        <Section></Section>
      </Page>
    </Layout>
  );
};

export default definePage(Index);
