import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable, VSTableAction, Modal, Button, Input, Drawer, Form, VSFormItem, VSForm } from '@vs/vsf-kit';
import { ExclamationCircleFilled, SearchOutlined, CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import PassWordText from '@/module/PasswordText'
import React, { useState, useCallback, useEffect } from 'react';
import searchImg from '@/assets/images/search.png'
import './index.less'
const regex1 = new RegExp(/^[A-Za-z0-9@._]{6,30}$/);
const regex2 = new RegExp(/^[A-Za-z0-9@._!#$%&]{6,30}$/);

const UserListPage = (props) => {
  const { global } = props?.stores || {};
  const {
    isLoading,
    startLoading,
    stopLoading,
    userInfo,
  } = global || {};
  const [searchParams, setSearchParams] = useState()
  const [form] = Form.useForm();
  const [emailMessage, setEmailMessage] = useState(); //表单提示
  const [passwordMessage, setPasswordMessage] = useState();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [user, setUser] = useState();
  const [isUserName, setIsUserName] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setUser(null);
  };

  const handelRemoveClick = async (id) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.FlUserController_delete_b3963f?.({ id }); // Tips: 需要返回删除成功与否
    if (res.code === 200) {
      vsf.showToast('删除数据成功', 'success')
      await vsf?.refs?.vSPromiseTable615999?.reload();
    } else {
      vsf.showToast('删除数据失败', 'error')
    }
    return res?.code === 200;
  }

  const reqUserList = useCallback(async (params) => {
    // startLoading();
    const res =
      await vsf.services?.FlUserController_queryUser_4ed5db?.({
        page: params.pagination?.from + 1 ?? 1,
        size: params.pagination?.size ?? 20,
        ...params.extra,
      }); // TODO: 你可能需要整理返回数据格式
    // stopLoading();
    return {
      data: res?.data?.result ?? [],
      total: res?.data?.count ?? 0,
    };
  }, [])

  const validateFields = (userInfo) => {
    const user = userInfo || form.getFieldsValue();
    const isValid1 = user.userName && regex1.test(user.userName); // 返回 true 或 false
    const isValid2 = user.userPwd && regex2.test(user.userPwd); // 返回 true 或 false
    // if (isValid1) {
    //   setEmailMessage(undefined)
    //   form.validateFields(['userName'])
    // }
    if (isValid2) {
      setPasswordMessage(undefined)
      form.validateFields(['userPwd'])
    }
    setDisabled(!(isValid1 && isValid2))
    return !(isValid1 && isValid2)
  }

  const validateCustom = async (rule, value) => {
    if (user) return Promise.resolve();
    if (!value || !regex1.test(value)) {
      setDisabled(true)
      return Promise.reject('由 6-30 位字母 + 数字 + 符号（@、_、. ）组成，不含空格，字母区分大小写');
    }
    try {
      const res =
        await vsf.services?.FlUserController_queryUserName_d1b007?.({
          username: value,
        }); // TODO: 你可能需要整理返回数据格式
      if (res.code === 200 && res.data === 0) {
        setIsUserName(true)
        return Promise.reject('该账号已存在，请重新设置');
      } else {
        validateFields()
        setIsUserName(false)
        return Promise.resolve();
      }
    } catch (error) {
      console.log('Validation error: Network or server issue');
    }
    return Promise.resolve();
  };

  const submit = async () => {
    const params = form.getFieldsValue()
    console.log(params);
    let res
    if (!user) {
      res =
        await vsf.services?.FlUserController_createUser_c1c424?.({
          flUserEo: {
            ...params
          }
        }); // Tips: 需要返回提交的完整数据，和表单RO一致
    } else {
      res =
        await vsf.services?.FlUserController_updateById_08ab79?.({
          eo: {
            ...user,
            ...params
          }
        }); // Tips: 需要返回提交的完整数据，和表单RO一致
    }
    if (res.code == 200) {
      vsf.showToast('保存成功', 'success')
      onClose()
      await vsf?.refs?.vSPromiseTable615999?.reload();
    } else {
      Modal.confirm({
        title: '保存数据失败了',
        icon: <CloseCircleFilled style={{ color: '#D54941' }} />,
        content: '系统异常',
        okText: '确定',
        cancelText: '取消',
        centered: true,
        // onOk: () => { },
        // onCancel() { },
        closable: true,
        className: 'my-modal-confirm',
        width: 480,
      });
    }
    return params;
  }

  useEffect(() => {
    if (userInfo?.role === 'USER') return vsf.navigateTo('/dashboard/overview');
  }, [userInfo])

  return (
    <Section className='user'>
      <div className='page-title'>账号管理</div>
      <VSPromiseTable
        vsid="08305"
        id='vSPromiseTable615999'
        editable={{
          editType: 'single',
          saveOnClickOutside: true,
          columnProps: {
            hideInTable: true,
            width: 80
          }
        }}
        className='user-table'
        extraParams={searchParams}
        searchFormRender={(props, defaultDom, options) => {
          return <div style={{ display: 'flex', gap: 24, paddingBlock: 24 }}>
            <Button onClick={() => { showDrawer(); setDisabled(true) }} type="primary"><PlusOutlined />账号</Button>
            <Input onPressEnter={(e) => setSearchParams(e.target.value ? { userName: e.target.value.trim() } : {})} width={208} suffix={<img style={{ width: 14, height: 14 }} src={searchImg}></img>} />
          </div>
        }}
        searchConfig={{
          autoSearch: true,
          optionButtons: [],
        }}
        onFetch={reqUserList}
        pagination={{
          defaultPageSize: 15,
          showSizeChanger: true,
          showQuickJumper: true,
        }}
      >
        <VSTableColumn
          dataIndex={['userName']}
          title="账号名"
          valueType="text"
          order={-2}
          fieldProps={{}}
          search
          render={(dom, record) => <Button style={{ fontSize: 18, padding: 0 }} type="link" onClick={() => { showDrawer(); setUser(record); validateFields(record) }}>{record.userName}{record.id === userInfo.id && ' (我)'}</Button>}
        />
        <VSTableColumn
          dataIndex={['role']}
          title="账号类型"
          valueType="text"
          fieldProps={{}}
          render={(dom, record) => record.role === 'ADMIN' ? '管理员' : '用户'}
        />
        <VSTableColumn
          dataIndex={['userPwd']}
          title="密码"
          valueType="text"
          fieldProps={{}}
          render={(dom, record) => <PassWordText value={record.userPwd} />}
        />
        <VSTableColumn
          dataIndex={['gmtCreate']}
          title="新增时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
        <VSTableColumn
          // width={100}
          title='操作'
          valueType='option'
          preview
        >
          <VSTableAction
            onClick={(e, data) => {
              showDrawer()
              setUser(data)
              validateFields(data)
            }}
          >编辑</VSTableAction>
          <VSTableAction
            onClick={(e, data) => {
              Modal.confirm({
                title: <span>确定删除{data.userName}的账号数据吗？</span>,
                icon: <ExclamationCircleFilled style={{ color: '#FFA900' }} />,
                content: <><p>删除后数据将无法恢复。</p><p>您可以通过左上角的“添加账号”重新进行数据新增。</p></>,
                okText: '确定删除',
                cancelText: '取消',
                centered: true,
                onOk: async () => await handelRemoveClick(data.id),
                onCancel() { },
                closable: true,
                className: 'my-modal-confirm',
                width: 480,
              });
            }}
            show={(record) => record.role !== 'ADMIN'}
          >删除</VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
      <Drawer
        className='user-my-drawer'
        closable={false}
        width={452}
        title={!user ? "添加账号" : "编辑账号"}
        placement="right"
        footerStyle={{ borderTop: 'none' }}
        headerStyle={{ borderBottom: 'none' }}
        footer={<div className='my-drawer-footer'>
          <Button className='btn' disabled={disabled || isUserName} onClick={()=> {console.log(form.getFieldsValue());submit()}} type="primary">保存</Button>
          <Button className='btn close' onClick={onClose}>取消</Button>
        </div>}
        destroyOnClose
        getContainer={() => document.getElementById('layout_main')}
        onClose={onClose}
        open={open}>
        <VSForm
          className='required-form'
          form={form}
          vsid="14719"
          style={{ height: "max-content" }}
          params={props.routes?.query}
          layout="vertical"
          onValuesChange={(values) => {
            if (values.hasOwnProperty('userPwd')) {
              validateFields()
            }
          }}
          initialValues={user}
        >
          <VSFormItem
            label="账号名"
            name="userName"
            readonly={user ? true : false}
            rules={[
              {
                required: true,
                validator: validateCustom
              }
            ]}
            help={emailMessage}
            validateTrigger="onBlur"
            placeholder="请输入内容"
            onFocus={() => !form.getFieldError('userName').length && setEmailMessage('由 6-30 位字母 + 数字 + 符号（@、_、. ）组成，不含空格，字母区分大小写')}
            onBlur={() => setEmailMessage(null)}
          />
          <VSFormItem
            label="密码"
            name="userPwd"
            // valueType="password"
            rules={[
              {
                required: true,
                message: '由 6-30 位字母 + 数字 + 符号（@、_、.、!、#、$、%、&)组成，不含空格，字母区分大小写',
                pattern: regex2,
              },
            ]}
            help={passwordMessage}
            validateTrigger="onBlur"
            placeholder="请输入内容"
            onFocus={() => setPasswordMessage('由 6-30 位字母 + 数字 + 符号（@、_、.、!、#、$、%、&)组成，不含空格，字母区分大小写')}
            onBlur={() => setPasswordMessage(null)}
          />
          <VSFormItem
            readonly
            name={['role']}
            label="账号类型"
            initialValue='USER'
            required={false}
            valueType="select"
            dataSource={[
              { label: '普通账号', value: 'USER' },
              { label: '管理员', value: 'ADMIN' },
            ]}></VSFormItem>
        </VSForm>
      </Drawer>
    </Section>
  );
};
export default definePage(UserListPage);
