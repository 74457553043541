/**
 * 分析指引按钮，侧滑
 * @param {
 * title: string || undefined,
 * value: string || Array || undefined,
 * renderContent: Function || undefined,
 * }
 */

import vsf, { definePage } from '@vs/vsf-boot';
import { DatePicker, Drawer } from "antd"
import React, { useState } from 'react';
import {
    CaretRightOutlined,
    CloseCircleFilled
} from '@ant-design/icons';
import './index.less'
const GuideBtn = ({ title, renderContent, value }) => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <div className='el-guide'>
            <div onClick={showDrawer} className='guide-btn'>
                分析指引<CaretRightOutlined style={{ color: '#333333' }} />
            </div>
            <Drawer
                className='guide-drawer'
                closable={false}
                width={818}
                title={<div className='drawer-header'><span>{title || "分析指引"}</span><CloseCircleFilled onClick={onClose} style={{ color: '#BEBEBE' }} /></div>}
                placement="right"
                footerStyle={{ borderTop: 'none' }}
                headerStyle={{ borderBottom: 'none' }}
                footer={false}
                destroyOnClose
                getContainer={() => document.getElementById('layout_main')}
                onClose={onClose}
                open={open}>
                {renderContent ? renderContent(value) : value}
            </Drawer>
        </div>
    );
};

export default GuideBtn;
