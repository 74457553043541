import { createServices } from '@vs/vsf-boot';
export default createServices({
  LiduWorkShopController_findByYieldRateAndDevice_2fd9ab: {
    method: 'post',
    url: '/api/data-analysis/find-by-yield-rate-and-device',
    parameterFomatter: (data?: {
      year: number;
      month: string;
      liduWorkshopEnum: LiduWorkshopEnum;
    }) => data,
    responseFormatter: (_, __, data?: LiduWorkshopVo89B7A8[]) => data,
  },
  LiduWorkShopController_findInvasive_f199f9: {
    method: 'post',
    url: '/api/data-analysis/find-invasive',
    parameterFomatter: (data?: { year: number }) => data,
    responseFormatter: (_, __, data?: InvasiveVo89B7A8) => data,
  },
  LiduWorkShopController_findDeviceRate_507c21: {
    method: 'post',
    url: '/api/data-analysis/find-device-rate',
    parameterFomatter: (data?: {
      year: number;
      month: string;
      liduWorkshopEnum: LiduWorkshopEnum;
      deviceRateEnum: DeviceRateEnum;
    }) => data,
    responseFormatter: (_, __, data?: DeviceRateVo89B7A8) => data,
  },
  LiduWorkShopController_findOee_b7c502: {
    method: 'post',
    url: '/api/data-analysis/find-oee',
    parameterFomatter: (data?: {
      year: number;
      month: string;
      liduWorkshopEnum: LiduWorkshopEnum;
    }) => data,
    responseFormatter: (_, __, data?: LiduDeviceOeeVo89B7A8) => data,
  },
  DataOverviewController_queryMainIndexManagerEo_f767f3: {
    method: 'post',
    url: '/api/data-overview/query-main-index-manager-eo',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: MainIndexManagerEoB57Eed) => data,
  },
  DataOverviewController_queryWorkshopProductionPage_32655e: {
    method: 'post',
    url: '/api/data-overview/query-workshop-production-page',
    parameterFomatter: (data?: { yearAndMonth: string }) => data,
    responseFormatter: (_, __, data?: WorkshopProductionPageEoB57Eed) => data,
  },
});
