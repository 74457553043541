import { definePage } from '@vs/vsf-boot';
import { Layout, Page, Section } from '@vs/vsf-kit';
import React from 'react';

const FactoryAreaEnergy: React.FC = () => {
  return (
    <Layout>
      <Page title="厂区能源">
        <Section></Section>
      </Page>
    </Layout>
  );
};

export default definePage(FactoryAreaEnergy);
