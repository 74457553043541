import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Layout, Page } from '@vs/vsf-kit';
import React from 'react';

const Container = (props) => {
  const { route } = props;
  return (
    <>{renderRoutes(route.routes, route.redirect)}</>
  );
};

export default definePage(Container);
