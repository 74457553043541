import request from "./request";

// const prefix = "/api";
const prefix = "";

// 获取品规列表
const getPrdSpecs = (payload) => {
  return request({
    url: `${prefix}/prdability/prdSpecs`,
    method: "POST",
    data: payload,
  });
};

// 获取日生产进度
const getDailyRecords = (payload) => {
  return request({
    url: `${prefix}/prdability/daily_records`,
    method: "POST",
    data: payload,
  });
};

// 获取月产值统计
const getMonthSummary = (payload) => {
  return request({
    url: `${prefix}/prdability/month_summary`,
    method: "POST",
    data: payload,
  });
};

// 获取年产值统计｜获取年计划完成率趋势
const getYearSummary = (payload) => {
  return request({
    url: `${prefix}/prdability/year_summary`,
    method: "POST",
    data: payload,
  });
};

export { getPrdSpecs, getDailyRecords, getMonthSummary, getYearSummary };
