import vsf, { definePage } from "@vs/vsf-boot";
import { Section } from "@vs/vsf-kit";
import { DatePicker } from "antd";
import React, { useState, useEffect, useRef } from "react";
import ContentHeader from "@/module/ContentHeader";
import GuideBtn from "@/module/GuideBtn";
import { formatMoney, convertToPercentage, convertToFixed } from "@/tools";
import {
  lastYearEnum as flagLastYearEnum,
  expectEnum as flagExpectEnum,
  ageList,
} from "@/config";
import {
  echartsColor,
  echartsColorList,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
  PortraitChart,
} from "@/config";
import up from "@/assets/images/up.png";
import empty from "@/assets/images/empty.png";
import down from "@/assets/images/down.png";
import dayjs from "dayjs";
import * as echarts from "echarts";
import "./index.less";

// 绩效指标
const mngmtGroupAvgIndexYiVo = [
  {
    title: "全员劳动生产率",
    info1: "工业增加值",
    info2: "从业人员年平均人数",
    key: "laborPrdtvty",
    all: "laborPrdtvty",
    expect: "laborPrdtvtyLastYear",
  },
  {
    title: "人均营业收入",
    info1: "营业收入(万元)",
    info2: "从业人员年平均人数",
    key: "revenuePerPpl",
    all: "revenuePerPpl",
    expect: "revenuePerPplLastYear",
  },
  {
    title: "人均利润",
    info1: "利润总额(万元)",
    info2: "从业人员年平均人数",
    key: "profitPerPpl",
    all: "profitPerPpl",
    expect: "profitPerPplLastYear",
  },
  // {
  //   title: '人均工资',
  //   info1: '从业人员工资总额(万元)',
  //   info2: '从业人员年平均人数',
  //   key: 'salaryPerPpl',
  //   all: 'salaryPerPpl',
  //   expect: 'salaryPerPplLastYear',
  // },
  {
    title: "人事费用率",
    info1: "人工成本总额",
    info2: "销售收入",
    key: "personProportion",
    all: "personProportion",
    expect: "personProportionCompare",
  },
  {
    title: "人工成本利润率",
    info1: "利润总额(万元)",
    info2: "人工成本总额(万元)",
    key: "laborCostProfitRate",
    all: "laborCostProfitRate",
    expect: "laborCostProfitRateLastYear",
  },
  {
    title: "人均工资",
    info1: "从业人员工资总额(万元)",
    info2: "从业人员年平均人数",
    key: "salaryPerPpl",
    all: "salaryPerPpl",
    expect: "salaryPerPplLastYear",
  },
  // {
  //   title: '人事费用率',
  //   info1: '人工成本总额',
  //   info2: '销售收入',
  //   key: 'personProportion',
  //   all: 'personProportion',
  //   expect: 'personProportionCompare',
  // },
];
const OrganizeBrain = (props) => {
  const { global } = props?.stores || {};
  const { startLoading, stopLoading } = global || {};
  const [date, setDate] = useState(dayjs().format("YYYY"));
  const [mngmtGroupAvgIndexYi, setMngmtGroupAvgIndexYi] = useState();
  const [mouseoverBar, setMouseoverBar] = useState();
  const [ageDataList, setageList] = useState();
  const [currentBar, setCurrentBar] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [bottomData, setbottomData] = useState(null);
  const [topData, settopData] = useState(null);
  const barRef = useRef();

  useEffect(async () => {
    // console.log(date);
    startLoading();
    await findMngmtGroupAvgIndexYi(date);
    await handleQueryDersonCount(date);
    stopLoading();
    const arr = [100, 100, 200, 300, 499, 99];
    // handlePortraitBar(
    //   'portrait-chart',
    //   [
    //     '数字化领军人才',
    //     '设备技术人才',
    //     '中药技能人才',
    //     '中层管理人才',
    //     '后备人才',
    //   ],
    //   [arr, arr],
    // );
  }, [date]);

  useEffect(() => {
    if (currentBar) {
      handleQueryEnumselectcount(date);
    }
  }, [date, currentBar]);

  useEffect(() => {
    if (chartData) {
      const chartX1 = chartData?.lastTalentList?.map((v) => {
        return {
          value: v?.type,
          textStyle: {
            opacity:
              v?.type == currentBar || v?.type == mouseoverBar ? 1 : 0.35,
          },
        };
      });
      const chartData1 = renderDataChart(chartData?.lastTalentList, 1);
      const chartData2 = renderDataChart(chartData?.talentList, 0);
      const lengtarr = [...chartData1?.data, ...chartData2?.data];
      const lengt =
        lengtarr
          ?.sort((a, b) => a - b)
          ?.[lengtarr?.length - 1]?.value?.toString().length * 50;
      console.log(
        lengt,
        lengtarr
          ?.sort((a, b) => a - b)
          ?.[lengtarr?.length - 1]?.value?.toString().length,
        lengtarr?.sort((a, b) => a - b)?.[lengtarr?.length - 1]
      );
      settopData([...chartData?.lastTalentList, ...chartData?.talentList]);
      handlePortraitBar(
        "portrait-chart",
        chartX1,
        [chartData1, chartData2],
        lengt
      );
    }
  }, [chartData, currentBar, mouseoverBar]);

  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYY"));
  };
  const findMngmtGroupAvgIndexYi = async (year) => {
    const res =
      await vsf.services.MngmtGroupAvgIndexController_findMngmtGroupAvgIndexYi_db043d(
        { year }
      );
    if (res.code === 200) {
      setMngmtGroupAvgIndexYi(res.data);
    }
  };
  // 格式化绩效指标数据
  const initMngmtGroupAvgIndexYi = (mngmtGroupAvgIndexYi) => {
    if (!mngmtGroupAvgIndexYi)
      return mngmtGroupAvgIndexYiVo.map((item) => ({
        ...item,
        all: "-",
        goal: "-",
        expect: { percentage: "-" },
      }));
    return mngmtGroupAvgIndexYiVo.map((item) => {
      if (item.key === "laborCostProfitRate") {
        return {
          ...item,
          all: convertToPercentage(mngmtGroupAvgIndexYi[item.all]),
          expect: {
            lastYearEnum:
              flagLastYearEnum[mngmtGroupAvgIndexYi[item.expect]?.lastYearEnum],
            percentage: convertToPercentage(
              mngmtGroupAvgIndexYi[item.expect]?.percentage
            ),
          },
        };
      }
      if (item.key === "personProportion") {
        return {
          ...item,
          all: convertToPercentage(mngmtGroupAvgIndexYi[item.all]),
          expect: {
            lastYearEnum:
              flagLastYearEnum[mngmtGroupAvgIndexYi[item.expect]?.lastYearEnum],
            percentage: convertToPercentage(
              mngmtGroupAvgIndexYi[item.expect]?.percentage
            ),
          },
        };
      }
      return {
        ...item,
        all: formatMoney(mngmtGroupAvgIndexYi[item.all], "万元"),
        expect: {
          lastYearEnum:
            flagLastYearEnum[mngmtGroupAvgIndexYi[item.expect]?.lastYearEnum],
          percentage: convertToPercentage(
            mngmtGroupAvgIndexYi[item.expect]?.percentage
          ),
        },
      };
    });
  };

  const handlePortraitBar = (id, chartX = [], dataChart = [], lengt) => {
    console.log(dataChart, lengt, "dataChartlengtlengtlengtlengt");
    let myChart;
    const dom = document.getElementById(id);
    dom && (myChart = echarts.init(dom));
    barRef.current = myChart;
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      color: PortraitChart,
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 10,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
      },
      grid: {
        left: lengt / 3,
        containLabel: true,
        top: 60,
        right: 0,
        bottom: 40,
      },
      xAxis: {
        type: "category",
        name: "人才类型",
        nameLocation: "middle",
        nameTextStyle: {
          color: "#333333",
          fontSize: 14,
          padding: [20, 0, 0, 0],
        },
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          // formatter: (value) => {
          //   return dayjs(value)?.format('MM月');
          // },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          // opacity:mouseoverBar
          // rotate: 45,
        },
      },
      yAxis: {
        // name: '数量',
        // nameLocation: "middle",
        name: "数\n量",
        // position: 'left',
        nameLocation: "left",
        // nameRotate: -90,
        // nameLocation: 'left',
        nameTextStyle: {
          padding: [0, lengt, 0, 0],
          color: "#333333",
          fontSize: 14,
        },
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: dataChart,
    };
    barRef?.current?.on("mouseover", function (params) {
      // console.log('-----');
      if (params.componentType === "series") {
        // params.seriesType === 'bar' 表示触发的是柱状图的事件
        if (params.seriesType === "bar") {
          // params.dataIndex 表示鼠标当前移入的是哪个柱子
          console.log("鼠标移入了柱子", params?.name);
          setMouseoverBar(params?.name);
          // 你可以在这里编写你需要执行的逻辑
        }
      }
    });
    barRef?.current?.on("mouseout", function (params) {
      if (params.componentType === "series") {
        // params.seriesType === 'bar' 表示触发的是柱状图的事件
        if (params.seriesType === "bar") {
          // params.dataIndex 表示鼠标当前移出的是哪个柱子
          console.log("鼠标移出了柱子", params.dataIndex);
          setMouseoverBar("");
          // 你可以在这里编写你需要执行的逻辑
        }
      }
    });
    barRef?.current?.on("click", function (params) {
      console.log("-----");
      if (params.componentType === "series") {
        // params.seriesType === 'bar' 表示触发的是柱状图的事件
        if (params.seriesType === "bar") {
          // params.dataIndex 表示鼠标当前移入的是哪个柱子
          console.log("鼠标点击了柱子", params?.name);
          setMouseoverBar(params?.name);
          setCurrentBar(params?.name);
          // 你可以在这里编写你需要执行的逻辑
        }
      }
    });
    dom && option && myChart.setOption(option, true);
  };

  const handlepieChart = (id, chartData) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) => {
          return value;
        },
      },
      series: [
        {
          type: "pie",
          // radius: [20, 140],
          // roseType: 'area',
          itemStyle: {
            borderRadius: 0,
          },
          radius: "50%",
          data: chartData,
          label: {
            // width: 140,
            overflow: "break",
            formatter: "{b}\n\n{c}",
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const handleBarChart = (id, chartX = [], dataChart = []) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    barRef.current = myChart;
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 0,
        top: 20,
        right: 40,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          // formatter: (value) => {
          //   return dayjs(value)?.format('MM月');
          // },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: -45,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: [
        {
          data: dataChart,
          type: "bar",
          smooth: true,
          showSymbol: false,
          // name: '售后厂区库存',
          lineStyle: {
            color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // opacity: 0.1,
          },
          itemStyle: {
            color: echartsColor?.echartsColor_0052D9,
            // opacity: mouseoverBar ? 0.5 : 1,
          },

          emphasis: {
            itemStyle: {
              color: echartsColor?.echartsColor_0052D9,
              opacity: 1,
            },
          },
          barWidth: 18,
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const handlestaffBar = (id, chartY, chartData) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      // tooltip: {
      //   trigger: 'axis',
      //   axisPointer: {
      //     type: 'shadow',
      //   },
      // },
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 10,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      xAxis: [
        {
          show: false,
          type: "value",
        },
      ],
      yAxis: [
        {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#ABC3FF", // 这里设置y轴颜色为红色
            },
          },
          axisLabel: {
            color: "#333333",
            fontSize: 16,
          },
          data: chartY,
        },
      ],
      series: [
        {
          name: "长期合同工",
          type: "bar",
          stack: "Total",
          label: {
            show: true,
            position: "insideRight",
            distance: 10,
            formatter: (value) => {
              // console.log(value, 'va');
              return `${Math?.abs(value?.value)}人`;
            },
            color: "#000000",
            fontSize: 16,
          },
          emphasis: {
            disabled: true,
          },
          itemStyle: {
            color: "#89B8FF",
          },
          data: chartData?.[0],
          barWidth: 38,
        },
        {
          name: "短期合同工",
          type: "bar",
          stack: "Total",
          label: {
            show: true,
            position: "insideLeft",
            distance: 10,
            formatter: (value) => {
              return `${Math?.abs(value?.value)}人`;
            },
            color: "#000000",
            fontSize: 16,
          },
          itemStyle: {
            color: "#8EE2F1",
          },
          emphasis: {
            disabled: true,
          },
          data: chartData?.[1],
          barWidth: 38,
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };
  const handleQueryDersonCount = async (date) => {
    const data = await vsf?.services?.queryDersonCount({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      // console.log(data, '长期合同工');

      const renderArr = (type, arr, isStraight) => {
        return arr?.map((v) => {
          return isStraight ? v?.[type] : -v?.[type];
        });
      };
      const value = data?.data;
      setChartData(value);
      setCurrentBar(value?.talentList?.[0]?.type);
      const chartX = value?.mngmtOverviewYis
        ?.sort((a, b) => b.year - a.year)
        .map((v) => {
          return `${v?.year}年  ${v?.totalNum}人`;
        });
      const totalNum = value?.ageList
        ?.map((v) => v?.personNum)
        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setageList({
        totalNum,
        list: value?.ageList,
        age: Math?.round(value?.avgAge),
      });
      handlestaffBar("staff-content", chartX, [
        renderArr("longTermWorker", value?.mngmtOverviewYis, false),
        renderArr("shortTermWorker", value?.mngmtOverviewYis, true),
      ]);
    }
  };

  const handleQueryEnumselectcount = async (date) => {
    const data = await vsf?.services?.queryEnumselectcount({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
      type: currentBar,
    });
    if (data?.code === 200) {
      // console.log(data, '八八八');
      const value = data?.data;
      setbottomData(data?.data);
      const color = Object?.keys(echartsColor);
      const renderPieData = (data) => {
        return data?.map((v, i) => {
          return {
            name: v?.type,
            value: v?.personNum,
            itemStyle: {
              color: echartsColor?.[color?.[i]],
            },
            color: echartsColor?.[color?.[i]],
          };
        });
      };
      const renderParData = (data) => {
        return data?.map((v) => v?.personNum);
      };
      const renderParDataX = (data) => {
        return data?.map((v) => v?.type);
      };
      handlepieChart("chart1", renderPieData(value?.educationList));
      handlepieChart("chart2", renderPieData(value?.specialityList));
      handlepieChart("chart3", renderPieData(value?.positionalList));
      handleBarChart(
        "chart4",
        renderParDataX(value?.vocationList),
        renderParData(value?.vocationList)
      );
      handleBarChart(
        "chart5",
        renderParDataX(value?.practiceList),
        renderParData(value?.practiceList)
      );
      handleBarChart(
        "chart6",
        renderParDataX(value?.ageList),
        renderParData(value?.ageList)
      );
    }
  };

  const renderData = (arr, type) => {
    return arr?.find((v) => v?.type == type)?.personNum;
  };

  const renderPortraitBar = (data) => {
    return data?.map((v) => {
      return {
        value: v?.personNum,
        itemStyle: {
          // color: 'rgba(212, 49, 49, 1)',
          opacity: v?.type == currentBar ? 1 : 0.35,
        },
      };
    });
  };
  const renderDataChart = (data, i) => {
    return {
      data1: data?.map((v) => v?.personNum),
      name: dayjs(data?.[0]?.year)?.format("YYYY年"),
      data: renderPortraitBar(data),
      type: "bar",
      smooth: true,
      showSymbol: false,
      // name: '2022',
      // lineStyle: {
      //   color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
      //   width: 2, // 设置折线宽度为 2
      //   // opacity: 0.1,
      // },
      label: {
        show: true,
        color: "#000",
        fontSize: 16,
        position: "top",
        opacity: 1,
      },
      itemStyle: {
        color: PortraitChart?.[i],
        opacity: 0.35,
      },
      emphasis: {
        itemStyle: {
          color: PortraitChart?.[i],
          opacity: 1,
        },
      },
      barWidth: 38,
    };
  };
  console.log(bottomData, ageList, "----bottomData");
  return (
    <div className="organize-brain">
      <ContentHeader
        defaultValue={dayjs(date)}
        onChange={handleDateChange}
        title="组织大脑"
        info="涪陵制药厂人均指标与人才画像"
        picker="year"
      />
      <div className="organize-brain-content">
        <div className="organize-brain-content-top">
          <div className="organize-brain-content-top-title">人均指标</div>
          {/* 隐藏分析指引 */}
          {/* <GuideBtn value="这里展示获取到的「分析指引」相关的一些说明描述，根据需求提供的内容进行展示。这里展示获取到的「分析指引」相关的一些说明描述，根据需求提供的内容进行展示。" /> */}
        </div>
        <div className="organize-brain-content-body">
          {initMngmtGroupAvgIndexYi(mngmtGroupAvgIndexYi).map((item) => {
            return (
              <div className="organize-brain-content-body-item" key={item.key}>
                <div className="organize-brain-content-body-item-title">
                  {item.title}
                </div>
                <div className="organize-brain-content-body-item-ratio">
                  {item.all}
                  <span>
                    {item.key === "laborCostProfitRate" ||
                    item.key === "personProportion"
                      ? "%"
                      : "万元/人"}
                  </span>
                </div>
                <div className="organize-brain-content-body-item-change">
                  较上年
                  {item.expect?.percentage === "-" ? (
                    "-"
                  ) : (
                    <>
                      <img
                        style={{ marginInline: 4 }}
                        src={item.expect?.lastYearEnum ? up : down}
                      ></img>
                      <span>{item.expect?.percentage}%</span>
                    </>
                  )}
                </div>
                <div
                  className="organize-brain-content-body-item-box"
                  style={{ marginTop: 32 }}
                >
                  {/* <div className="organize-brain-content-body-item-num">-</div> */}
                  <div className="organize-brain-content-body-item-text">
                    {item.info1}
                  </div>
                </div>
                <div className="organize-brain-content-body-item-sign">÷</div>
                <div className="organize-brain-content-body-item-box">
                  {/* <div className="organize-brain-content-body-item-num">-</div> */}
                  <div className="organize-brain-content-body-item-text">
                    {item.info2}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="organize-brain-staff">
          <div className="organize-brain-content-top">
            <div className="organize-brain-content-top-title">员工人数</div>
          </div>
          <div className="staff-content" id="staff-content"></div>
        </div>
        <div className="organize-brain-age">
          <div className="organize-brain-content-top">
            <div className="organize-brain-content-top-title">年龄情况</div>
          </div>
          <div className="organize-brain-age-content">
            {ageDataList?.list?.length > 0 && (
              <>
                {" "}
                <div className="age-content-title">
                  平均年龄 <span>{ageDataList?.age}岁</span>
                </div>
                <div className="age-content-legend">
                  {ageList?.map((v) => (
                    <div className="legend-item">
                      <div
                        className="legend-color"
                        style={{ backgroundColor: v?.color }}
                      ></div>
                      <div>{v?.label}</div>
                    </div>
                  ))}
                </div>
                <div className="age-content-chart">
                  {ageList?.map((v) => (
                    <div
                      className="content-chart"
                      style={{
                        width: `${(
                          (renderData(ageDataList?.list, v?.label) /
                            ageDataList?.totalNum) *
                          100
                        )?.toFixed(2)}%`,
                        backgroundColor: v?.color,
                      }}
                    >
                      {renderData(ageDataList?.list, v?.label)}
                    </div>
                  ))}
                </div>
              </>
            )}
            {ageDataList?.list?.length == 0 && (
              <div className="empty1">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
        </div>
        <div className="organize-brain-content-portrait">
          <div className="organize-brain-content-top">
            <div className="organize-brain-content-top-title">人才画像</div>
          </div>
          <div className="content-portrait-content">
            <div className="chart-title">
              人才类型分布 <span>单位：人</span>
            </div>

            {topData?.length != 0 && (
              <div className="portrait-chart" id="portrait-chart"></div>
            )}
            {topData?.length == 0 && (
              <div className="empty1">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
        </div>
        <div className="organize-brain-chartDiv">
          <div className="chart-title">
            {currentBar} <span>单位：人</span>
          </div>
          <div className="chartDiv-top">
            <div className="chartDiv-item">
              <div className="chart-title">学历</div>
              {bottomData?.educationList && (
                <div className="chart" id="chart1"></div>
              )}
              {!bottomData?.educationList && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
            <div className="chartDiv-item">
              <div className="chart-title">专业</div>

              {bottomData?.specialityList && (
                <div className="chart" id="chart2"></div>
              )}
              {!bottomData?.specialityList && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
            <div className="chartDiv-item">
              <div className="chart-title">职称</div>
              {bottomData?.positionalList && (
                <div className="chart" id="chart3"></div>
              )}
              {!bottomData?.positionalList && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
          </div>
          <div className="chartDiv-top">
            <div className="chartDiv-item">
              <div className="chart-title">职业资格</div>
              {bottomData?.vocationList && (
                <div className="chart" id="chart4"></div>
              )}
              {!bottomData?.vocationList && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
            <div className="chartDiv-item">
              <div className="chart-title">执业资格</div>
              {bottomData?.practiceList && (
                <div className="chart" id="chart5"></div>
              )}
              {!bottomData?.practiceList && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
            <div className="chartDiv-item">
              <div className="chart-title">年龄</div>
              {bottomData?.ageList && <div className="chart" id="chart6"></div>}
              {!bottomData?.ageList && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default definePage(OrganizeBrain);
