import { initPager } from "../../utils";
import { CheckOutlined, RiseOutlined } from "@ant-design/icons";
import { DatePicker, Table, TableProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { MointorContext } from "../..";
import { transNumToFixed } from "../../utils";
import "./index.less";
import moment from "moment";
import { getDailyRecords } from "../../services";
import { isNil } from "lodash";

interface IColumns {
  key: React.Key;
  date: string;
  productType: string;
  product: string;
  prodSpec: string;
  specification: string;
  meterialInBatch: number;
  serialNo: number;
  stockIn: number;
  stockInPack: number;
  unitPrice: number;
  dayProdValue: number;
  monthlyPlan: number;
  monthlyPlanFinishRate: number;
}

const getClassNames = (className) => `product-monitor-summary-${className}`;
const { RangePicker } = DatePicker;
const dateFormat = "YYYYMMDD";

const SummaryByDay = () => {
  const { year, specs, date, setDate } = useContext(MointorContext);
  const [pager, setPager] = useState(initPager);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [orderBy, setOrderBy] = useState({ col: "date", od: 1 });
  const [dateMoment, setDateMoment] = useState<moment.Moment[]>([]);

  useEffect(() => {
    setDateMoment([moment(date.dateStart), moment(date.dateEnd)]);
  }, [date]);

  useEffect(() => {
    setPager(initPager);
    queryDaySummary(initPager, orderBy, date);
  }, [specs]);

  useEffect(() => {
    const diff = moment(year).diff(
      moment(date.dateStart).format("YYYY"),
      "year"
    );

    const _date = {
      dateStart: moment(date.dateStart).add(diff, "year").format(dateFormat),
      dateEnd: moment(date.dateEnd).add(diff, "year").format(dateFormat),
    };
    setDate && setDate(_date);
    setPager(initPager);
    queryDaySummary(initPager, orderBy, _date);
  }, [year]);

  const queryDaySummary = (
    page: { current: number; pageSize: number },
    orderBy: { col: string; od: number },
    date: { dateStart: string; dateEnd: string }
  ) => {
    setLoading(true);
    getDailyRecords({
      year,
      specs,
      page,
      ...orderBy,
      ...date,
    })
      .then((res: any) => {
        setTotal(res.data.total);
        setDataSource(res.data.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<IColumns> = [
    {
      title: "生产日期",
      dataIndex: "date",
      key: "date",
      sorter: true,
      fixed: "left",
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (val) => moment(val).format("YYYY/MM/DD"),
      width: 120,
    },
    {
      title: "品规",
      dataIndex: "prodSpec",
      key: "prodSpec",
      width: 180,
      fixed: "left",
    },
    {
      title: "剂型",
      dataIndex: "productType",
      key: "productType",
      width: 100,
    },
    {
      title: "品种",
      dataIndex: "product",
      key: "product",
      width: 160,
    },
    {
      title: "规格",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: "当日入库数（瓶/盒）",
      dataIndex: "stockIn",
      key: "stockIn",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 200,
      align: "right",
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "当日入库数件数（件）",
      dataIndex: "stockInPack",
      key: "stockInPack",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "单价（元/盒）",
      dataIndex: "unitPrice",
      key: "unitPrice",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "当日产值（万元）",
      dataIndex: "dayProdValue",
      key: "dayProdValue",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      width: 200,
      render: (val: number) => transNumToFixed(val) || "-",
    },
    {
      title: "投料批数",
      dataIndex: "meterialInBatch",
      key: "meterialInBatch",
      render: (val: string) => (isNil(val) ? "-" : val),
    },
    {
      title: "流水号",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (val: string) => (isNil(val) ? "-" : val),
    },
    {
      title: "月计划完成率（%）",
      dataIndex: "monthlyPlanFinishRate",
      key: "monthlyPlanFinishRate",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "right",
      fixed: "right",
      width: 240,
      render: (val: number) => {
        return (
          <div className={getClassNames("plan")}>
            <div className={getClassNames("plan-rate")}>
              {val ? `${(val * 100).toFixed(2)}` : "-"}
            </div>
            <div
              className={classNames({
                [getClassNames("plan-rate-bar")]: true,
                [getClassNames("plan-rate-bar-over")]: val > 1,
              })}
            >
              <div
                style={{ width: val > 1 ? 96 : 96 * val }}
                className={classNames({
                  [getClassNames("plan-bar")]: true,
                  [getClassNames("plan-complete")]: val >= 1,
                  [getClassNames("plan-un-complete")]: val < 1,
                })}
              >
                {val === 1 ? <CheckOutlined className="checkIcon" /> : null}
              </div>
              {val > 1 ? <RiseOutlined className="riseIcon" /> : null}
            </div>
          </div>
        );
      },
    },
  ];

  const handleTableChange: TableProps<IColumns>["onChange"] = (
    pagination,
    filter,
    sort,
    { action }
  ) => {
    if (action === "sort") {
      const { columnKey, order } = sort as any;
      const _orderBy: any = {
        col: columnKey,
        od: order === "ascend" ? 0 : order === "descend" ? 1 : null,
      };

      setPager(initPager);
      setOrderBy(_orderBy);
      queryDaySummary(initPager, _orderBy, date);
    }
    if (action === "paginate") {
      const { current, pageSize } = pagination as any;
      setPager({
        current,
        pageSize,
      });
      queryDaySummary({ current, pageSize }, orderBy, date);
    }
  };

  // 当天及以前日期不可选择
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      (current && current > moment().endOf("day")) ||
      (current && current >= moment(year).add(1, "year").startOf("year")) ||
      (current && current < moment(year).subtract(1, "year").endOf("year"))
    );
  };

  const handleChange: RangePickerProps["onChange"] = (dates) => {
    const _date = {
      dateStart: moment(dates?.[0]).format(dateFormat),
      dateEnd: moment(dates?.[1]).format(dateFormat),
    };
    setDate &&
      setDate({
        ..._date,
      });
    setPager(initPager);
    queryDaySummary(pager, orderBy, _date);
  };

  return (
    <div className={getClassNames("by-day")}>
      <div className={getClassNames("header")}>
        <span className={getClassNames("header-title")}>日生产进度</span>
        <span className={getClassNames("header-subTitle")}>
          月计划完成率数据为截止当日数据
        </span>
      </div>
      <div className={getClassNames("content-filter")}>
        <span className={getClassNames("content-filter-title")}>生产日期</span>
        <RangePicker
          disabledDate={disabledDate}
          value={dateMoment as any}
          onChange={handleChange}
          allowClear={false}
          onCalendarChange={(val: any) => {
            setDateMoment(val);
          }}
          onBlur={() => {
            setDateMoment([moment(date.dateStart), moment(date.dateEnd)]);
          }}
        />
      </div>
      <div className={getClassNames("tablewrap")}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
          pagination={{
            total,
            current: pager.current,
            pageSize: pager.pageSize,
          }}
        />
        {total > 0 && <div className="total">共 {total} 项数据</div>}
      </div>
    </div>
  );
};

export default SummaryByDay;
