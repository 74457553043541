import './index.less';

import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Dropdown, Image, Input, Popover,Button } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import avatar from '@/assets/images/avatar.png'
import logo1 from '@/assets/images/logo1.png'
const Header = (props) => {
  const { global } = props?.stores || {};
  const {
    userData,
    userInfo,
    setUserInfo,
    setUserData,
  } = global || {};

  const items = [
    {
      key: '2',
      label: <div onClick={()=> {setUserData({});setUserInfo({})}}>退出</div>,
    },
  ];
  return (
    <div className="header">
      <div className="left">
        <img src={logo1} alt="" />
        <div>涪陵制药厂数据决策体系管理平台</div>
      </div>
      <div className="right">
        {/* <div className="avatar">
          
        </div> */}
        <Image className="avatar" src={avatar} preview={false} />
        <div className="role">
          <Dropdown menu={{ items }}>
            <div className="role dropdown-container">
              {userInfo?.userName || userInfo?.nickName || '用户'}
              <DownOutlined size={18}/>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default definePage(Header);
