import vsf, { definePage } from "@vs/vsf-boot";
import React, { useState, useCallback, useEffect, useRef } from "react";
import ContentHeader from "@/module/ContentHeader";
import GuideBtn from "@/module/GuideBtn";
import * as echarts from "echarts";
import { formatMoney, convertToPercentage, convertToFixed } from "@/tools";
import dayjs from "dayjs";
import "./index.less";
import {
  echartsColor,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
  riskPassRate,
  riskTabs,
  echartsColorList,
  chartBarSelect,
  waterList,
  waterLine,
  electricityList,
  electricitypie,
  avgList,
  avg,
  // echartsColorList,
} from "@/config";
import empty from "@/assets/images/empty.png";
import { DatePicker, Select, Drawer, Row, Col } from "antd";
import down from "@/assets/images/down.png";
import up from "@/assets/images/up.png";
import {
  CaretRightOutlined,
  CloseCircleFilled,
  // CloseCircleFilled,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { handleToFixed, handleChartSize } from "@/utils";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
const chartDataList = [
  {
    color: echartsColor?.echartsColor_0052D9,
    label: "能耗金额",
    value: "energyConsumptionAmountPerWanyuan",
  },
  {
    color: echartsColor?.echartsColor_2FC25B,
    label: "水费",
    value: "waterFeePerWanyuan",
  },
  {
    color: echartsColor?.echartsColor_A350F6,
    label: "电费",
    value: "electricityFeePerWanyuan",
  },
  {
    color: echartsColor?.echartsColor_60C5D8,
    label: "天然气费",
    value: "naturalGasFeePerWanyuan",
  },
];

const OrganizeBrain = (props) => {
  const { global } = props?.stores || {};
  const { startLoading, stopLoading } = global || {};
  const [date, setDate] = useState(
    props.location.state?.date
      ? dayjs(props.location.state?.date)?.format("YYYYMM")
      : dayjs()?.format("YYYYMM")
  );
  const [drawerShow, setDrawerShow] = useState(false);
  const [anchorId, setAnchorId] = useState(
    props.location.state?.anchorId ?? "createCost"
  );
  const [open, setOpen] = useState(false);
  const [bottomDate, setBottomDate] = useState(
    props.location.state?.date || dayjs()?.format("YYYY-MM")
  );
  const [bottomDate2, setBottomDate2] = useState(
    props.location.state?.date || dayjs()?.format("YYYY-MM")
  );
  const [waterData, setWaterData] = useState(null);
  const [waterDataPie, setWaterDataPie] = useState(null);
  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYYMM"));
    setBottomDate(dayjs(value)?.format("YYYY-MM"));
    setBottomDate2(dayjs(value)?.format("YYYY-MM"));
  };
  const [electricityData, setElectricityData] = useState(null);
  const [currentElectricity, setCurrentElectricity] = useState(null);

  useEffect(async () => {
    console.log(date);
    startLoading();
    await handleWaterS();
    if (anchorId == "rawMaterial") {
      await handleElectricity();
    }
    if (anchorId == "rawMaterial1") {
      await handleGasPge();
    }
    stopLoading();
    // charts6
  }, [date, anchorId]);

  useEffect(async () => {
    if (bottomDate && anchorId == "createCost") {
      startLoading();
      await handleWaterBie();
      stopLoading();
    }
  }, [bottomDate, anchorId]);
  useEffect(async () => {
    if (bottomDate2 && anchorId == "rawMaterial") {
      startLoading();
      await handleElectricityBie();
      stopLoading();
    }
  }, [bottomDate2, anchorId]);
  useEffect(async () => {
    if (currentElectricity && anchorId == "rawMaterial") {
      console.log("我是选中得");
      // handleLineChart("charts6", echartsXData, [
      //   renderChartsData([1, 2, 3, 4, 4], "测试1"),
      //   renderChartsData([1, 2, 3, 8, 9], "测试2"),
      //   renderChartsData([1, 2, 3, 6, 5], "测试3"),
      // ]);
    }
  }, [bottomDate2, anchorId, currentElectricity]);
  useEffect(() => {
    if (waterData) {
      const chartX = waterData?.energyDismantlingLineEoLine?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chartData = chartDataList?.map((v) => {
        return {
          data: waterData?.energyDismantlingLineEoLine?.map((item) =>
            handleToFixed(item?.[v?.value], 2)
          ),
          type: "line",
          smooth: true,
          name: v?.label,
          showSymbol: false,
          lineStyle: {
            color: v?.color, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        };
      });
      handleChart("energ-an-rate-chart", chartX, chartData, 5);
      handleChart("energ-an-Drawer-chart", chartX, chartData, 0, true);
    }
  }, [waterData, drawerShow]);
  useEffect(() => {
    // 监听窗口大小改变事件
    window.addEventListener("resize", () => {
      handleChartSize("energ-an-rate-chart");
    });
    return () => {
      window.removeEventListener("resize", () => {
        handleChartSize("energ-an-rate-chart");
      });
    };
  }, []);

  const handleChart = (id, chartX, chartData, interval, isLegend = false) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      grid: {
        left: 0,
        containLabel: true,
        top: 40,
        right: 20,
        bottom: 27,
      },
      legend: {
        type: "plain",
        show: isLegend,
        // left: 0,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
        left: 0,
        top: 0,
      },
      xAxis: {
        type: "category",
        // type: 'value',
        data: chartX,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: isLegend,
        },

        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          interval: interval,
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
      },
      tooltip: {
        trigger: "axis",
        show: isLegend,
        valueFormatter: (value) => {
          return value;
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        show: isLegend,
      },
      series: chartData,
    };

    dom && option && myChart.setOption(option);
  };

  const handleLineChart = (
    id,
    chartX,
    chartData,
    isLegend = true,
    top = 80,
    rate = 0,
    isVisualMap = false
  ) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const VisualMap = {};
    isVisualMap &&
      (VisualMap.visualMap = renderVisualMap(chartData, "chartData"));
    const option = {
      color: echartsColorList,
      grid: {
        left: 0,
        containLabel: true,
        top: top,
        right: 20,
        bottom: 27,
      },
      legend: {
        type: "plain",
        show: isLegend,
        // left: 0,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
        left: 0,
        top: 24,
      },
      xAxis: {
        type: "category",
        // type: 'value',
        data: chartX,
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        // axisLine: {
        //   show: isLegend,
        // },

        axisLabel: {
          formatter: (value) => {
            console.log(value, "----");
            return dayjs(value)?.format("MM月");
          },
          interval: 0,
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: rate,
        },
      },
      tooltip: {
        trigger: "axis",
        // show: isLegend,
        valueFormatter: (value) => {
          return value;
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        scale: true,
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: isLegend,
      },
      ...VisualMap,
      series: chartData,
    };

    dom && option && myChart.setOption(option);
  };

  const handlePieChart = (id, chartData, isClick = false) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      grid: {
        left: 0,
        containLabel: true,
        top: 20,
        right: 0,
        bottom: 20,
      },
      color: echartsColorList,
      tooltip: {
        trigger: "item",
        valueFormatter: (value) => {
          return value;
        },
      },
      series: [
        {
          name: "总制造费用构成",
          type: "pie",
          radius: [40, "80%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 0,
          },
          data: chartData,
          label: {
            // width: 80,
            overflow: "break",
            // formatter: (value) => {
            //   console.log(value, '----');
            //   return '{b}: {d}%';
            // },
            formatter: "{b}\n\n{d}%",
          },
          // avoidLabelOverlap: false,
        },
      ],
    };
    if (dom && isClick) {
      myChart?.on("click", function (params) {
        console.log("-----");
        if (params.componentType === "series") {
          console.log(params, "params");
          if (params.seriesType === "pie") {
            console.log("鼠标点击了", params?.name, params?.data);
            setCurrentElectricity(params?.data);
          }
        }
      });
    }

    dom && option && myChart.setOption(option);
  };

  const handleChart1 = (id, chartX = [], dataChart = [], grid = "10%") => {
    const y1 =
      dataChart[0]?.sort((a, b) => a - b)?.[dataChart[0]?.length - 1]?.length -
      2;
    const y2 =
      dataChart[1]?.sort((a, b) => a - b)?.[dataChart[1]?.length - 1]?.length -
      2;
    // console.log(y1, y2);
    const num = 8;
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 24,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        // selectedMode: true,
        selectedMode: false,
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 10,
        containLabel: true,
        top: 80,
        right: 10,
        bottom: 0,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: [
        {
          name: "单位：吨",
          position: "left",
          type: "value",
          axisTick: {
            lineStyle: {
              color: echartsXYColor,
            },
          },
          nameTextStyle: {
            // align: "left",
            padding: [0, 0, 0, -y2 * num],
          },
          axisLabel: {
            formatter: "{value}",
            color: echartsXYColor,
            fontSize: echartsXYSize,
          },
          // show: false,
        },
        {
          name: "单位：%",
          type: "value",
          axisTick: {
            // show: false,
            lineStyle: {
              color: echartsXYColor,
            },
          },
          nameTextStyle: {
            // align: "left",
            padding: [0, -y1 * num, 0, 0],
          },
          position: "right",
          axisLabel: {
            formatter: "{value}",
            color: echartsXYColor,
            fontSize: echartsXYSize,
          },
          // show: false,
        },
      ],
      series: [
        {
          data: dataChart?.[1],
          type: "line",
          //   yAxisIndex: 1,
          yAxisIndex: 0,
          smooth: true,
          showSymbol: false,
          name: "平均每批天然气量",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          lineStyle: {
            //   color: echartsColor?.echartsColor_EF7E60,
            width: 2,
          },
        },
        {
          data: dataChart?.[0],
          type: "line",
          yAxisIndex: 1,
          smooth: true,
          showSymbol: false,
          name: "气汽比",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          lineStyle: {
            //   color: echartsColor?.echartsColor_EF7E60,
            width: 2,
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const handleChange = (id) => () => {
    setAnchorId(id);
  };

  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  const handleBDateChange = (value) => {
    setBottomDate(dayjs(value)?.format("YYYY-MM"));
  };

  const handleBDateChange2 = (value) => {
    setBottomDate2(dayjs(value)?.format("YYYY-MM"));
  };

  const handleWaterS = async () => {
    const data = await vsf?.services?.energyAnalyze({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      const value = data?.data;
      setWaterData({ ...value });
      // 车间水量
      const chart1List = waterList?.map((v) => {
        return {
          name: v?.label,
          data: value?.[v?.value]?.map((item) =>
            handleToFixed(item?.proportion, 2)
          ),

          dataValue: value?.[v?.value],
          key: v?.value,
        };
      });
      const charts1X = chart1List?.[0]?.dataValue?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      console.log(charts1X, chart1List?.[0]?.dataValue, "charts1X");
      handleLineChart(
        "first-energ-left-charts",
        charts1X,
        chart1List?.map((v) => renderChartsData(v?.data, v?.name)),
        true,
        80,
        45,
        true
      );

      // 差异率

      const chart2X = value?.differenceRateLine?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chart2Data = value?.differenceRateLine?.map((v) =>
        handleToFixed(v?.proportion * 100, 2)
      );
      handleLineChart(
        "first-energ-rigth-charts",
        chart2X,
        [renderChartsData(chart2Data, "差异率")],
        false,
        40,
        45
      );

      // 消耗水量

      const chart3List = waterLine?.map((v) => {
        return {
          name: v?.label,
          data: value?.[v?.value]?.map((item) =>
            handleToFixed(item?.proportion, 2)
          ),
          dataValue: value?.[v?.value],
          key: v?.value,
        };
      });
      const charts3X = chart3List?.[0]?.dataValue?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      handleLineChart(
        "first-energ-botton-rigth-charts",
        charts3X,
        chart3List?.map((v) => renderChartsData(v?.data, v?.name)),
        true,
        80,
        45
      );

      // console.log(data, "-----");
    }
  };

  const handleWaterBie = async () => {
    const data = await vsf?.services?.energyAnalyzePie({
      yearAndMonth: dayjs(bottomDate)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      const value = data?.data;
      const chart1List = waterLine?.map((v) => {
        console.log(v?.[v?.key]);
        return {
          name: v?.label,
          value: handleToFixed(value?.[v?.key] * 100, 2),
        };
      });
      setWaterDataPie(
        chart1List?.map((v) => v?.value)?.reduce((a, b) => a + b, 0)
      );
      handlePieChart("first-energ-botton-left-charts", chart1List);
    }
  };

  const handleElectricity = async () => {
    const data = await vsf?.services?.electricityPage({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      const value = data?.data;
      setElectricityData(value);

      handleLineChart(
        "charts1",
        renderData(value?.electricityFeeLine)?.chartx,
        [
          renderChartsData(
            renderData(value?.electricityFeeLine)?.data,
            "电费",
            true
          ),
        ],
        false,
        40
      );
      handleLineChart(
        "charts2",
        renderData(value?.electricityQuantityLine)?.chartx,
        [
          renderChartsData(
            renderData(value?.electricityQuantityLine)?.data,
            "电量",
            true
          ),
        ],
        false,
        40
      );
      handleLineChart(
        "charts3",
        renderData(value?.electricityAvgPriceLine)?.chartx,
        [
          renderChartsData(
            renderData(value?.electricityAvgPriceLine)?.data,
            "电价",
            true
          ),
        ],
        false,
        40
      );

      // 车间电量
      const chart1List = electricityList?.map((v) => {
        return {
          name: v?.label,
          data: value?.[v?.value]?.map((item) =>
            handleToFixed(item?.proportion, 2)
          ),
          dataValue: value?.[v?.value],
          key: v?.value,
        };
      });

      const charts1X = chart1List?.[0]?.dataValue?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      console.log(charts1X, chart1List?.[0]?.dataValue, "charts1X");
      handleLineChart(
        "charts4",
        charts1X,
        chart1List?.map((v) => renderChartsData(v?.data, v?.name)),
        true,
        80,
        0,
        true
      );
    }
  };

  const handleElectricityBie = async () => {
    const data = await vsf?.services?.electricityPagePie({
      yearAndMonth: dayjs(bottomDate2)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      const value = data?.data;
      const chart1List = electricitypie?.map((v) => {
        console.log(v?.[v?.value]);
        return {
          name: v?.label,
          value: handleToFixed(value?.[v?.value] * 100, 2),
          key: v?.value,
        };
      });
      // setWaterDataPie(
      //   chart1List?.map((v) => v?.value)?.reduce((a, b) => a + b, 0)
      // );
      const sortArr = chart1List?.sort((a, b) => a?.value - b?.value);
      setCurrentElectricity(sortArr?.[sortArr?.length - 1]);
      handlePieChart("charts5", chart1List, true);
    }
  };

  const handleGasPge = async () => {
    const data = await vsf?.services?.gasPage({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      console.log(data, "222");
      const value = data?.data;
      const charts1x = value?.avgPerNatureGasLine?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const renderData = (data, is) => {
        return data?.map((v) => handleToFixed(v?.proportion, 2));
      };
      handleChart1("charts7", charts1x, [
        renderData(value?.gasToVaporRatioLine),
        renderData(value?.avgPerNatureGasLine),
      ]);
      const chart1List = avgList?.map((v) => {
        return {
          name: v?.label,
          data: value?.[v?.value]?.map((item) =>
            handleToFixed(item?.proportion, 2)
          ),
          dataValue: value?.[v?.value],
          key: v?.value,
        };
      });
      const charts1X = chart1List?.[0]?.dataValue?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      console.log(charts1X, chart1List?.[0]?.dataValue, "charts1X");
      handleLineChart(
        "charts8",
        charts1X,
        chart1List?.map((v) => renderChartsData(v?.data, v?.name)),
        true,
        80,
        45,
        true
      );

      const chart3List = avg?.map((v) => {
        return {
          name: v?.label,
          data: value?.[v?.value]?.map((item) =>
            handleToFixed(item?.proportion, 2)
          ),
          dataValue: value?.[v?.value],
          key: v?.value,
        };
      });
      const charts3X = chart3List?.[0]?.dataValue?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      handleLineChart(
        "charts9",
        charts3X,
        chart3List?.map((v) => renderChartsData(v?.data, v?.name)),
        true,
        80,
        45
      );
    }
  };

  const handleIsNumber = (value) => {
    return typeof value === "number" || typeof value === "string";
  };
  const renderData = (data) => {
    return {
      chartx: data?.map((v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")),
      data: data?.map((v) => handleToFixed(v?.proportion, 2)),
    };
  };
  const renderChartsData = (data, name, isAreaStyle = false) => {
    const areaStyleObj = {};
    isAreaStyle &&
      (areaStyleObj.areaStyle = {
        color: "#EFF3FF",
        emphasis: { color: "#EFF3FF" },
      });
    return {
      data: data,
      type: "line",
      smooth: true,
      name,
      showSymbol: false,
      lineStyle: {
        width: 2, // 设置折线宽度为 2
        // ... 可以设置更多线条样式属性
      },
      ...areaStyleObj,
    };
  };
  const renderVisualMap = (data) => {
    const maxArr = data?.[1]?.data;
    // []?.flatMap
    const minArr = data
      ?.map((v) => v?.data)
      ?.flat()
      ?.sort((a, b) => a - b)?.[0];
    // console.log(maxArr, minArr, "minArr11111111111");
    const max = maxArr?.sort((a, b) => a - b)?.[maxArr?.length - 1];
    const min = minArr?.[0];
    console.log(max, min, minArr, maxArr);
    return [
      {
        show: false,
        seriesIndex: 0, //指定取哪个系列的数据，即哪个系列的 series.data。默认取所有系列。
        pieces: [
          {
            min: Number(min),
            max: Number(max),
            // color: echartsColorList?.[0],
          },
        ],
        outOfRange: {
          color: "#F4885A",
          borderColor: "#F4885A",
        },
        inRange: {
          color: echartsColorList?.[0],
          borderColor: echartsColorList?.[0],
        },
      },
    ];
  };
  const renderFirst = () => {
    return (
      <div className="first-energ">
        <div className="second-energ-first-header">水量分析</div>
        <div className="first-energ-first">
          <div className="first-energ-left">
            <div className="header">
              车间水量 <span>单位：吨</span>
            </div>
            <div
              className="first-energ-left-charts"
              id="first-energ-left-charts"
            ></div>
          </div>
          <div className="first-energ-left">
            {" "}
            <div className="header">
              差异率 <span>单位：%</span>
            </div>
            {waterData?.differenceRateLine?.length > 0 && (
              <div
                className="first-energ-left-charts"
                id="first-energ-rigth-charts"
              ></div>
            )}
            {waterData?.differenceRateLine?.length == 0 ||
              (!waterData?.differenceRateLine && (
                <div className="empty1">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              ))}
          </div>
        </div>
        <div className="first-energ-second">
          {" "}
          <div className="first-energ-left">
            {/* <div className="header">
              车间水量 <span>单位：吨</span>
            </div> */}
            <div className="bottom-left-header">
              <div className="picker">
                <DatePicker
                  onChange={handleBDateChange}
                  disabledDate={disabledDate}
                  locale={locale}
                  picker="month"
                  allowClear={false}
                  format={"YYYY年-MM月"}
                  // defaultValue={moment(bottomDate)}
                  value={moment(bottomDate)}
                  onOpenChange={setOpen}
                  suffixIcon={
                    open ? (
                      <CaretUpOutlined size={16} />
                    ) : (
                      <CaretDownOutlined
                        size={16}
                        style={{ color: "#333333" }}
                      />
                    )
                  }
                  // suffixIcon={open ? <CaretUpOutlined /> : <CaretDownOutlined style={{ color: '#333333' }} />}
                />
              </div>
              <div className="header-title">水量构成</div>
            </div>

            {waterDataPie && (
              <div
                className="first-energ-left-charts"
                id="first-energ-botton-left-charts"
              ></div>
            )}
            {!waterDataPie && (
              <div className="empty1">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
          <div className="first-energ-left">
            {" "}
            <div className="header">
              平均每批消耗水量 <span>单位：吨</span>
            </div>
            <div
              className="first-energ-left-charts"
              id="first-energ-botton-rigth-charts"
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const renderSecond = () => {
    return (
      <div className="second-energ">
        <div className="second-energ-first">
          <div className="second-energ-first-header">电费影响因素分析</div>
          <div className="second-energ-first-content">
            <div className="energ-first-content-left">
              <div className="header">电费</div>
              <div className="left-content">
                <div className="num">
                  {handleToFixed(electricityData?.electricityFee, 2)}{" "}
                  <span>元</span>
                </div>
                {/* {electricityData?.electricityFeeCompare && ( */}
                <div className="left-content-rate">
                  <div>月度</div>
                  <div className="text">同比增长</div>
                  {handleIsNumber(electricityData?.electricityFeeCompare) ? (
                    <>
                      {" "}
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          electricityData?.electricityFeeCompare > 0 ? up : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          electricityData?.electricityFeeCompare * 100,
                          2
                        )}
                        %
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="energ-first-content-rigth">
              <div className="rigth-header">
                电费月趋势 <span>单位：元</span>
              </div>

              {electricityData?.electricityFeeLine && (
                <div className="rigth-chart" id="charts1"></div>
              )}
              {!electricityData?.electricityFeeLine && (
                <div className="empty1">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
              {/* <div className="empty1">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div> */}
            </div>
          </div>
          <div className="second-energ-first-content">
            <div className="energ-first-content-left">
              <div className="header">电量</div>
              <div className="left-content">
                <div className="num">
                  {handleToFixed(electricityData?.electricityQuantity, 2)}{" "}
                  <span>度</span>
                </div>
                {/* {electricityData?.electricityQuantityCompare && ( */}
                <div className="left-content-rate">
                  <div>月度</div>
                  <div className="text">同比增长</div>
                  {handleIsNumber(
                    electricityData?.electricityQuantityCompare
                  ) ? (
                    <>
                      {" "}
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          electricityData?.electricityQuantityCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          electricityData?.electricityQuantityCompare * 100,
                          2
                        )}
                        %
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="energ-first-content-rigth">
              <div className="rigth-header">
                电量月趋势 <span>单位：度</span>
              </div>
              {/* <div className="rigth-chart" id="charts2"></div> */}
              {electricityData?.electricityQuantityLine && (
                <div className="rigth-chart" id="charts2"></div>
              )}
              {!electricityData?.electricityQuantityLine && (
                <div className="empty1">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
              {/* <div className="empty1">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div> */}
            </div>
          </div>
          <div className="second-energ-first-content">
            <div className="energ-first-content-left">
              <div className="header">电价</div>
              <div className="left-content">
                <div className="num">
                  {handleToFixed(electricityData?.electricityAvgPrice, 2)}{" "}
                  <span>元/度</span>
                </div>
                <div className="left-content-rate">
                  <div>月度</div>
                  <div className="text">同比增长</div>
                  {handleIsNumber(
                    electricityData?.electricityAvgPriceCompare
                  ) ? (
                    <>
                      {" "}
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          electricityData?.electricityAvgPriceCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          electricityData?.electricityAvgPriceCompare * 100,
                          2
                        )}
                        %
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <div className="energ-first-content-rigth">
              <div className="rigth-header">
                电价月趋势 <span>单位：元/度</span>
              </div>

              {electricityData?.electricityAvgPriceLine && (
                <div className="rigth-chart" id="charts3"></div>
              )}
              {!electricityData?.electricityAvgPriceLine && (
                <div className="empty1">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
              {/* <div className="empty1">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="second-energ-second">
          <div className="second-header">电量趋势分析</div>
          <div className="second-firsr">
            <div className="second-firsr-header">
              车间电量 <span>单位：度</span>
            </div>
            <div className="second-firsr-charts" id="charts4"></div>
            {/* <div className="empty1">
              <img src={empty}></img>
              <div>暂无数据</div>
            </div> */}
          </div>
          <div className="second-second">
            <div className="second-second-left">
              <div className="first-energ-left">
                <div className="bottom-left-header">
                  <div className="picker">
                    <DatePicker
                      onChange={handleBDateChange2}
                      disabledDate={disabledDate}
                      locale={locale}
                      picker="month"
                      allowClear={false}
                      format={"YYYY年-MM月"}
                      // defaultValue={moment(bottomDate2)}
                      value={moment(bottomDate2)}
                      onOpenChange={setOpen}
                      suffixIcon={
                        open ? (
                          <CaretUpOutlined size={16} />
                        ) : (
                          <CaretDownOutlined
                            size={16}
                            style={{ color: "#333333" }}
                          />
                        )
                      }
                      // suffixIcon={open ? <CaretUpOutlined /> : <CaretDownOutlined style={{ color: '#333333' }} />}
                    />
                  </div>
                  <div className="header-title">电量构成分析</div>
                </div>
                <div className="second-left-charts" id="charts5"></div>
              </div>
            </div>
            <div className="second-second-left">
              <div className="first-energ-left">
                {" "}
                <div className="header">
                  {currentElectricity?.name}用电量分析 <span>单位：度</span>
                </div>
                {/* <div className="second-left-charts" id="charts6"></div> */}
                <div className="empty1">
                  <img src={empty}></img>
                  <div>数据待建设</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderThird = () => {
    return (
      <div className="third-energ">
        <div className="second-header">天然气量分析</div>
        <div className="second-firsr">
          <div className="second-firsr-header">平均每批天然气量vs气汽比</div>
          <div className="second-firsr-charts" id="charts7"></div>
          {/* <div className="empty1">
              <img src={empty}></img>
              <div>暂无数据</div>
            </div> */}
        </div>
        <div className="second-second">
          <div className="second-second-left">
            <div className="first-energ-left">
              {" "}
              <div className="header">
                蒸汽量 <span>蒸汽量=天然量/气汽比，单位：吨</span>
              </div>
              <div className="second-left-charts" id="charts8"></div>
            </div>
          </div>
          <div className="second-second-left">
            <div className="first-energ-left">
              {" "}
              <div className="header">
                平均每批蒸汽用量趋势 <span>单位：吨</span>
              </div>
              <div className="second-left-charts" id="charts9"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  console.log(anchorId, "anchorId");
  return (
    <div className="energ-an">
      <ContentHeader
        defaultValue={dayjs(date)}
        onChange={handleDateChange}
        title="李渡车间能源分析"
        info="涪陵制药厂能源指标分析看板，当前以李渡口服液制剂车间为例。"
        secondBreadcrumb="能源管理"
        handleNavgateSecondTab={() => vsf.navigateTo("/dashboard/enery/index")}
      />
      <div className="energ-an-content">
        <div className="energ-an-content-top">
          <div className="energ-an-content-top-title">能耗拆解</div>
          {/* 隐藏分析指引 */}
          {/* <GuideBtn value="这里展示获取到的「分析指引」相关的一些说明描述，根据需求提供的内容进行展示。这里展示获取到的「分析指引」相关的一些说明描述，根据需求提供的内容进行展示。" /> */}
        </div>
        <div className="energ-an-content-body">
          <Row gutter={[16, 20]}>
            <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={13}>
              <div className="energ-an-content-body-l">
                <div className="energ-an-content-body-title">
                  李渡车间能耗分析
                  <span>点击数字卡片可查看详细分析，单位：万元</span>
                </div>
                <div className="energ-an-content-body-energ-an">
                  <div>
                    <div className="energ-an-content-body-energ-an-num1">
                      {convertToFixed(
                        waterData?.energyConsumptionAmountPerWanyuan
                      )}
                    </div>
                    <div className="energ-an-content-body-energ-an-text">
                      能耗金额
                    </div>
                  </div>
                  <div className="energ-an-content-body-energ-an-operator">
                    =
                  </div>
                  <div
                    onClick={handleChange("createCost")}
                    className={
                      anchorId === "createCost"
                        ? "active active-card"
                        : "active-card"
                    }
                  >
                    <div className="energ-an-content-body-energ-an-num2">
                      {convertToFixed(waterData?.waterFeePerWanyuan)}
                    </div>
                    <div className="energ-an-content-body-energ-an-text">
                      水费
                    </div>
                  </div>
                  <div className="energ-an-content-body-energ-an-operator">
                    +
                  </div>
                  <div
                    onClick={handleChange("rawMaterial")}
                    className={
                      anchorId === "rawMaterial"
                        ? "active active-card"
                        : "active-card"
                    }
                  >
                    <div className="energ-an-content-body-energ-an-num2">
                      {convertToFixed(waterData?.electricityFeePerWanyuan)}
                    </div>
                    <div className="energ-an-content-body-energ-an-text">
                      电费
                    </div>
                  </div>
                  <div className="energ-an-content-body-energ-an-operator">
                    +
                  </div>
                  <div
                    onClick={handleChange("rawMaterial1")}
                    className={
                      anchorId === "rawMaterial1"
                        ? "active active-card"
                        : "active-card"
                    }
                  >
                    <div className="energ-an-content-body-energ-an-num2">
                      {convertToFixed(waterData?.naturalGasFeePerWanyuan)}
                    </div>
                    <div className="energ-an-content-body-energ-an-text">
                      天然气费
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={11}>
              <div className="energ-an-content-body-r">
                <div className="energ-an-content-body-header">
                  <div className="energ-an-content-body-title">能耗金额</div>
                  {waterData?.energyDismantlingLineEoLine?.length > 0 && (
                    <div
                      className="energ-an-content-body-btn"
                      onClick={() => setDrawerShow(true)}
                    >
                      放大查看
                    </div>
                  )}
                </div>
                {waterData?.energyDismantlingLineEoLine?.length ? (
                  <div
                    onClick={() => setDrawerShow(true)}
                    className="energ-an-rate-chart"
                    id="energ-an-rate-chart"
                  ></div>
                ) : (
                  <div className="empty1">
                    <img src={empty}></img>
                    <div>暂无数据</div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="energ-an-line"></div>
        {anchorId == "createCost" && renderFirst()}
        {anchorId == "rawMaterial" && renderSecond()}
        {anchorId == "rawMaterial1" && renderThird()}
        {/* <div className='energ-an'></div> */}
      </div>

      <Drawer
        placement="right"
        onClose={() => setDrawerShow(false)}
        size="large"
        open={drawerShow}
        className="energ-an-drawer"
      >
        <div className="energ-an-drawer-content">
          <div className="energ-an-drawer-header">
            <div className="title">
              能耗金额
              <span className="unit">单位：元</span>
            </div>
            <CloseCircleFilled
              onClick={() => setDrawerShow(false)}
              style={{ color: "#BEBEBE" }}
            ></CloseCircleFilled>
          </div>
          <div
            className="energ-an-Drawer-chart"
            id="energ-an-Drawer-chart"
          ></div>
        </div>
      </Drawer>
    </div>
  );
};
export default definePage(OrganizeBrain);
