import systemLayout from "@/layouts/systemLayout";
import Cost from "@/pages/Cost";
import Dashboard from "@/pages/Dashboard";
import FinancialIndicators from "@/pages/FinancialIndicators";
import LiduWorkshop from "@/pages/LiduWorkshop";
import OrganizeBrain from "@/pages/OrganizeBrain";
import ProductionManagement from "@/pages/ProductionManagement";
import Risk from "@/pages/Risk";
import User from "@/pages/User";
import ProductMonitor from "@/pages/ProductMonitor";
import EnergyAnalysis from "@/pages/EnergyAnalysis";
import EnergyDashboard from "@/pages/EnergyDashboard";
import FactoryAreaEnergy from "@/pages/FactoryAreaEnergy";
import FactoryArea from "@/pages/FactoryArea";
import DataUpload from "@/pages/DataUpload";
// const routes = [
//   {
//     path: "/financial_indicators",
//     exact: true,
//     label: "核心财务指标",
//     icon: "icon-a-Group20192",
//     component: FinancialIndicators,
//   },
//   {
//     path: "/dashboard",
//     label: "管理驾驶舱",
//     icon: "icon-a-Group20186",
//     component: systemLayout,
//     redirect: "/dashboard/overview",
//     routes: [
//       {
//         path: "/dashboard/overview",
//         exact: true,
//         label: "概览",
//         icon: "icon-server",
//         component: Dashboard,
//       },
//       {
//         path: "/dashboard/production",
//         label: "生产管理",
//         icon: "icon-server-1",
//         component: systemLayout,
//         redirect: "/dashboard/production/overview",
//         routes: [
//           {
//             path: "/dashboard/production/overview",
//             exact: true,
//             label: "概览",
//             // icon: 'icon-xitong',
//             component: ProductionManagement,
//           },
//           {
//             path: "/dashboard/production/monitor",
//             exact: true,
//             label: "产能监控",
//             component: ProductMonitor,
//           },
//           {
//             path: "/dashboard/production/lidu_workshop",
//             exact: true,
//             label: "李渡车间",
//             // icon: 'icon-xitong',
//             component: LiduWorkshop,
//           },
//         ],
//       },
//       {
//         path: "/dashboard/risk",
//         exact: true,
//         label: "风险管理",
//         icon: "icon-a-Group20189",
//         component: Risk,
//       },
//       {
//         path: "/dashboard/cost",
//         exact: true,
//         label: "成本管理",
//         icon: "icon-a-Group20188",
//         component: Cost,
//       },
//       {
//         path: "/dashboard/organize_brain",
//         exact: true,
//         label: "组织大脑",
//         icon: "icon-a-Group20193",
//         component: OrganizeBrain,
//       },
//     ],
//   },
//   {
//     path: "/enery",
//     label: "能源环保",
//     icon: "icon-a-Group20191",
//     component: systemLayout,
//     redirect: "/enery/index",
//     routes: [
//       {
//         path: "/enery/index",
//         exact: true,
//         label: "概览",
//         icon: "icon-server",
//         component: EnergyDashboard,
//       },
//       {
//         path: "/enery/factory_area",
//         exact: true,
//         label: "厂区能源",
//         icon: "icon-a-Group20796",
//         component: FactoryArea,
//       },
//       {
//         path: "/enery/analysis",
//         exact: true,
//         label: "能源分析",
//         icon: "icon-a-Group20794",
//         component: EnergyAnalysis,
//       },
//     ],
//   },
//   {
//     path: "/data_upload",
//     exact: true,
//     label: "数据上传",
//     icon: "icon-a-Group20190",
//     component: DataUpload,
//   },
//   {
//     path: "/user",
//     exact: true,
//     label: "账号管理",
//     icon: "icon-a-Group20562",
//     component: User,
//   },
// ];

const routes = [
  {
    path: "/financial_indicators",
    exact: true,
    label: "核心财务指标",
    icon: "icon-a-Group20192",
    component: FinancialIndicators,
  },
  {
    path: "/dashboard",
    label: "管理驾驶舱",
    icon: "icon-a-Group20186",
    component: systemLayout,
    redirect: "/dashboard/overview",
    routes: [
      {
        path: "/dashboard/production/monitor",
        exact: true,
        icon: "icon-a-Group20794",
        label: "产能监控",
        component: ProductMonitor,
      },
      {
        path: "/dashboard/overview",
        exact: true,
        label: "生产管理",
        icon: "icon-server-1",
        component:Dashboard,
      },
      {
        path: "/dashboard/production/overview",
        exact: true,
        label: "生产分析",
        icon: "icon-server-1",
        component: ProductionManagement,
        isHide: true,
      },
      {
        path: "/dashboard/risk",
        exact: true,
        label: "风险管理",
        icon: "icon-a-Group20189",
        component: Risk,
      },
      {
        path: "/dashboard/cost",
        exact: true,
        label: "成本管理",
        icon: "icon-a-Group20188",
        component: Cost,
      },
      {
        path: "/dashboard/organize_brain",
        exact: true,
        label: "组织大脑",
        icon: "icon-a-Group20193",
        component: OrganizeBrain,
      },
      {
        path: "/dashboard/production/lidu_workshop",
        exact: true,
        label: "李渡车间",
        icon: 'icon-xitong',
        component: LiduWorkshop,
        isHide:true,
      },
      {
        path: "/dashboard/enery",
        label: "能源管理",
        icon: "icon-a-Group20191",
        component: systemLayout,
        redirect: "/dashboard/enery/index",
        routes: [
          {
            path: "/dashboard/enery/index",
            exact: true,
            label: "概览",
            component: EnergyDashboard,
          },
          {
            path: "/dashboard/enery/factory_area",
            exact: true,
            label: "厂区能源",
            component: FactoryArea,
          },
          {
            path: "/dashboard/enery/analysis",
            exact: true,
            label: "能源分析",
            component: EnergyAnalysis,
          },
        ],
      },
    ],
  },
  {
    path: "/data_upload",
    exact: true,
    label: "数据上传",
    icon: "icon-a-Group20190",
    component: DataUpload,
  },
  {
    path: "/user",
    exact: true,
    label: "账号管理",
    icon: "icon-a-Group20562",
    component: User,
  }, 
];

export default routes;
