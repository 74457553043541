/**
 * 鼠标移入即时显示气泡框，如下，包含4个字段：
 * 1.指标名称  
 * 2. 指标描述   
 * 3. 异常说明（如果是异常则显示，正常则不用显示） 
 * 4.【点击卡片可查看下钻分析】该句固定显示；鼠标移入是变「手」形态；
 * @param {
 * title: string
 * info: string
 * warn: string
 * }
 */

import vsf, { definePage } from '@vs/vsf-boot';
import { Popover } from "antd"
import React, { useState } from 'react';
import {
    InfoCircleFilled
} from '@ant-design/icons';
import './index.less'
const MyPopover = ({ title, info, warn, children }) => {
    const content = (
        <div className='my-popover'>
            <div className='title'><InfoCircleFilled style={{ color: '#0052D9', fontSize: 18 }} />{title}</div>
            <div className='content'>
                <div className='info'>{info}</div>
                {warn && <div className='warn'>{warn}</div>}
                <div className='text'>点击卡片可查看下钻分析</div>
            </div>
        </div>
    )
    return (
        <Popover content={content}>
            {children}
        </Popover>
    );
};

export default MyPopover;
