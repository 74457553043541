import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Checkbox, Form, Input, Tabs, Modal } from '@vs/vsf-kit';
import React, { useState, useEffect } from 'react';
import services from './services';
import {
  CloseCircleFilled
} from '@ant-design/icons';
import yinzhang from '@/assets/images/yinzhang.png'
import logo2 from '@/assets/images/logo2.png'
import loginImg from '@/assets/images/login-img.png'
import './index.less';

const { TabPane } = Tabs;

const Login = (props) => {
  const { global } = props?.stores || {};
  const {
    setUserInfo,
    setUserData,
  } = global || {};
  const [form] = Form.useForm();
  const [isLogin, setIsLogin] = useState(true);
  const [values, setValues] = useState({});

  const onLogin = async values => {
    console.log('Received values of form: ', values);
    // form.setFields([
    //   {
    //     name: 'passWord',
    //     errors: ['用户名或密码错误'],
    //   },
    // ]);

    const res = await vsf.services?.FlUserController_login_ebffd9?.(values)
    if (res.code === 200) {
      vsf.showToast('登录成功', 'success')
      setUserInfo(res.data);
      setUserData(res.data);
      vsf.navigateTo(props.routes?.qurey?.from || '/dashboard/overview');
    } else {
      Modal.error({
        title: '登录错误',
        icon: <CloseCircleFilled style={{ color: '#D54941' }} />,
        content: res.message || '用户名或密码错误请重新输入。如果忘记密码，请联系管理员重置。',
        okText: '知道了',
        // cancelText: '取消',
        centered: true,
        onOk: () => { },
        // onCancel() { },
        closable: true,
        className: 'my-modal-confirm',
        width: 480,
      });
    }
  };

  return (
    <div className="login-container">
      <div className='login-img' />
      <div className='login-content'>
        <div className='logo-content-title'>涪陵制药厂数据决策体系管理平台</div>
        <div className="login-block">
          <div className="title">欢迎登录</div>
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onLogin}
            onValuesChange={(v) => {
              console.log(v);
              setValues({ ...values, ...v })
            }}
          >
            <Form.Item
              name="userName">
              <Input
                allowClear
                size="large"
                placeholder="账号"
                rules={[{ required: true, message: '请输入账号!' }]}
                bordered={false}
                style={{ borderBottom: '1px solid #EAEAEA' }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '请输入密码!' }]}
              name="passWord"
            >
              <Input.Password
                size="large"
                type="passWord"
                bordered={false}
                style={{ borderBottom: '1px solid #EAEAEA' }}
                placeholder="密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%', marginTop: 50 }}
                size="large"
                disabled={!values.userName || !values.passWord}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className='login-content-logo'>
          <img className='login-logo' src={logo2} alt="" />
          <img className='login-yinzhang' src={yinzhang} alt="" />
        </div>
      </div>
    </div>
  );
};


export default definePage(Login);
