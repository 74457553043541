export function isNumber(value) {
  return !isNaN(value) && !isNaN(parseFloat(value));
}
// 金额转化 元转目标金额
export function formatMoney(
  amount: number | string | undefined,
  unit: '万元' | '百万' | '亿元',
  str: string | undefined,
): string {
  let divisor: number;
  if (!isNumber(amount)) {
    return str || '-';
  }
  switch (unit) {
    case '万元':
      divisor = 1e4;
      break;
    case '百万':
      divisor = 1e6;
      break;
    case '亿元':
      divisor = 1e8;
      break;
    default:
      throw new Error('未知的单位');
  }

  const result: number = (amount as number) / divisor;

  // const resultStr: string = result.toString();

  // 检查小数部分是否大于两位
  // if (resultStr.includes('.') && resultStr.split('.')[1].length > 2) {
  //   return result.toFixed(2);
  // }
  return result.toFixed(2);
  // return resultStr;
}

// 小数转百分比
export function convertToPercentage(
  num: number | string | undefined,
  unit?: string | undefined,
  str?: string | undefined,
): number | string {
  if (!isNumber(num)) {
    return str || '-';
  }
  const result = (num as number) * 100;
  // const resultStr = result.toString();

  // 检查小数部分是否大于两位
  // if (resultStr.includes('.') && resultStr.split('.')[1].length > 2) {
  //   return result.toFixed(2);
  // }
  if(unit) return result.toFixed(2) + '' + unit;
  return result.toFixed(2);
  // return result;
}

// 小数转整数或保留两位小数
export function convertToFixed(
  num: number | string | undefined,
  fiexdNum?: number, 
  str?: string | undefined,
): number | string {
  if (!isNumber(num)) {
    return str || '-';
  }
  // const resultStr = num.toString();

  // 检查小数部分是否大于两位
  // if (resultStr.includes('.') && resultStr.split('.')[1].length > 2) {
  //   return num.toFixed(2);
  // }
  return Number(num).toFixed(fiexdNum || 2);
  // return num;
}

// “，”分割金额并保留两位小数
export function formatCurrency(amount: number) {
  if (!isNumber(amount)) {
    return amount || '-';
  }
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
