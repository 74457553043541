import { PlusOutlined } from "@ant-design/icons";
import { Button, Cascader, Checkbox, Input, Spin, Tag } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { MointorContext } from "../../index";
import "./index.less";
import { getPrdSpecs } from "../../services";

interface IRecord {
  label: string;
  value: string;
}

enum ESpecs {
  ALL = "all",
}

const selectedAll = [{ label: "全部", value: ESpecs.ALL }];
const getClassNames = (className) => `product-monitor-${className}`;

const Filter = () => {
  const [initOptions, setInitOptions] = useState([]); // 所有品规集合
  const [options, setOptions] = useState<IRecord[]>([]); // 当前下拉框展示的品规集合
  const [tags, setTags] = useState<IRecord[]>([]); // 选中的品规结果展示集合
  const [selectedValue, setSelectedValue] = useState<string[][]>([]); // 选中的值，用于回填下拉选项
  const [checkAll, setCheckAll] = useState(true); // 全选按钮是否选中
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getSpecs } = useContext(MointorContext);
  const indeterminate =
    selectedValue.length > 0 && selectedValue.length < initOptions.length;

  useEffect(() => {
    setLoading(true);
    // 首次获取品规列表时，1.保存全部品规 2.保存需要展示的下拉选项 3.默认全选
    getPrdSpecs({})
      .then((res: any) => {
        const data = res.data || [];
        const value = data.map((item) => [item.value]);
        setOptions(data);
        setInitOptions(data);
        setTags(selectedAll);
        getSpecs && getSpecs(value.flat());
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 模糊搜索时，获取当前需要展示的品规选项
  const requestSpecs = (keywords?: string) => {
    setLoading(true);
    getPrdSpecs({ keywords })
      .then((res: any) => {
        const data = res.data || [];
        setOptions(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 模糊搜索品规
  const handleInputSearch = _.debounce((e: any) => {
    requestSpecs(e.target.value);
    setIsSearch(!!e.target.value);
  }, 500);

  // 面板选择
  const handleCascaderChange = (value: string[][]) => {
    setSelectedValue(value);
    getSpecs && getSpecs(value.flat());
    const _selectedOptions = value
      ?.flat()
      ?.map((item) => ({ label: item, value: item }));
    setTags(_selectedOptions);
  };

  // 重置
  const cancelAll = () => {
    setSelectedValue([]);
    getSpecs && getSpecs([]);
    setTags([]);
    setCheckAll(false);
  };

  // 全部品规按钮的回调函数
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const specs = options.map((item) => item.value);
      setCheckAll(true);
      setSelectedValue([]);
      setTags(selectedAll);
      getSpecs && getSpecs(specs);
    } else {
      setCheckAll(false);
      cancelAll();
    }
  };

  // 在原已选的品规基础上，追加品规
  const handleSelectedAll = () => {
    const currentChooseVals = options.map((item) => [item.value]);
    const mergeVals = [...selectedValue, ...currentChooseVals];
    const specsVals = _.uniq(mergeVals.flat());
    const _tags = _.unionBy([...tags, ...options], "value").filter(
      (item) => item.value !== ESpecs.ALL
    );
    setSelectedValue(mergeVals);
    setTags(_tags);
    getSpecs && getSpecs(specsVals);
  };

  const dropdownRender = (menus: React.ReactNode) => {
    return (
      <div className={getClassNames("filter-dropdown-render")}>
        <div className={getClassNames("filter-input-search")}>
          <Input.Search placeholder="搜索关键字" onChange={handleInputSearch} />
        </div>
        <Spin spinning={loading}>
          <div style={{ margin: "6px 0 2px 12px" }}>
            {!isSearch && options.length > 0 && (
              <Checkbox
                onChange={onCheckAllChange}
                checked={checkAll}
                indeterminate={indeterminate}
              >
                全部
              </Checkbox>
            )}
          </div>

          {menus}
          <div
            style={{ background: "#fff", padding: 4 }}
            className={getClassNames("filter-selected-all")}
          >
            {isSearch && options.length > 0 && (
              <Button type="primary" block onClick={handleSelectedAll}>
                一键全选
              </Button>
            )}
          </div>
        </Spin>
      </div>
    );
  };

  return (
    <div className={getClassNames("filter")}>
      <div className={getClassNames("filter-action-wrap")}>
        <span>品种品规</span>
        <Cascader
          options={options}
          value={selectedValue}
          multiple
          dropdownRender={dropdownRender}
          onChange={handleCascaderChange as any}
        >
          <span className={getClassNames("filter-action")}>
            <PlusOutlined /> 选择
          </span>
        </Cascader>
      </div>
      <div className={getClassNames("filter-result")}>
        {tags.map((item) => (
          <Tag
            key={item.value}
            closable
            onClose={(e) => {
              e.preventDefault();
              if (item.value === ESpecs.ALL) {
                cancelAll();
                return;
              }
              const selectVal = selectedValue.filter(
                (val) => !_.includes(val, item.value)
              );
              setSelectedValue(selectVal);
              getSpecs && getSpecs(selectVal.flat());
              const _tags = tags.filter((tag) => tag.value !== item.value);
              setTags(_tags);
            }}
          >
            {item.label}
          </Tag>
        ))}
      </div>
      <div className={getClassNames("filter-reset")} onClick={cancelAll}>
        重置
      </div>
    </div>
  );
};

export default Filter;
