import vsf, { definePage } from "@vs/vsf-boot";
import { Section, Tooltip, Popover } from "@vs/vsf-kit";
import { Row, Col, Drawer } from "antd";
import React, { useState, useCallback, useEffect, useRef } from "react";
import * as echarts from "echarts";
import ContentHeader from "@/module/ContentHeader";
import GuideBtn from "@/module/GuideBtn";
import up from "@/assets/images/up.png";
import down from "@/assets/images/down.png";
import question from "@/assets/images/question.png";
import warn from "@/assets/images/warn.png";
import enery1 from "@/assets/images/enery1.png";
import enery2 from "@/assets/images/enery2.png";
import enery3 from "@/assets/images/enery3.png";
import empty from "@/assets/images/empty.png";
import { formatMoney, convertToPercentage, convertToFixed } from "@/tools";
// import vsf, { definePage } from '@vs/vsf-boot';
import { Anchor, DatePicker, Select } from "antd";
import {
  DrugName,
  echartsColor,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
  riskPassRate,
  energyList,
  echartsColorList,
  FactoryListD,
} from "@/config";
import "./index.less";
import dayjs from "dayjs";
import classNames from "classnames";
import {
  CaretRightOutlined,
  CloseCircleFilled,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { handleToFixed, handleChartSize } from "@/utils";
const EnergyDashboard = (props) => {
  const { global } = props?.stores || {};
  const [currentChart, setCurrentChart] = useState({});
  const [drawerShow, setDrawerShow] = useState(false);
  const { startLoading, stopLoading } = global || {};
  const [data, setData] = useState(null);
  const [date, setDate] = useState(
    props.location.state?.date
      ? dayjs(props.location.state?.date)?.format("YYYYMM")
      : dayjs()?.format("YYYYMM")
  );
  const [currentFactorySelect, setCurrentFactorySelect] = useState();
  const [currentEnergySelect, setCurrentEnergySelect] = useState();

  const [allFactorySelect, setAllFactorySelect] = useState(null);
  const [allEnergySelect, setAllEnergySelect] = useState(null);

  const [SelectV, setSelect] = useState(false);
  const [SelectV2, setSelect2] = useState(false);
  const [energyData, setEnergyData] = useState(null);
  const [factoryData, setFactoryData] = useState(null);

  useEffect(async () => {
    startLoading();
    await handleQueryDataOverview(date);
    await handleFactorySelect();
    await handleEnergySelect();
    if (currentEnergySelect?.value && currentFactorySelect?.value) {
      await handleQueryEnergy();
      await handleQueryFactory();
    }
    // await handleQueryOutCount(date);
    stopLoading();
    // rigth-chart
  }, [date]);
  useEffect(() => {
    if (currentChart) {
      const chartX = currentChart?.list?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const dataChart = currentChart?.list?.map((v) => {
        // unit
        return currentChart?.unit == "%"
          ? handleToFixed(v?.proportion * 100, 2)
          : handleToFixed(v?.proportion, 2);
      });
      handleDrawerChart(
        "energ-Drawer-chart",
        currentChart.title,
        chartX,
        dataChart
      );
    }
  }, [currentChart]);
  useEffect(() => {
    if (data) {
      const chartX = data?.wanyuanProductConsumptionLine?.map(
        (v) => v?.yearAndMonth
      );
      const chartData = data?.wanyuanProductConsumptionLine?.map((v) =>
        handleToFixed(v?.proportion, 2)
      );
      handleChart("echarts1", "万元产值能耗", chartX, chartData, "#2460FF");
      const chart2X = data?.productConsumptionSumLine?.map(
        (v) => v?.yearAndMonth
      );
      const chart2Data = data?.productConsumptionSumLine?.map((v) =>
        handleToFixed(v?.proportion, 2)
      );
      handleChart("echarts2", "能源消耗总额", chart2X, chart2Data, "#2460FF");
      const chart3X = data?.wanyuanProductCo2Line?.map((v) => v?.yearAndMonth);
      const chart3Data = data?.wanyuanProductCo2Line?.map((v) =>
        handleToFixed(v?.proportion, 2)
      );
      handleChart(
        "echarts3",
        "万元产值二氧化碳",
        chart3X,
        chart3Data,
        "#2460FF"
      );
    }
  }, [data]);
  useEffect(async () => {
    // handleQueryFactory();
    setFactoryData([]);
    if (currentEnergySelect?.value) {
      startLoading();
      await handleQueryFactory();
      // await handleQueryOutCount(date);
      stopLoading();
    }
  }, [currentEnergySelect]);
  useEffect(async () => {
    // handleQueryEnergy();
    setEnergyData([]);
    if (currentFactorySelect?.value) {
      startLoading();
      await handleQueryEnergy();
      stopLoading();
    }
  }, [currentFactorySelect]);

  useEffect(() => {
    // 监听窗口大小改变事件
    window.addEventListener("resize", () => {
      handleChartSize("center-chart");
      handleChartSize("rigth-chart");
      handleChartSize("left-chart");
      handleChartSize("echarts1");
      handleChartSize("echarts2");
      handleChartSize("echarts3");
    });
    return () => {
      window.removeEventListener("resize", () => {
        handleChartSize("center-chart");
        handleChartSize("rigth-chart");
        handleChartSize("left-chart");
        handleChartSize("echarts1");
        handleChartSize("echarts2");
        handleChartSize("echarts3");
      });
    };
  }, []);

  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYYMM"));
  };
  const renderIsUp = (value) => {
    return value > 0 ? up : down;
  };

  const handleChartClick = (value) => {
    setDrawerShow(true);
    setCurrentChart(value);
  };

  const handleQueryDataOverview = async (date) => {
    const data = await vsf?.services?.queryEnergyOverview({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });
    if (data?.code === 200) {
      console.log(data);
      const value = data?.data;
      setData(data?.data);
      const chart2X = value?.outPutLine?.map((v) =>
        dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chart2Data = value?.outPutLine?.map((v) =>
        handleToFixed(v?.proportion, 2)
      );
      handleLineChart(
        "center-chart",
        chart2X,
        [renderChartsData(chart2Data, "产值")],
        false,
        40
      );
    }
  };
  const handleLineChart = (
    id,
    chartX,
    chartData,
    isLegend = true,
    top = 80,
    rate = 0
  ) => {
    console.log(chartX, "-------------------");
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      grid: {
        left: 0,
        containLabel: true,
        top: top,
        right: 20,
        bottom: 27,
      },
      legend: {
        type: "plain",
        show: isLegend,
        // left: 0,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
        left: 0,
        top: 24,
      },
      xAxis: {
        type: "category",
        // type: 'value',
        data: chartX,
        axisTick: {
          show: false,
        },
        // axisLine: {
        //   show: isLegend,
        // },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLabel: {
          formatter: (value) => {
            console.log(value, "----");
            return dayjs(value)?.format("MM月");
          },
          // interval: interval,
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: rate,
        },
      },
      tooltip: {
        trigger: "axis",
        // show: isLegend,
        valueFormatter: (value) => {
          return value;
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: isLegend,
      },
      series: chartData,
    };

    dom && option && myChart.setOption(option);
  };

  const handleDrawerChart = (id, name, chartX = [], dataChart = [], color) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      // ...defaultOption,
      grid: {
        left: 0,
        top: 20,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: [
        {
          name,
          data: dataChart,
          type: "line",
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };
  const handleChart = (id, name, chartX = [], dataChart = [], color) => {
    const myChart = echarts.init(document.getElementById(id));
    const option = {
      // ...defaultOption,
      grid: {
        left: 0,
        // containLabel: true,
        top: 0,
        right: 0,
        bottom: 0,
        // containLabel: true,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        show: false,
        height: 0,
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
      },
      yAxis: {
        show: false,
      },

      series: [
        {
          name,
          data: dataChart,
          type: "line",
          areaStyle: {
            opacity: 0.1,
            color: color,
          },
          smooth: true,
          showSymbol: false,
          lineStyle: {
            color: color, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        },
      ],
    };
    option && myChart.setOption(option);
  };

  const handleFactorySelect = async () => {
    const data = await vsf?.services?.factorySelect({});
    if (data?.code === 200) {
      console.log(data, "data");
      const arr = data?.data?.map((v) => {
        return { value: v, label: v };
      });
      setAllFactorySelect(arr);
      setCurrentFactorySelect(arr?.[0]);
    }
  };
  const handleEnergySelect = async () => {
    const data = await vsf?.services?.energySelect({});
    if (data?.code === 200) {
      const arr = data?.data?.map((v) => {
        return { value: v, label: v };
      });
      setAllEnergySelect(arr);
      setCurrentEnergySelect(arr?.[0]);
    }
  };
  const handleQueryEnergy = async () => {
    const data = await vsf?.services?.overviewEnergy({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
      factory: currentFactorySelect?.value,
    });
    if (data?.code === 200) {
      setEnergyData([
        ...data?.data?.lastEnergyForEnergyEoList,
        ...data?.data?.nowEnergyForEnergyEoList,
      ]);
      // console.log("------");
      handlePortraitBar(
        "left-chart",
        energyList?.map((v) => v?.label),
        [
          renderDataChart(data?.data?.lastEnergyForEnergyEoList?.[0], 0),
          renderDataChart(data?.data?.nowEnergyForEnergyEoList?.[0], 1),
        ]
      );
    }
  };
  const handleQueryFactory = async () => {
    const data = await vsf?.services?.overviewFactory({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
      energy: currentEnergySelect?.value,
    });
    if (data?.code === 200) {
      setFactoryData([
        ...data?.data?.lastEnergyForFactoryEoList,
        ...data?.data?.nowEnergyForFactoryEoList,
      ]);

      const renderData = (data, i) => {
        const datavalue = FactoryListD?.map((v) => {
          return data?.find((item) => v == item?.factory);
        });
        return {
          // data1: data?.map((v) => v?.personNum),
          name: dayjs(datavalue?.[0]?.year)?.format("YYYY年"),
          data: datavalue?.map((v) => handleToFixed(v?.totalAmount ?? 0, 2)),
          type: "bar",
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: echartsColorList?.[i],
            // opacity: 0.35,
          },
          barWidth: 18,
        };
      };
      console.log(
        renderData(data?.data?.lastEnergyForFactoryEoList, 0),
        renderData(data?.data?.nowEnergyForFactoryEoList, 1),
        "renderData(data?.data?.nowEnergyForFactoryEoList, 1)"
      );
      handlePortraitBar("rigth-chart", FactoryListD, [
        renderData(data?.data?.lastEnergyForFactoryEoList, 0),
        renderData(data?.data?.nowEnergyForFactoryEoList, 1),
      ]);
    }
  };
  const handleSelectChangeEnergy = (value) => {
    setCurrentEnergySelect(allEnergySelect?.find((v) => v?.value == value));
  };
  const handleSelectChangeFactory = (value) => {
    setCurrentFactorySelect(allFactorySelect?.find((v) => v?.value == value));
  };
  const handlePortraitBar = (id, chartX = [], dataChart = []) => {
    // console.log(dataChart, "dataChartlengtlengtlengtlengt");
    let myChart;
    const dom = document.getElementById(id);
    dom && (myChart = echarts.init(dom));
    // barRef.current = myChart;
    const option = {
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },
      color: echartsColorList,
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 10,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
      },
      grid: {
        left: 0,
        containLabel: true,
        top: 60,
        right: 0,
        bottom: 40,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        axisLabel: {
          // formatter: (value) => {
          //   return dayjs(value)?.format('MM月');
          // },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          // opacity:mouseoverBar
          // rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: dataChart,
    };
    dom && option && myChart.setOption(option, true);
  };

  const renderDataChart = (data, i) => {
    const datavalue = energyList?.map((v) =>
      handleToFixed(data?.[v?.value], 2)
    );

    return {
      // data1: data?.map((v) => v?.personNum),
      name: data?.year + "年",
      data: datavalue,
      type: "bar",
      smooth: true,
      showSymbol: false,
      itemStyle: {
        color: echartsColorList?.[i],
        // opacity: 0.35,
      },
      barWidth: 18,
    };
  };
  const renderChartsData = (data, name, isAreaStyle = false) => {
    const areaStyleObj = {};
    isAreaStyle &&
      (areaStyleObj.areaStyle = { color: "#EFF3FF", opacity: 0.88 });
    return {
      data: data,
      type: "line",
      smooth: true,
      name,
      showSymbol: false,
      lineStyle: {
        width: 2, // 设置折线宽度为 2
        // ... 可以设置更多线条样式属性
      },
      ...areaStyleObj,
    };
  };
  console.log(factoryData, "----aaaaa");
  return (
    <div className="energ-index">
      <ContentHeader
        defaultValue={dayjs(date)}
        onChange={handleDateChange}
        title="概览"
        secondBreadcrumb="能源管理"
        handleNavgateSecondTab={()=>vsf.navigateTo("/dashboard/enery/index")}
        info="涪陵制药厂能源管理核心指标看板"
        value={date}
        show={""}
      />
      <div className="energ-index-card1">
        <div className="energ-index-card1-top">
          <div className="energ-index-card1-top-title">能源指标</div>
        </div>
        <div className="card-list">
          <div className="card">
            <div className="head">
              <div className="title">
                <img className="icon-l" src={enery3} alt="" />
                万元产值能耗
              </div>
              <Popover
                content={
                  <>
                    <p>万元产值能耗 = 总能耗（吨标煤）/总产值（万元）</p>
                  </>
                }
              >
                <img className="icon-r" src={question} alt="" />
              </Popover>
            </div>
            <div className="bottom">
              <div className="num-box">
                <div className="num">
                  {convertToFixed(data?.wanyuanProductConsumption, 4)}
                  <span>吨标煤/万元</span>
                </div>
                <div className="target">
                  <span className="text-vs">VS</span>
                  <span className="tg-m">目标</span>
                  <span>
                    {convertToFixed(data?.wanyuanProductConsumptionTarget, 4)}
                    吨标煤/万元
                  </span>
                </div>
                <div className="change">
                  较上年
                  <img
                    style={{ marginInline: 4 }}
                    src={renderIsUp(data?.wanyuanProductConsumptionCompare)}
                  ></img>
                  <span className="rate">
                    {convertToPercentage(
                      data?.wanyuanProductConsumptionCompare,
                      "%"
                    )}
                  </span>
                </div>
              </div>
              <Tooltip title="点击查看图表详情" placement="bottom">
                <div
                  className="echarts"
                  id="echarts1"
                  onClick={() => {
                    handleChartClick({
                      list: data?.wanyuanProductConsumptionLine,
                      title: "万元产值能耗",
                      rate: convertToFixed(data?.wanyuanProductConsumption, 4),
                      target: convertToFixed(data?.wanyuanProductConsumptionTarget, 4),
                      planRate: data?.wanyuanProductConsumptionCompare,
                      unit: "吨标煤/万元",
                      text: "万元产值能耗 = 总能耗（吨标煤）/总产值（万元）",
                    });
                  }}
                ></div>
              </Tooltip>
            </div>
          </div>
          <div className="card">
            <div className="head">
              <div className="title">
                <img className="icon-l" src={enery2} alt="" />
                能源消耗总额
                {/* <span
                  // className="sign-tag sign-down-tag"
                  className={classNames(`sign-tag`, `sign-down-tag`, {
                    "sign-up-tag": data?.wanyuanQualityLoss < 1,
                  })}
                >
                  {renderExpect(data?.wanyuanQualityLoss, 1)}
                </span> */}
              </div>
              <Popover content={<><p>"包含：水费，电费，天然气费和外购蒸汽费；"</p><p>年底为财务口径，1-11月为设备部口径</p></>}>
                <img className="icon-r" src={question} alt="" />
              </Popover>
            </div>
            <div className="bottom">
              <div className="num-box">
                <div className="num">
                  {convertToFixed(data?.productConsumptionSum)}
                  <span>万元</span>
                </div>
                <div className="target">
                  <span className="text-vs">VS</span>
                  <span className="tg-m">目标</span>
                  <span>
                    {convertToFixed(data?.productConsumptionSumTarget)}万元
                  </span>
                </div>
                <div className="change">
                  较上年
                  <img
                    style={{ marginInline: 4 }}
                    src={renderIsUp(data?.productConsumptionSumCompare)}
                  ></img>
                  <span className="rate">
                    {convertToPercentage(
                      data?.productConsumptionSumCompare,
                      "%"
                    )}
                  </span>
                </div>
              </div>
              <Tooltip title="点击查看图表详情" placement="bottom">
                <div
                  className="echarts"
                  id="echarts2"
                  onClick={() => {
                    handleChartClick({
                      list: data?.productConsumptionSumLine,
                      title: "能源消耗总额",
                      rate: convertToFixed(data?.productConsumptionSum),
                      planRate: data?.productConsumptionSumCompare,
                      target: convertToFixed(data?.productConsumptionSumTarget),
                      unit: "万元",
                      text: "包含：水费，电费，天然气费和外购蒸汽费；年底为财务口径，1-11月为设备部口径",
                    });
                  }}
                ></div>
              </Tooltip>
            </div>
          </div>
          <div className="card">
            <div className="head">
              <div className="title">
                <img className="icon-l" src={enery1} alt="" />
                万元产值二氧化碳
              </div>
              <Popover
                placement="topLeft"
                content="万元产值二氧化碳 = 总二氧化碳排放（吨）/总产值（万元）"
              >
                <img className="icon-r" src={question} alt="" />
              </Popover>
            </div>
            <div className="bottom">
              <div className="num-box">
                <div className="num">
                  {convertToFixed(data?.wanyuanProductCo2, 4)}
                  <span>吨/万元</span>
                </div>
                <div className="target">
                  <span className="text-vs">VS</span>
                  <span className="tg-m">目标</span>
                  <span>
                    {convertToFixed(data?.wanyuanProductCo2Target, 4)}吨/万元
                  </span>
                </div>
                <div className="change">
                  较上年
                  <img
                    style={{ marginInline: 4 }}
                    src={renderIsUp(data?.wanyuanProductCo2Compare)}
                  ></img>
                  <span className="rate">
                    {convertToPercentage(data?.wanyuanProductCo2Compare, "%")}
                  </span>
                </div>
              </div>
              <Tooltip title="点击查看图表详情" placement="bottom">
                <div
                  className="echarts"
                  id="echarts3"
                  onClick={() => {
                    handleChartClick({
                      list: data?.wanyuanProductCo2Line,
                      title: "万元产值二氧化碳",
                      rate: convertToFixed(data?.wanyuanProductCo2, 4),
                      planRate: data?.wanyuanProductCo2Compare,
                      target: convertToFixed(data?.wanyuanProductCo2Target, 4),
                      unit: "吨/万元",
                      text: "万元产值二氧化碳 = 总二氧化碳排放（吨）/总产值（万元）",
                    });
                  }}
                ></div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="energ-index-center">
        <div className="header-title">
          产值趋势图 <span>单位：万元</span>
        </div>

        {data?.outPutLine && (
          <div className="center-chart" id="center-chart"></div>
        )}
        {!data?.outPutLine && (
          <div className="empty1">
            <img src={empty}></img>
            <div>暂无数据</div>
          </div>
        )}
      </div>
      <div className="energ-index-bottom">
        <div className="bottom-left">
          <div className="bottom-left-header">
            <div className="header-title">
              能源总额-分能源 <span>单位：元</span>
            </div>
            <div className="picker">
              <Select
                // defaultValue={currentFactorySelect?.value}
                value={currentFactorySelect}
                style={{ width: 140 }}
                onChange={handleSelectChangeFactory}
                options={allFactorySelect}
                // onOpenChange={setOpen}
                onDropdownVisibleChange={() => {
                  // console.log('我打开');
                  setSelect2(!SelectV2);
                }}
                // onDeselect={() => setSelect(false)}
                suffixIcon={
                  SelectV2 ? (
                    <CaretUpOutlined size={16} />
                  ) : (
                    <CaretDownOutlined size={16} style={{ color: "#333333" }} />
                  )
                }
              />
            </div>
          </div>
          {energyData && energyData?.length > 0 && (
            <div className="left-chart" id="left-chart"></div>
          )}
          {(energyData?.length == 0 || !energyData) && (
            <div className="empty1">
              <img src={empty}></img>
              <div>暂无数据</div>
            </div>
          )}
        </div>
        <div className="bottom-left">
          <div className="bottom-left-header">
            <div className="header-title">
              能源总额-分厂区 <span>单位：元</span>
            </div>
            <div className="picker">
              <Select
                // defaultValue={currentEnergySelect?.value}
                value={currentEnergySelect}
                style={{ width: 120 }}
                onChange={handleSelectChangeEnergy}
                options={allEnergySelect}
                // onOpenChange={setOpen}
                onDropdownVisibleChange={() => {
                  // console.log('我打开');
                  setSelect(!SelectV);
                }}
                // onDeselect={() => setSelect(false)}
                suffixIcon={
                  SelectV ? (
                    <CaretUpOutlined size={16} />
                  ) : (
                    <CaretDownOutlined size={16} style={{ color: "#333333" }} />
                  )
                }
              />
            </div>
          </div>
          {/* {factoryData?.length > 0 ? (
            <div className="left-chart" id="rigth-chart"></div>
          ) : (
            
          )} */}
          {factoryData && factoryData?.length > 0 && (
            <div className="left-chart" id="rigth-chart"></div>
          )}
          {(factoryData?.length == 0 || !factoryData) && (
            <div className="empty1">
              <img src={empty}></img>
              <div>暂无数据</div>
            </div>
          )}
        </div>
      </div>
      <Drawer
        // title=""
        placement="right"
        onClose={() => setDrawerShow(false)}
        size="large"
        open={drawerShow}
        className="energ-drawer"
      >
        <div className="energ-Drawer-content">
          <div className="energ-Drawer-header">
            <div className="title">
              {currentChart?.title}
              <span className="unit">单位：{currentChart?.unit}</span>
            </div>
            <CloseCircleFilled
              onClick={() => setDrawerShow(false)}
              style={{ color: "#BEBEBE" }}
            ></CloseCircleFilled>
          </div>
          <div className="energ-text"> {currentChart?.text}</div>
          <div className="num">
            <div>{currentChart?.rate}</div>
            <span> {currentChart?.unit}</span>
          </div>
          <div className="target">
            <span className="text-vs">VS</span>
            <span className="tg-m">目标</span>
            <span>
              {currentChart?.target}
              {currentChart?.unit}
            </span>
          </div>
          <div className="num-up">
            较上年
            <img
              style={{ marginInline: 4 }}
              src={renderIsUp(currentChart?.planRate)}
            ></img>
            <span>{convertToPercentage(currentChart?.planRate, "%")}</span>
          </div>
          <div className="energ-Drawer-chart" id="energ-Drawer-chart"></div>
        </div>
      </Drawer>
    </div>
  );
};

export default definePage(EnergyDashboard);
