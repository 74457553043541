import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const global = defineStore({
  userData: storage.get('userData') || {},
  userInfo: storage.get('userInfo') || {},
  isLoading: false,
  setUserData: (v) => {
    global.userData = v;
    storage.set('userData', v);
  },
  setUserInfo: (v) => {
    global.userInfo = v;
    storage.set('userInfo', v);
  },
  getUserInfo: async (v) => {
    if (!global.userInfo.id) return;
    const res = await vsf.services.FlUserController_loginUser_544008();
    if (res.code === 301) {
      vsf.showToast('登录已过期，请重新登录！', 'error');
      global.setUserData({});
      global.setUserInfo({});
    }
  },
  startLoading: () => {
    global.isLoading = true;
  },
  stopLoading: () => {
    global.isLoading = false;
  },
});

export default global;
