import React from 'react';

const ArrowAnimation = ({ style , width, height}) => {
  const radius = width / 2;
  const circleCenter = { x: radius, y: radius };
  const arrowSpacing = 110;
  const arrowWidth = 7;
  const arrowLength = 4;
  const arrowHeadSize = 14;
  const arrowHeadWidth = 9;
  const arrowCount = Math.floor((2 * Math.PI * radius) / (arrowLength + arrowSpacing));
  // const arrowCount1 = Math.floor((2 * Math.PI * radius) / (arrowLength + arrowSpacing)) - 23;
  const arrowCount1 = Math.floor(height * 9 / (arrowWidth + arrowSpacing));

  // 创建箭头的SVG元素
  const createArrow = (index, isArc, startAngle, endAngle,type) => {
    const angle = startAngle + (index * (endAngle - startAngle)) / arrowCount;
    const transform = isArc ? `rotate(${angle} ${circleCenter.x} ${circleCenter.y})` : '';
    // const yPosition = isArc ? circleCenter.y - radius : circleCenter.y - radius - 40 - index * (arrowLength + arrowSpacing);
    const yPosition = isArc ? circleCenter.y - radius : circleCenter.y - radius + index * (arrowHeadWidth + 2);
    return (
      <g key={index} transform={transform}>
        {/* 弧形 */}
        {!(index === arrowCount - 1) && isArc && <rect
          x={circleCenter.x - arrowWidth / 2}
          y={yPosition}
          width={arrowWidth}
          height={arrowLength}
          fill="#ededed"
        // transform={`translate(${-arrowCount * 1.8}, ${2}) scale(1.1, 1.8)`}
        >
          <animate
            attributeName="fill"
            values={type=="left"?"#6C54FF;#ededed;":"#F8C100;#ededed;"}
            dur="4s"
            begin={`${index * 0.1}s`}
            repeatCount="indefinite"
            fill="freeze" // 保持动画结束时的状态
          />
          <animateTransform
            attributeName="transform"
            type="scale"
            begin={`${index * 0.1}s`}
            dur="4s"
            values="1 1.3;1 1"
            repeatCount="indefinite"
          />
        </rect>}
        {/* 直线 */}
        {!(index === arrowCount - 1) && !isArc && <rect
          x={circleCenter.x - arrowWidth / 2}
          y={yPosition}
          width={arrowLength}
          height={arrowWidth}
          fill="#ededed"
        >
          <animate
            attributeName="fill"
            values="#0094FF;#ededed;"
            dur="4s"
            begin={`${index * (0.1 * arrowCount / arrowCount1)}s`}
            repeatCount="indefinite"
            fill="freeze" // 保持动画结束时的状态
          />
          <animate
            attributeName="width"
            begin={`${index * (0.1 * arrowCount / arrowCount1)}s`}
            dur="4s"
            values={`${arrowLength * 1.3};${arrowLength}`}
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="translate"
            begin={`${index * (0.1 * arrowCount / arrowCount1)}s`}
            dur="0s"
            values={`0;${-arrowLength * 0.3 / 2};0`}
            repeatCount="indefinite"
            additive="sum"
          />
        </rect>}
        {/* 右边三角 */}
        {index === arrowCount - 1 && startAngle > 0 && (
          <polygon
            points={`${circleCenter.x - 13},${yPosition + 12} 
                     ${circleCenter.x - arrowHeadWidth - 4},${yPosition - arrowHeadSize + 9} 
                     ${circleCenter.x + arrowHeadWidth - 3},${yPosition - arrowHeadSize + 18}`}
            fill="#ededed"
          >
            <animate
              attributeName="fill"
              values="#F8C100;#ededed;"
              dur="4s"
              begin={`${index * 0.1}s`}
              repeatCount="indefinite"
              fill="freeze" // 保持动画结束时的状态
            />
            <animateTransform
              attributeName="transform"
              type="scale"
              begin={`${index * 0.1}s`}
              dur="4s"
              values="1 1.3;1 1"
              repeatCount="indefinite"
            />

          </polygon>
        )}
        {/* 左边三角 */}
        {index === arrowCount - 1 && startAngle < 0 && (
          <polygon
            points={`${circleCenter.x + 20},${yPosition + 12} 
                     ${circleCenter.x - arrowHeadWidth + 8},${yPosition - arrowHeadSize + 18} 
                     ${circleCenter.x + arrowHeadWidth + 10},${yPosition - arrowHeadSize + 8}`}
            fill="#ededed"
          >
            <animate
              attributeName="fill"
              values="#6C54FF;#ededed;"
              dur="4s"
              begin={`${index * 0.1}s`}
              repeatCount="indefinite"
              fill="freeze" // 保持动画结束时的状态
            />
            <animateTransform
              attributeName="transform"
              type="scale"
              begin={`${index * 0.1}s`}
              dur="4s"
              values="1 1.3;1 1"
              repeatCount="indefinite"
            />
          </polygon>
        )}
        {/*中间三角 */}
        {index === arrowCount1 - 1 && startAngle == 0 && (
          <polygon
            points={`${circleCenter.x - 10},${yPosition + 16} 
                     ${circleCenter.x + 6},${yPosition + 16} 
                     ${circleCenter.x - 2},${yPosition + 35}`}
            fill="#ededed"
          >
            <animate
              attributeName="fill"
              values="#0094FF;#ededed;"
              dur="4s"
              begin={`${index * (0.1 * arrowCount / arrowCount1)}s`}
              repeatCount="indefinite"
              fill="freeze" // 保持动画结束时的状态
            />
            <animate
              attributeName="width"
              begin={`${index * (0.1 * arrowCount / arrowCount1)}s`}
              dur="4s"
              values={`${arrowLength * 1.3};${arrowLength}`}
              repeatCount="indefinite"
            />
          </polygon>
        )}
      </g>
    );
  };

  return (
    <svg style={style} width={width} height={height}>
      {/* 绘制箭头 */}
      {/* 左侧圆弧箭头 */}
      {Array.from({ length: arrowCount }, (_, index) => createArrow(index, true, 1, 40,'right'))}
      {/* 右侧圆弧箭头 */}
      {Array.from({ length: arrowCount }, (_, index) => createArrow(index, true, -1, -40,'left'))}
      {/* y轴直线箭头 */}
      {Array.from({ length: arrowCount1 }, (_, index) => createArrow(index, false, 0, 0))}
    </svg>
  );
};

export default ArrowAnimation;