import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Tooltip } from '@vs/vsf-kit';
// import { Row, Col } from 'antd';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ContentHeader from '@/module/ContentHeader';
import GuideBtn from '@/module/GuideBtn';
import * as echarts from 'echarts';
import ecStat from 'echarts-stat';
import { formatMoney, convertToPercentage, convertToFixed } from '@/tools';
import {
  lastYearEnum as flagLastYearEnum,
  expectEnum as flagExpectEnum,
  manufacturingCos,
} from '@/config';
import dayjs from 'dayjs';
import up from '@/assets/images/up.png';
import down from '@/assets/images/down.png';
import up1 from '@/assets/images/up1.png';
import down1 from '@/assets/images/down1.png';
import warn from '@/assets/images/warn.png';
import Polygon from '@/assets/images/Polygon.png';
import empty from '@/assets/images/empty.png';
import './index.less';
import {
  echartsColor,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
  riskPassRate,
  riskTabs,
  echartsColorList,
} from '@/config';
import classnames from 'classnames';
import { DatePicker, Drawer, Row, Col } from 'antd';
import { CaretRightOutlined, CloseCircleFilled } from '@ant-design/icons';
import { handleToFixed } from '@/utils';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
const chartDataList = [
  {
    color: echartsColor?.echartsColor_0052D9,
    label: '当月单盒成本',
    value: 'costMonth',
  },
  {
    color: echartsColor?.echartsColor_2FC25B,
    label: '单盒制造费用',
    value: 'createCost',
  },
  {
    color: echartsColor?.echartsColor_A350F6,
    label: '原辅料单盒成本',
    value: 'rawMaterialCost',
  },
  {
    color: echartsColor?.echartsColor_60C5D8,
    label: '包装单盒成本',
    value: 'packageMaterialCost',
  },
  {
    color: echartsColor?.echartsColor_6F95F8,
    label: '直接人工成本',
    value: 'personCost',
  },
];

const OrganizeBrain = (props) => {
  const { global } = props?.stores || {};
  const { startLoading, stopLoading } = global || {};
  const [date, setDate] = useState(
    props.location.state?.date
      ? dayjs(props.location.state?.date)?.format('YYYYMM')
      : dayjs()?.format('YYYYMM'),
  );
  const [bottomDate, setBottomDate] = useState(
    props.location.state?.date
      ? dayjs(props.location.state?.date)?.format('YYYYMM')
      : dayjs()?.format('YYYYMM'),
  );
  const [costManager, setCostManager] = useState({});
  const [drawerShow, setDrawerShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabData, setTabData] = useState(null);
  const [tabData2, settabData2] = useState(null);
  const [anchorId, setAnchorId] = useState(
    props.location.state?.anchorId ?? 'createCost',
  );
  const [bottomData, setbottomData] = useState(null);
  const [thirdData, setthirdData] = useState(null);
  // const anchorId = props.location.state?.anchorId;
  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format('YYYYMM'));
  };
  const rateChart = useRef();
  const queryCostManager = async (yearAndMonth) => {
    const res =
      await vsf.services.DataOverviewController_queryCostManager_ab5035({
        yearAndMonth,
      });
    if (res.code === 200) {
      setCostManager(res.data);
    }
  };

  useEffect(async () => {
    console.log(date);
    startLoading();
    await queryCostManager(date);
    stopLoading();
    // handleManufacturingCost(date);
    anchorId === 'createCost' && handleManufacturingCost(date);
    anchorId === 'rawMaterial' && handleQueryPurchasePage(date);
  }, [date, anchorId]);

  useEffect(() => {
    anchorId === 'createCost' && handlecostByDonth(bottomDate);
  }, [bottomDate, anchorId]);

  useEffect(() => {
    if (costManager) {
      const chartX = costManager?.costManagerList?.map((v) =>
        dayjs(v?.yearAndMonth)?.format('YYYY-MM'),
      );
      const chartData = chartDataList?.map((v) => {
        return {
          data: costManager?.costManagerList?.map((item) =>
            handleToFixed(item?.[v?.value], 2),
          ),
          type: 'line',
          smooth: true,
          name: v?.label,
          showSymbol: false,
          lineStyle: {
            color: v?.color, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        };
      });
      // console.log(chartData);
      handleChart('cost-rate-chart', chartX, chartData, 5);
      handleChart('cost-Drawer-chart', chartX, chartData, 0, true);
      // cost-Drawer-chart
    }
  }, [costManager, drawerShow]);

  const handleChart = (id, chartX, chartData, interval, isLegend = false) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      grid: {
        left: 0,
        containLabel: true,
        top: 40,
        right: 20,
        bottom: 27,
      },
      legend: {
        type: 'plain',
        show: isLegend,
        // left: 0,
        itemWidth: 12,
        itemHeight: 12,
        icon: 'rect',
        selectedMode: false,
        left: 0,
        top: 0,
      },
      xAxis: {
        type: 'category',
        // type: 'value',
        data: chartX,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: isLegend,
        },

        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format('MM月');
          },
          interval: interval,
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
      },
      tooltip: {
        trigger: 'axis',
        show: isLegend,
        valueFormatter: (value) => {
          return value;
        },
      },
      yAxis: {
        type: 'value',
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: '{value}',
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        show: isLegend,
      },
      series: chartData,
    };

    dom && option && myChart.setOption(option);
  };

  const handlePieChart = (id, chartData) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      grid: {
        left: 0,
        containLabel: true,
        top: 20,
        right: 0,
        bottom: 20,
      },
      color: echartsColorList,
      tooltip: {
        trigger: 'item',
        valueFormatter: (value) => {
          return value;
        },
      },
      series: [
        {
          name: '总制造费用构成',
          type: 'pie',
          radius: [40, '80%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 0,
          },
          data: chartData,
          label: {
            // width: 80,
            overflow: 'break',
            // formatter: (value) => {
            //   console.log(value, '----');
            //   return '{b}: {d}%';
            // },
            formatter: '{b}\n\n{d}%',
          },
          // avoidLabelOverlap: false,
        },
      ],
    };

    dom && option && myChart.setOption(option);
  };
  const handleScatterChart = (id, chartData, legt) => {
    console.log(legt, 'legt');
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    echarts.registerTransform(ecStat.transform.regression);
    // data, regTitle, "regression-chart", "linear", 1
    let regressionDemo = ecStat.regression("logarithmic", chartData, 1);
    regressionDemo.points.sort(function (a, b) {
      return a[0] - b[0];
    });
    const option = {
      dataset: [
        {
          source: chartData,
        },
        {
          transform: {
            type: 'ecStat:regression',
            config: {
              method: 'exponential',
              // 'end' by default
              // formulaOn: 'start'
            },
          },
        },
      ],
      xAxis: {
        name: '实际产能',
        nameLocation: 'middle',
        nameTextStyle: {
          color: '#333333',
          fontSize: 14,
          padding: [20, 0, 0, 0],
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      yAxis: {
        scale: true,
        // name: '单\n盒\n成\n本\n制\n造\n费\n用',
        name: '单盒成本制造费用',

        // nameLocation: 'left',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [0, 0, legt, 0],
          color: '#333333',
          fontSize: 14,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      grid: {
        left: legt < 30 ? 30 : legt,
        containLabel: true,
        top: 20,
        right: 40,
        bottom: 40,
      },
      series: [
        {
          name: 'scatter',
          type: 'scatter',
          datasetIndex: 0,
        },
        {
          name: 'line',
          type: 'line',
          smooth: true,
          datasetIndex: 1,
          symbolSize: 0.1,
          symbol: 'circle',
          itemStyle: {
            color: '#45A5FF',
          },
          data: regressionDemo.points
        },
      ],
    };

    dom && option && myChart.setOption(option);
  };

  const handleBarChart = (id, chartX = [], dataChart = []) => {
    // console.log(dataChart, 'dataChart');
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    // barRef.current = myChart;
    const option = {
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 0,
        top: 20,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLine: {
          lineStyle: {
            color: '#EAEAEA', // 这里设置 x 轴线的颜色
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format('MM月');
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(234,234,234,0.3)', // 这里设置 y 轴网格线的颜色
          },
        },
        axisLabel: {
          formatter: '{value}',
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: [
        {
          data: dataChart,
          type: 'bar',
          smooth: true,
          showSymbol: false,
          // // name: '售后厂区库存',
          // lineStyle: {
          //   color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
          //   width: 2, // 设置折线宽度为 2
          //   // opacity: 0.1,
          // },
          // itemStyle: {
          //   color: echartsColor?.echartsColor_0052D9,
          //   // opacity: mouseoverBar ? 0.5 : 1,
          // },

          // emphasis: {
          //   itemStyle: {
          //     color: echartsColor?.echartsColor_0052D9,
          //     opacity: 1,
          //   },
          // },
          barWidth: 18,
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };
  const handleBDateChange = (value) => {
    setBottomDate(dayjs(value)?.format('YYYY-MM'));
  };
  const handleIsNumber = (value) => {
    return typeof value === 'number' || typeof value === 'string';
  };
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  };
  const handleChange = (id) => () => {
    setAnchorId(id);
  };
  const formatNumber = (num) => {
    // 将数字转换为字符串
    const numStr = String(num);
    // 找到小数点的位置
    const dotIndex = numStr.indexOf('.');

    // 如果没有小数点或小数部分长度不超过3，直接返回原数
    if (dotIndex === -1 || numStr.length - dotIndex - 1 <= 2) {
      return num;
    }

    // 如果小数部分长度超过3，使用toFixed(3)并转换为数字
    return Number(num.toFixed(2));
  }

  const handleManufacturingCost = async (date) => {
    const data = await vsf?.services?.manufacturingCost({
      yearAndMonth: dayjs(date)?.format('YYYYMM'),
    });
    if (data?.code === 200) {
      setTabData(data?.data);
      console.log(data?.data, 'data?.data');
      const value = data?.data;
      const chartx = value?.totalManufacturingAnalysisEos?.map((v) =>
        dayjs(v?.yearAndMonth)?.format('YYYY-MM'),
      );
      const chartData = manufacturingCos?.map((v) => {
        return {
          data: value?.totalManufacturingAnalysisEos?.map((item) =>
            item?.[v?.value]?.toFixed(2),
          ),
          name: v?.label,
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: {
            // color: echartsColor?.echartsColor_0052D9, // 设置折线的颜色为红色
            width: 2, // 设置折线宽度为 2
            // ... 可以设置更多线条样式属性
          },
        };
      });
      handleRateChart('first-chart', chartx, chartData);
      console.log(value?.influenceFactorEo);
      const chartData1 = value?.influenceFactorEo?.map((v) => [
        v?.realProduct,
        v?.totalCost,
      ]);
      const flatArr = value?.influenceFactorEo?.map((v) => formatNumber(v?.totalCost));
      const strLength = flatArr?.sort((a, b) => a - b)?.[flatArr?.length - 1]?.toString().length
      let legt = strLength * 5.8;
      console.log(flatArr);
      handleScatterChart('third-rigth-chart', chartData1, legt);
    }
  };
  const handleQueryPurchasePage = async (date) => {
    const data = await vsf?.services?.queryPurchasePage({
      yearAndMonth: dayjs(date)?.format('YYYYMM'),
    });
    if (data?.code === 200) {
      const value = data?.data;
      setbottomData(data?.data);
      const valueData = value?.lastProcurements?.map((v) => v?.mateName);
      const topValue = valueData?.map((v) => {
        return {
          name: v,
          lastValue: value?.lastProcurements?.find(
            (item) => item?.mateName == v,
          )?.avgAmount,
          nowValue: value?.nowProcurements?.find((item) => item?.mateName == v)
            ?.avgAmount,
        };
      });
      const chart1X = value?.csBipProcurements;

      const renderChartX = (value) => {
        return value?.map((v) => dayjs(v?.yearAndMonth)?.format('YYYY-MM'));
      };

      const renderChartData = (value) => {
        return value?.map((v) => {
          return {
            value: v?.avgAmount?.toFixed(2),
            itemStyle: {
              color: v?.avgAmount?.toFixed(2) > 0 ? '#FF8B38' : '#3D7FFF',
            },
          };
        });
      };

      handleBarChart(
        'chart1',
        renderChartX(value?.csBipProcurements),
        renderChartData(value?.csBipProcurements),
      );
      handleBarChart(
        'chart2',
        renderChartX(value?.bxProcurements),
        renderChartData(value?.bxProcurements),
      );
      handleBarChart(
        'chart3',
        renderChartX(value?.flProcurements),
        renderChartData(value?.flProcurements),
      );
      handleBarChart(
        'chart4',
        renderChartX(value?.bzProcurements),
        renderChartData(value?.bzProcurements),
      );
      console.log(topValue, 'topValue');
      settabData2({
        ...value,
        topValue,
      });
    }
  };
  const handleRateChart = (id, chartx, chartData) => {
    console.log(id, chartx, chartData, 'id, chartx, chartData');
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      // ...defaultOption,
      color: echartsColorList,
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => {
          return value;
        },
      },
      grid: {
        left: 0,
        top: 60,
        right: 10,
        bottom: 0,
        containLabel: true,
      },
      legend: {
        type: 'plain',
        show: true,
        // left: 0,
        top: 20,
        itemWidth: 12,
        itemHeight: 12,
        icon: 'rect',
        selectedMode: false,
        left: 0,
      },
      yAxis: {
        type: 'value',
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: '{value}',
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      xAxis: {
        type: 'category',
        // scale: true,
        data: chartx,
        axisTick: {
          show: false,
          alignWithLabel: true,
          interval: 1,
          length: 4,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format('MM月');
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
      },
      series: chartData,
    };
    dom && option && myChart?.setOption(option);
  };
  const handlecostByDonth = async (date) => {
    const data = await vsf?.services?.costByDonth({
      yearAndMonth: dayjs(date)?.format('YYYYMM'),
    });
    if (data?.code === 200) {
      console.log(data);
      const chatrData = manufacturingCos?.map((v) => {
        return {
          value: data?.data?.[v?.value]?.toFixed(2),
          name: v?.label,
        };
      });
      setthirdData(chatrData?.map((v) => v?.value)?.filter((v) => v));
      handlePieChart('third-left-chart', chatrData);
    }
  };
  const renderTab1 = () => {
    return (
      <div className="cost-tab1">
        <div className="first">
          <div className="header-title" id="anchor1">
            总制造费用分析
          </div>
          <div className="first-content">
            <div className="first-content-left">
              <div className="title"></div>
              <div className="content">
                <div
                  // className="price"
                  className={classnames(`price`, {
                    // firsterr: loss && Number(loss) > 0.5,
                  })}
                >
                  {/* {loss} */}
                  {/* {handleIsNumber(loss) ? loss : '-'} */}
                  {handleToFixed(tabData?.totalManufactoringCost, 2)}
                  <span>万元</span>
                </div>
                <div className="unit">
                  较上月
                  {handleIsNumber(tabData?.totalManufactoringCostCompare) ? (
                    <>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          Number(tabData?.totalManufactoringCostCompare) > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {Math.abs(
                          tabData?.totalManufactoringCostCompare * 100,
                        )?.toFixed(2)}
                        %
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
            <div className="first-content-rigth">
              <div className="rigth-header">
                <div className="title">总制造费用月趋势</div>
                <div className="unit">单位：万元</div>
              </div>
              <div className="first-chart" id="first-chart"></div>
            </div>
          </div>
        </div>
        <div className="third third1">
          <div className="third-content">
            <div className="third-content-left">
              <div>
                <div className="duration-header">
                  <div className="header-date">
                    <DatePicker
                      onChange={handleBDateChange}
                      disabledDate={disabledDate}
                      locale={locale}
                      picker="month"
                      allowClear={false}
                      format={'YYYY年MM月'}
                      defaultValue={moment(bottomDate)}
                      onOpenChange={setOpen}
                      suffixIcon={
                        open ? (
                          <CaretUpOutlined size={16} />
                        ) : (
                          <CaretDownOutlined
                            size={16}
                            style={{ color: '#333333' }}
                          />
                        )
                      }
                    />
                  </div>
                  <div className="header-title">总制造费用构成</div>
                </div>
              </div>

              {thirdData?.length > 0 && (
                <div className="third-left-chart" id="third-left-chart"></div>
              )}
              {thirdData?.length == 0 && (
                <div className="empty">
                  <img src={empty}></img>
                  <div>暂无数据</div>
                </div>
              )}
            </div>
            <div className="third-content-rigth">
              <div className="third-content-header">
                制造费用影响因素分析
              </div>
              <div className="duration-warn">
                <img src={warn} className="warn-img"></img>
                <div>制造费用与产能呈</div>
                <div className="warn-text">负相关</div>
                {/* {[1, 2]?.map((v, index) => {
                  return index < 2 ? (
                    <>
                      <div className="warn-text" key={index}>
                        {v}
                      </div>
                      和
                    </>
                  ) : (
                    <div className="warn-text" key={index}>
                      {v}
                    </div>
                  );
                })} */}
              </div>
              <div className="third-left-chart" id="third-rigth-chart"></div>
            </div>
          </div>
        </div>
        <div className="minimal-invasive">
          <div className="minimal-invasive-header">降本专项情况</div>
          <div className="minimal-invasive-content">
            <div className="left">
              <div>{handleToFixed(tabData?.mngmtInnvtProgramYi?.total, 0)}</div>
              <div className="title">微创新项目</div>
            </div>
            <div className="left">
              <div>
                {handleToFixed(tabData?.mngmtInnvtProgramYi?.completed, 0)}
              </div>
              <div className="title">结题项目</div>
            </div>
            <div className="left">
              <div>
                {handleToFixed(
                  tabData?.mngmtInnvtProgramYi?.totalReducedRate * 100,
                  2,
                )}
                <span className="unit">%</span>
              </div>
              <div className="title">项目达成降本比例</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderTab2 = () => {
    return (
      <div className="cost-tab2">
        <div className="cost-tab2-first">
          <div className="first-title">原材料采购成本分析</div>
          <div className="first-content">
            {tabData2?.topValue?.map((v, i) => {
              return (
                <div className="first-content-item">
                  <div>
                    {' '}
                    <div className="item-header">
                      <div>
                        <div>{v?.name}</div>
                        <img
                          src={v?.nowValue > v?.lastValue ? up1 : down1}
                        ></img>
                      </div>
                    </div>
                    <div className="item-text">
                      <div className="name">当月采购价</div>
                      <div className="num">
                        {handleToFixed(v?.nowValue, 2)}元
                      </div>
                    </div>
                    <div className="item-text">
                      <div className="name">上一年均价</div>
                      <div className="num">
                        {handleToFixed(v?.lastValue, 2)}元
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="cost-tab2-second">
          <div className="second-chart">
            <div className="second-chart-header">
              苍术采购成本趋势 <span>单位：元/千克</span>
              <span>本年度每月采购单价 - 上一年度均价</span>
            </div>
            {/* <div className="chart" id="chart1"></div> */}
            {bottomData?.csBipProcurements && (
              <div className="chart" id="chart1"></div>
            )}
            {!bottomData?.csBipProcurements && (
              <div className="empty">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
        </div>
        <div className="cost-tab2-second">
          <div className="second-chart">
            <div className="second-chart-header">
              半夏采购成本趋势 <span>单位：元/千克</span>
              <span>本年度每月采购单价 - 上一年度均价</span>
            </div>

            {bottomData?.bxProcurements && (
              <div className="chart" id="chart2"></div>
            )}
            {!bottomData?.bxProcurements && (
              <div className="empty">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
        </div>
        <div className="cost-tab2-second">
          <div className="second-chart">
            <div className="second-chart-header">
              茯苓采购成本趋势 <span>单位：元/千克</span>
              <span>本年度每月采购单价 - 上一年度均价</span>
            </div>
            {bottomData?.flProcurements && (
              <div className="chart" id="chart3"></div>
            )}
            {!bottomData?.flProcurements && (
              <div className="empty">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
        </div>
        <div className="cost-tab2-second">
          <div className="second-chart">
            <div className="second-chart-header">
              白芷采购成本趋势 <span>单位：元/千克</span>
              <span>本年度每月采购单价 - 上一年度均价</span>
            </div>
            {bottomData?.bzProcurements && (
              <div className="chart" id="chart4"></div>
            )}
            {!bottomData?.bzProcurements && (
              <div className="empty">
                <img src={empty}></img>
                <div>暂无数据</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  console.log(anchorId, 'aaaa');
  return (
    <div className="cost">
      <ContentHeader
        defaultValue={dayjs(date)}
        onChange={handleDateChange}
        title="成本管理"
        info="涪陵制药厂成本分析"
      />
      <div className="cost-content">
        <div className="cost-content-top">
          <div className="cost-content-top-title">单盒成本分析</div>
          {/* 隐藏分析指引 */}
          {/* <GuideBtn value="这里展示获取到的「分析指引」相关的一些说明描述，根据需求提供的内容进行展示。这里展示获取到的「分析指引」相关的一些说明描述，根据需求提供的内容进行展示。" /> */}
        </div>
        <div className="cost-content-body">
          <Row gutter={[16, 20]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="cost-content-body-l">
                <div className="cost-content-body-title">
                  单盒成本拆解<span>点击数字卡片可查看详细分析，单位：元</span>
                </div>
                <div className="cost-content-body-cost">
                  <div>
                    <div className="cost-content-body-cost-num1">
                      {convertToFixed(costManager.costMonth)}
                    </div>
                    <div className="cost-content-body-cost-text">
                      当月单盒成本
                    </div>
                  </div>
                  <div className="cost-content-body-cost-operator">=</div>
                  <div
                    onClick={handleChange('createCost')}
                    className={
                      anchorId === 'createCost' ? 'active active-card' : 'active-card'
                    }
                  >
                    <div className="cost-content-body-cost-num2">
                      {convertToFixed(costManager.createCost)}
                    </div>
                    <div className="cost-content-body-cost-text">
                      单盒制造费用
                    </div>
                  </div>
                  <div className="cost-content-body-cost-operator">+</div>
                  <div
                    onClick={handleChange('rawMaterial')}
                    className={
                      anchorId === 'rawMaterial' ? 'active active-card' : 'active-card'
                    }
                  >
                    <div className="cost-content-body-cost-num2">
                      {convertToFixed(costManager.rawMaterialCost)}
                    </div>
                    <div className="cost-content-body-cost-text">
                      原辅料单盒成本
                    </div>
                  </div>
                  <div className="cost-content-body-cost-operator">+</div>
                  <Tooltip overlayInnerStyle={{ padding: '4px 28px', borderRadius: 6 }} title="功能建设中" color="#000000">
                    <div className='card'>
                      <div className="cost-content-body-cost-num2">
                        {convertToFixed(costManager.packageMaterialCost)}
                      </div>
                      <div className="cost-content-body-cost-text">
                        包装单盒成本
                      </div>
                    </div>
                  </Tooltip>
                  <div className="cost-content-body-cost-operator">+</div>
                  <Tooltip overlayInnerStyle={{ padding: '4px 28px', borderRadius: 6 }} title="功能建设中" color="#000000">
                    <div className='card'>
                      <div className="cost-content-body-cost-num2">
                        {convertToFixed(costManager.personCost)}
                      </div>
                      <div className="cost-content-body-cost-text">
                        直接人工成本
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="cost-content-body-r">
                <div className="cost-content-body-header">
                  <div className="cost-content-body-title">单盒成本月趋势</div>
                  <div
                    className="cost-content-body-btn"
                    onClick={() => setDrawerShow(true)}
                  >
                    放大查看
                  </div>
                </div>
                <div
                  onClick={() => setDrawerShow(true)}
                  className="cost-rate-chart"
                  id="cost-rate-chart"
                ></div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cost-line"></div>
        {anchorId === 'createCost' && renderTab1()}
        {anchorId === 'rawMaterial' && renderTab2()}
      </div>

      <Drawer
        // title=""
        placement="right"
        onClose={() => setDrawerShow(false)}
        size="large"
        open={drawerShow}
      >
        <div className="dash-Drawer">
          <div className="dash-Drawer-header">
            <div className="title">
              单盒成本月趋势
              <span className="unit">单位：元</span>
            </div>
            <CloseCircleFilled
              onClick={() => setDrawerShow(false)}
              style={{ color: '#BEBEBE' }}
            ></CloseCircleFilled>
          </div>
          <div className="cost-Drawer-chart" id="cost-Drawer-chart"></div>
        </div>
      </Drawer>
    </div>
  );
};
export default definePage(OrganizeBrain);
