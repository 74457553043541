import { createServices } from '@vs/vsf-boot';
export default createServices({
  PassRateController_findByAccessoriesPassRate_ead879: {
    method: 'post',
    url: '/api/data-analysis/find-by-accessories-pass-rate',
    parameterFomatter: (data?: {
      applydate: string;
      passRateEnum: PassRateEnum;
    }) => data,
    responseFormatter: (_, __, data?: TotalPassRatePlusVo89B7A8) => data,
  },
});
