import "./index.less";

import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import vsf, { definePage } from "@vs/vsf-boot";
import { Anchor, DatePicker, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import classnames from "classnames";
import dayjs from "dayjs";
import * as echarts from "echarts";
import moment from "moment";
import React, { useEffect, useState } from "react";

import chevronRigh from "@/assets/images/chevron-righ.png";
import down from "@/assets/images/down.png";
import empty from "@/assets/images/empty.png";
import righ from "@/assets/images/righ.png";
import up from "@/assets/images/up.png";
import warn from "@/assets/images/warn.png";
import {
  AnchorList,
  chartBarSelect,
  echartsColor,
  echartsColorList,
  echartsXData,
  echartsXYColor,
  echartsXYSize,
  SelectTypeName,
} from "@/config";
import { handleToFixed } from "@/utils";

const LiduWorkshop = (props) => {
  const { global } = props?.stores || {};
  const { startLoading, stopLoading } = global || {};
  const { Link } = Anchor;
  const [date, setDate] = useState(
    props.location.state?.date || dayjs()?.format("YYYY-MM")
  );
  const [data, setData] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [findOee, setFindOee] = useState<any>();
  const [currentSelect, setCurrentSelect] = useState({
    value: "BZ",
    label: "包装",
  });
  const [SelectV, setSelect] = useState(false);
  const [bottomDate, setBottomDate] = useState(
    props.location.state?.date || dayjs()?.format("YYYY-MM")
  );
  const [warnTag, setWarnTag] = useState([]);
  const [currentTab, setCurrentTab] = useState<any>("1");
  const [bottomData, setbottomData] = useState<any>();
  const [oeeData, setOeeData] = useState<any>();
  const [bottomChartData, setBottomChartData] = useState<any>();
  const [humanEfficiencyTab, setHumanEfficiencyTab] = useState<any>();

  useEffect(() => {
    if (currentTab == "2") {
      handleFindInvasive(date);
      handleQueryWorkshopProductionPage(date);
    }
  }, [currentTab, date]);

  const initData = () => {
    if (currentTab === "1") {
      startLoading();
      Promise.all([
        handleQueryMainIndexManagerEo(date),
        handleQueryWorkshopProductionPage(date),
        handleFindByYieldRateAndDevice(date),
        handleFindOee(date),
        handleQueryHumanEfficiencyTab(date),
      ]).finally(() => {
        stopLoading();
      });
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, currentTab]);

  useEffect(() => {
    currentTab == "1" && handleFindDeviceRate(bottomDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomDate, currentSelect, currentTab]);

  const handleChart = (id, chartX: any = [], dataChart: any = []) => {
    console.log(id, chartX, dataChart, "dataChart");
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      legend: {
        type: "plain",
        show: true,
        left: 0,
        top: 24,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        // selectedMode: true,
        selectedMode: false,
        // data: legendData,
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return value;
        },
      },

      grid: {
        left: 0,
        containLabel: true,
        top: 100,
        right: 0,
        bottom: 40,
      },
      xAxis: {
        type: "category",
        // scale: true,
        data: chartX,
        axisTick: {
          show: false,
          alignWithLabel: true,
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: (value) => {
            return dayjs(value)?.format("MM月");
          },
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: false,
      },
      series: dataChart,
    };
    dom && option && myChart.setOption(option);
  };
  const handleLineChart = (
    id,
    chartX,
    chartData,
    isLegend = true,
    top = 80,
    rate = 0
  ) => {
    console.log(chartX, "-------------------");
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      color: echartsColorList,
      grid: {
        left: 0,
        containLabel: true,
        top: top,
        right: 20,
        bottom: 27,
      },
      legend: {
        type: "plain",
        show: false,
        // left: 0,
        itemWidth: 12,
        itemHeight: 12,
        icon: "rect",
        selectedMode: false,
        left: 0,
        top: 24,
      },
      xAxis: {
        type: "category",
        // type: 'value',
        data: chartX,
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#E7E7E7",
          },
        },
        // axisLine: {
        //   show: isLegend,
        // },

        axisLabel: {
          formatter: (value) => {
            console.log(value, "----");
            return dayjs(value)?.format("MM月");
          },
          interval: 0,
          color: echartsXYColor,
          fontSize: echartsXYSize,
          rotate: rate,
        },
      },
      tooltip: {
        trigger: "axis",
        // show: isLegend,
        valueFormatter: (value) => {
          return value;
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          lineStyle: {
            color: echartsXYColor,
          },
        },
        scale: true,
        // min: 0,
        // max: 100,
        axisLabel: {
          formatter: "{value}",
          color: echartsXYColor,
          fontSize: echartsXYSize,
        },
        // show: isLegend,
      },
      series: chartData,
    };

    dom && option && myChart.setOption(option);
  };
  const handlepieChart = (id, chartData) => {
    const dom = document.getElementById(id);
    let myChart;
    dom && (myChart = echarts.init(dom));
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) => {
          return value;
        },
      },
      series: [
        {
          type: "pie",
          // radius: [20, 140],
          roseType: "area",
          itemStyle: {
            borderRadius: 0,
          },
          data: chartData,
          label: {
            // width: 140,
            overflow: "break",
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
  };

  const handleFindInvasive = async (date) => {
    const data =
      await vsf?.services?.LiduWorkShopController_findInvasive_f199f9({
        year: dayjs(date)?.format("YYYY"),
      });
    if (data?.code === 200) {
      console.log(data);
      setbottomData(data?.data);
    }
  };
  const handleFindByYieldRateAndDevice = async (date) => {
    const data =
      await vsf?.services?.LiduWorkShopController_findByYieldRateAndDevice_2fd9ab(
        {
          year: dayjs(date)?.format("YYYY"),
          month: dayjs(date)?.format("MM"),
        }
      );
    if (data?.code === 200) {
      console.log(data);
      setOeeData(data?.data);
      const chartX = data?.data?.liduYieldRateVos?.map(
        (v) => dayjs(v?.time)?.format("YYYY-MM")
      );
      const chartDataLits = [
        {
          value: "configuration",
          name: "配制",
          color: echartsColor?.echartsColor_E68F58,
        },
        {
          value: "filling",
          name: "灌装",
          color: echartsColor?.echartsColor_1890FF,
        },
        {
          value: "packing",
          name: "包装",
          color: echartsColor?.echartsColor_CF63D8,
        },
      ];
      const legendData = chartDataLits?.map((v) => {
        return {
          ...v,
          itemStyle: {
            color: v?.color,
          },
        };
      });
      handleChart(
        "material-content-chart",
        chartX,
        renderChart(data?.data?.liduYieldRateVos, chartDataLits, 2, 100)
        // legendData,
      );

      const chartDataLits1 = [
        {
          value: "configuration",
          name: "配制",
          color: echartsColor?.echartsColor_E68F58,
        },
        {
          value: "filling",
          name: "灌装",
          color: echartsColor?.echartsColor_1890FF,
        },
        {
          value: "packing",
          name: "包装",
          color: echartsColor?.echartsColor_CF63D8,
        },
        {
          value: "sterilization",
          name: "灭菌甩水",
          color: echartsColor?.echartsColor_647AA2,
        },
      ];
      handleChart(
        "device-content-chart",
        chartX,
        renderChart(data?.data?.liduWorkshopVos, chartDataLits1, 2, 100)
      );
    }
  };
  const handleFindDeviceRate = async (date) => {
    const data =
      await vsf?.services?.LiduWorkShopController_findDeviceRate_507c21({
        year: dayjs(date)?.format("YYYY"),
        // year: ;
        month: dayjs(date)?.format("MM"),
        deviceRateEnum: currentSelect?.value,
      });
    if (data?.code === 200) {
      setBottomChartData({
        ...data?.data,
        // maxDevice: data?.data?.maxDevice?.join('is')?.split(''),
      });
      const color = Object?.keys(echartsColor);
      const chartData = data?.data?.faultPercentageVos?.map((v, index) => {
        return {
          name: v?.deviceName,
          value: handleToFixed(v?.deviceFaultPercentage, 2),
          itemStyle: {
            color: echartsColor?.[color?.[index]],
          },
          color: echartsColor?.[color?.[index]],
        };
      });
      const arr = data?.data?.reasonVos?.map((v) => {
        return {
          ...chartData?.find((item) => {
            return v?.deviceName === item?.name;
          }),
          ...v,
        };
      });
      setWarnTag(arr);
      handlepieChart("duration-chart", chartData);
    }
  };
  const handleFindOee = async (date) => {
    const data = await vsf?.services?.LiduWorkShopController_findOee_b7c502({
      year: dayjs(date)?.format("YYYY"),
      // year: ;
      month: dayjs(date)?.format("MM"),
    });
    if (data?.code === 200) {
      console.log(data);
      setFindOee(data?.data);
    }
  };
  const handleQueryMainIndexManagerEo = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryMainIndexManagerEo_f767f3(
        {
          yearAndMonth: dayjs(date)?.format("YYYYMM"),
        }
      );
    if (data?.code === 200) {
      console.log(data);
      setData(data?.data);
    }
  };
  const handleQueryWorkshopProductionPage = async (date) => {
    const data =
      await vsf?.services?.DataOverviewController_queryWorkshopProductionPage_32655e(
        {
          yearAndMonth: dayjs(date)?.format("YYYYMM"),
        }
      );

    if (data?.code === 200) {
      // setThirdData(data?.data);
      const chartX = data?.data?.capacityProportion?.map(
        (v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")
      );
      const chartDataLits = [
        {
          value: "settingCount",
          name: "配制",
          color: echartsColor?.echartsColor_E68F58,
        },
        {
          value: "bottleCount",
          name: "灌装",
          color: echartsColor?.echartsColor_1890FF,
        },
        {
          value: "packageCount",
          name: "包装",
          color: echartsColor?.echartsColor_CF63D8,
        },
      ];
      handleChart(
        "first-left-chart",
        chartX,
        renderChart(data?.data?.capacityProportion, chartDataLits, 2, 100)
      );
      handleChart(
        "first-rigth-chart",
        chartX,
        renderChart(data?.data?.capacityMaxProportion, chartDataLits)
      );
    }
  };
  const handleQueryHumanEfficiencyTab = async (date) => {
    const data = await vsf?.services?.queryHumanEfficiencyTab({
      yearAndMonth: dayjs(date)?.format("YYYYMM"),
    });

    if (data?.code === 200) {
      const value = data?.data;
      setHumanEfficiencyTab(value || {});
      handleLineChart(
        "charts1",
        renderData(value?.humanEfficiencyRateLine)?.chartx,
        [
          renderChartsData(
            renderData(value?.humanEfficiencyRateLine)?.data,
            "人效比",
            true
          ),
        ],
        false,
        40
      );
      handleLineChart(
        "charts2",
        renderData(value?.humanWorkTimeLine)?.chartx,
        [
          renderChartsData(
            renderData(value?.humanWorkTimeLine)?.data,
            "人均工作时间",
            true
          ),
        ],
        false,
        40
      );
      handleLineChart(
        "charts3",
        renderData(value?.humanCountLine)?.chartx,
        [
          renderChartsData(
            renderData(value?.humanCountLine)?.data,
            "人数",
            true
          ),
        ],
        false,
        40
      );
    }
  };

  const handleDateChange = (value) => {
    setDate(dayjs(value)?.format("YYYY-MM"));
  };

  const handleBDateChange = (value) => {
    setBottomDate(dayjs(value)?.format("YYYY-MM"));
  };

  const handleSelectChange = (value) => {
    setCurrentSelect(chartBarSelect?.find((v) => v?.value == value) as any);
  };

  const renderChart = (data, list, ToFixed = 0, rate = 1) => {
    return list?.map((v) => {
      return {
        data: data?.map((item) =>
          handleToFixed(item?.[v?.value] * rate, ToFixed)
        ),
        name: v?.name,
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          // color: v?.color, // 设置折线的颜色为红色
          width: 2, // 设置折线宽度为 2
          // ... 可以设置更多线条样式属性
        },
      };
    });
  };
  const renderChartsData = (data, name, isAreaStyle = false) => {
    const areaStyleObj = { areaStyle: {} };
    isAreaStyle &&
      (areaStyleObj.areaStyle = {
        color: "#EFF3FF",
        emphasis: { color: "#EFF3FF" },
      });
    return {
      data: data,
      type: "line",
      smooth: true,
      name,
      showSymbol: false,
      lineStyle: {
        width: 2, // 设置折线宽度为 2
        // ... 可以设置更多线条样式属性
      },
      ...areaStyleObj,
    };
  };
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };
  const handleToCost = () => {
    vsf.navigateTo("/dashboard/production/overview");
  };

  const handleToDashboard = () => {
    vsf.navigateTo("/dashboard/overview");
  };

  const handleIsNumber = (value) => {
    // console.log(value, '----');
    return typeof value === "number" || typeof value === "string";
  };
  const renderData = (data) => {
    return {
      chartx: data?.map((v) => dayjs(v?.yearAndMonth)?.format("YYYY-MM")),
      data: data?.map((v) => handleToFixed(v?.proportion, 2)),
    };
  };
  return (
    <div className="LiduWorkshop-container">
      <div className="header">
        <div className="Breadcrumb">
          <div onClick={handleToDashboard}>管理驾驶仓</div>
          <img src={chevronRigh} className="Breadcrumb-img"></img>
          <div onClick={handleToDashboard}>生产管理</div>
          <img src={chevronRigh} className="Breadcrumb-img"></img>
          <div onClick={handleToCost} style={{ cursor: "pointer" }}>
            生产分析
          </div>
          <img src={chevronRigh} className="Breadcrumb-img"></img>
          <div className="Breadcrumb-name">李渡车间生产分析</div>
        </div>
        <div className="date">
          <div className="date-title">时间</div>
          <DatePicker
            onChange={handleDateChange}
            disabledDate={disabledDate}
            locale={locale}
            picker="month"
            allowClear={false}
            format={"YYYY年-MM月"}
            defaultValue={moment(date)}
            onOpenChange={setOpen}
            suffixIcon={
              open ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined style={{ color: "#333333" }} />
              )
            }
            // suffixIcon={open ? <CaretUpOutlined /> : <CaretDownOutlined style={{ color: '#333333' }} />}
          />
        </div>
      </div>

      <div className="LiduWorkshop-first">
        <div className="first-header">
          <div className="first-header-bg"></div>
          <div className="header-content">
            <div className="produce">
              <div className="header-content-title produce-title">生产排产</div>
              <div className="produce-bottom">
                <div className="header-content-div produce-div">排产率</div>
                {/* <img src={righ} className="righ"></img> */}
              </div>
            </div>
            <div className="header-content-img produce-righ">
              <img src={righ} className="righ"></img>
            </div>
            <div className="material">
              <div className="header-content-title material-title">
                物料采购
              </div>
              <div className="material-bottom">
                <div className="header-content-div material-div">
                  采购完成率
                </div>
                {/* <img src={righ} className="righ"></img> */}
              </div>
            </div>
            <div className="header-content-img material-righ">
              <img src={righ} className="righ"></img>
            </div>
            <div className="workshop">
              <div className="workshop-title">车间生产</div>
              <div className="workshop-content">
                <div className="workshop-content-top">
                  {/* <img src={warn} className="workshop-img"></img> */}
                  {/* <div>完成率低，描述描述</div> */}
                </div>
                <div className="num">
                  <span>
                    {handleIsNumber(data?.workshopProduction)
                      ? handleToFixed(data?.workshopProduction * 100, 2)
                      : "-"}
                  </span>
                  <span style={{ fontSize: 18 }}>%</span>
                </div>
                <div className="rate">最差周生产计划完成率</div>
                <div className="workshop-bottom">生产部、车间、设备部</div>
              </div>
            </div>
            <div className="header-content-img warehouse-righ">
              <img src={righ} className="righ"></img>
            </div>
            <div className="warehouse">
              <div className="header-content-title warehouse-title">
                仓储入库、流入市场
              </div>
              <div className="warehouse-bottom">
                {/* <img src={righ} className="righ"></img> */}
                <div className="header-content-div warehouse-div">库存量</div>
              </div>
            </div>
          </div>
        </div>
        <div className="first-content">
          <div
            // className="first-content-left"
            className={classnames(`first-content-left`, {
              activeTab: currentTab === "1",
            })}
            onClick={() => {
              setCurrentTab("1");
            }}
          >
            <div className="first-content-header">
              产能利用率{" "}
              <span>
                口径说明：当月产能÷截止当月最大产能<span>单位：%</span>
              </span>
            </div>
            <div className="first-left-chart" id="first-left-chart"></div>
          </div>
          <div
            //  className="first-content-rigth"
            className={classnames(`first-content-rigth`, {
              activeTab: currentTab === "2",
            })}
            onClick={() => {
              setCurrentTab("2");
            }}
          >
            <div className="first-content-header">
              各工序历史最大日均产能 <span>单位：件</span>
            </div>
            <div className="first-left-chart" id="first-rigth-chart"></div>
          </div>
        </div>

        {currentTab == "1" && (
          <div>
            {" "}
            <Anchor
              getContainer={() => {
                return document.getElementById("layout_main") as any;
              }}
              offsetTop={0}
              className="Anchor"
            >
              {AnchorList?.map((v, i) => (
                <Link href={v?.href} key={v?.href} title={v?.label} />
              ))}
            </Anchor>
          </div>
        )}
      </div>
      {currentTab == "1" && (
        <>
          {" "}
          <div className="material" id="material">
            <div className="material-header">物料</div>
            <div className="material-content">
              <div className="material-content-title">
                收得率 <span>单位：%</span>
              </div>
              <div
                className="material-content-chart"
                id="material-content-chart"
              ></div>
            </div>
          </div>
          <div className="device" id="device">
            <div className="material-header">设备</div>
            <div className="device-content">
              <div className="device-content-left">
                <div className="material-content-title">
                  {dayjs(date)?.format("MM")}月各工序平均设备OEE率
                </div>
                <div className="device-left-content">
                  <div className="rate">
                    <div className="num">
                      {handleIsNumber(findOee?.lastYearRate)
                        ? handleToFixed(findOee?.oeeRate * 100, 2)
                        : "-"}
                      {/* {handleToFixed(findOee?.oeeRate * 100, 2)} */}
                    </div>
                    <div className="unit">%</div>
                  </div>
                  <div className="unit">
                    较上年
                    {handleIsNumber(findOee?.lastYearRate) ? (
                      <>
                        <img
                          style={{ marginInline: 4 }}
                          src={findOee?.lastYearRate > 0 ? up : down}
                        ></img>
                        <span>
                          {handleToFixed(
                            Math?.abs(findOee?.lastYearRate) * 100,
                            2
                          )}
                          %
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
              <div className="device-content-rigth">
                <div className="first-content-header device-rigth-header">
                  各工序设备OEE{" "}
                  <span>
                    工序：配制 - 灌装 - 灭菌甩水 - 包装 <span>单位：%</span>
                  </span>
                </div>
                {oeeData?.minLidu && (
                  <div className="warn">
                    <img src={warn} className="warn-img"></img>
                    <div>工序瓶颈在 </div>
                    <div className="warn-text">
                      {SelectTypeName[oeeData?.minLidu]}
                    </div>
                  </div>
                )}
                <div
                  className="device-content-chart"
                  id="device-content-chart"
                ></div>
              </div>
            </div>
            <div className="duration">
              <div className="duration-header">
                <div className="header-date">
                  <DatePicker
                    onChange={handleBDateChange}
                    disabledDate={disabledDate}
                    locale={locale}
                    picker="month"
                    allowClear={false}
                    format={"YYYY年-MM月"}
                    defaultValue={moment(bottomDate)}
                    onOpenChange={setOpen}
                    suffixIcon={
                      open ? (
                        <CaretUpOutlined size={16} />
                      ) : (
                        <CaretDownOutlined
                          size={16}
                          style={{ color: "#333333" }}
                        />
                      )
                    }
                    // suffixIcon={open ? <CaretUpOutlined /> : <CaretDownOutlined style={{ color: '#333333' }} />}
                  />
                  {/* <div className="icon"></div> */}
                </div>
                <div className="picker">
                  <Select
                    defaultValue={currentSelect?.value}
                    style={{ width: 120 }}
                    onChange={handleSelectChange}
                    options={chartBarSelect}
                    // onOpenChange={setOpen}
                    onDropdownVisibleChange={() => {
                      // console.log('我打开');
                      setSelect(!SelectV);
                    }}
                    // onDeselect={() => setSelect(false)}
                    suffixIcon={
                      SelectV ? (
                        <CaretUpOutlined size={16} />
                      ) : (
                        <CaretDownOutlined
                          size={16}
                          style={{ color: "#333333" }}
                        />
                      )
                    }
                  />
                </div>
                <div className="header-title">工序下设备故障时长占比</div>
              </div>
              <div className="duration-content">
                {bottomChartData?.reasonVos && (
                  <>
                    <div className="duration-left">
                      {bottomChartData?.maxDevice && (
                        <div className="duration-warn">
                          <img src={warn} className="warn-img"></img>
                          <div>{currentSelect?.label}故障设备多为 </div>
                          {bottomChartData?.maxDevice?.map((v, index) => {
                            return index <
                              bottomChartData?.maxDevice?.length - 1 ? (
                              <>
                                <div className="warn-text" key={index}>
                                  {v}
                                </div>
                                和
                              </>
                            ) : (
                              <div className="warn-text" key={index}>
                                {v}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="duration-chart" id="duration-chart"></div>
                    </div>
                    <div className="duration-rigth">
                      {warnTag?.map((v: any, index) => {
                        // console.log(v, '-----');
                        return (
                          <div className="rigth-item" key={index}>
                            <div className="item-header">
                              {" "}
                              <div
                                className="garden"
                                style={{ backgroundColor: v?.color }}
                              ></div>
                              {v?.deviceName}总故障原因
                            </div>
                            {v?.reasonTypeVo?.map((item, i) => (
                              <div className="item-item" key={i}>
                                <div>{handleToFixed(item?.faultRate, 2)}%</div>
                                <div className="tag">{item?.repTypeName}</div>
                                <div>{item?.faultReason}</div>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {!bottomChartData?.reasonVos && (
                  <div className="empty">
                    <img src={empty}></img>
                    <div>暂无数据</div>
                  </div>
                )}
              </div>
            </div>
            <div className="efficiency" id="efficiency">
              <div className="material-header">人效</div>
              <div className="first-content">
                <div className="first-first">
                  <div className="first-num">
                    {handleToFixed(humanEfficiencyTab?.monthProduct, 0)}{" "}
                    <span>支</span>
                  </div>
                  <div className="unit">月产量</div>
                </div>
                <div className="first-second">=</div>
                <div className="first-third">
                  {" "}
                  <div className="first-num">
                    {handleToFixed(humanEfficiencyTab?.humanEfficiencyRate, 0)}{" "}
                    <span>支/小时</span>
                  </div>
                  <div className="unit">人效比</div>
                </div>
                <div className="first-second">x</div>
                <div className="first-third">
                  {" "}
                  <div className="first-num">
                    {handleToFixed(humanEfficiencyTab?.humanWorkTime, 0)}{" "}
                    <span>小时/人</span>
                  </div>
                  <div className="unit">人均工作时长</div>
                </div>
                <div className="first-second">x</div>
                <div className="first-third">
                  <div className="first-num">
                    {handleToFixed(humanEfficiencyTab?.humanCount, 0)}{" "}
                    <span>人</span>
                  </div>
                  <div className="unit">人数</div>
                </div>
              </div>
              <div className="second-energ-first-content">
                <div className="energ-first-content-left">
                  <div className="header">人效比</div>
                  <div className="left-content">
                    <div className="num">
                      {handleToFixed(
                        humanEfficiencyTab?.humanEfficiencyRate,
                        0
                      )}{" "}
                      <span>支/小时</span>
                    </div>
                    <div className="left-content-rate">
                      <div>年度</div>
                      <div className="text">同比浮动量</div>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          humanEfficiencyTab?.humanEfficiencyRateYearCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          humanEfficiencyTab?.humanEfficiencyRateYearCompare,
                          2
                        )}
                        %
                      </span>
                    </div>
                    <div className="left-content-rate">
                      <div>月度</div>
                      <div className="text">环比浮动量</div>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          humanEfficiencyTab?.humanEfficiencyRateMonthCompare >
                          0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          humanEfficiencyTab?.humanEfficiencyRateMonthCompare,
                          2
                        )}
                        %
                      </span>
                    </div>
                  </div>
                </div>
                <div className="energ-first-content-rigth">
                  <div className="rigth-header">
                    人效比月趋势 <span>单位：支/小时</span>
                  </div>
                  {humanEfficiencyTab?.humanEfficiencyRateLine ? (
                    <div className="rigth-chart" id="charts1"></div>
                  ) : (
                    <div className="empty1">
                      <img src={empty}></img>
                      <div>暂无数据</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="second-energ-first-content">
                <div className="energ-first-content-left">
                  <div className="header">人均工作时长</div>
                  <div className="left-content">
                    <div className="num">
                      {handleToFixed(humanEfficiencyTab?.humanWorkTime, 0)}{" "}
                      <span>小时/人</span>
                    </div>
                    <div className="left-content-rate">
                      <div>年度</div>
                      <div className="text">同比浮动量</div>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          humanEfficiencyTab?.humanWorkTimeYearCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          humanEfficiencyTab?.humanWorkTimeYearCompare,
                          2
                        )}
                        %
                      </span>
                    </div>
                    <div className="left-content-rate">
                      <div>月度</div>
                      <div className="text">环比浮动量</div>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          humanEfficiencyTab?.humanWorkTimeMonthCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          humanEfficiencyTab?.humanWorkTimeMonthCompare,
                          2
                        )}
                        %
                      </span>
                    </div>
                  </div>
                </div>
                <div className="energ-first-content-rigth">
                  <div className="rigth-header">
                    人均工作时长月趋势 <span>单位：小时/人</span>
                  </div>
                  {humanEfficiencyTab?.humanWorkTimeLine ? (
                    <div className="rigth-chart" id="charts2"></div>
                  ) : (
                    <div className="empty1">
                      <img src={empty}></img>
                      <div>暂无数据</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="second-energ-first-content">
                <div className="energ-first-content-left">
                  <div className="header">人数</div>
                  <div className="left-content">
                    <div className="num">
                      {handleToFixed(humanEfficiencyTab?.humanCount, 0)}{" "}
                      <span>人</span>
                    </div>
                    <div className="left-content-rate">
                      <div>年度</div>
                      <div className="text">同比浮动量</div>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          humanEfficiencyTab?.humanCountYearCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          humanEfficiencyTab?.humanCountYearCompare,
                          2
                        )}
                        %
                      </span>
                    </div>
                    <div className="left-content-rate">
                      <div>月度</div>
                      <div className="text">环比浮动量</div>
                      <img
                        style={{ marginInline: 4 }}
                        src={
                          humanEfficiencyTab?.humanCountMonthCompare > 0
                            ? up
                            : down
                        }
                      ></img>
                      <span>
                        {handleToFixed(
                          humanEfficiencyTab?.humanCountMonthCompare,
                          2
                        )}
                        %
                      </span>
                    </div>
                  </div>
                </div>
                <div className="energ-first-content-rigth">
                  <div className="rigth-header">
                    人数月趋势 <span>单位：人</span>
                  </div>
                  {humanEfficiencyTab?.humanCountLine ? (
                    <div className="rigth-chart" id="charts3"></div>
                  ) : (
                    <div className="empty1">
                      <img src={empty}></img>
                      <div>暂无数据</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {currentTab == "2" && (
        <div className="minimal-invasive">
          <div className="minimal-invasive-header">微创新专项漏斗</div>
          <div className="minimal-invasive-content">
            <div className="left">
              <div>{bottomData?.total}</div>
              <div className="title">微创新项目</div>
            </div>
            <div className="left">
              <div>{bottomData?.completed}</div>
              <div className="title">结题项目</div>
            </div>
            <div className="left">
              <div>
                {handleToFixed(bottomData?.totalReducedRate * 100, 2)}
                <span className="unit">%</span>
              </div>
              <div className="title">项目达成降本比例</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default definePage(LiduWorkshop);
