import _ from "lodash";
import * as XLSX from "xlsx";

const dc = XLSX.utils.decode_cell,
  ec = (r: number, c: number) => {
    return XLSX.utils.encode_cell({ r: r, c: c });
  };

type Range = {
  min: {
    r: number;
    c: number;
  };
  max: {
    r: number;
    c: number;
  };
};

/**
 * Find a sheet in the workbook by name, and return an object with keys
 * `sheet` and `range`, where `range` is an object describing the valid cells
 * of the sheet, like `{min: {r: 1, c: 1}, max: {r: 5, c:5}}`.
 */
export function findSheet(workbook: XLSX.WorkBook, sheetName: string) {
  // console.log("workbook", workbook);
  const sheet = workbook.Sheets[sheetName];
  const data = XLSX.utils.sheet_to_json(sheet, {
    header: 1,
    defval: null,
  });

  // const repArr = data?.map((v: any) => {
  //   return v?.map((item) => item.replace(/\n/g, ""));
  // });
  console.log(data, sheet, "workbook");
  const range: Range = { min: { r: 0, c: 0 }, max: { r: 0, c: 0 } };

  if (!sheet) {
    return {};
  }

  // find size of the sheet
  const ref = sheet["!ref"];

  if (!ref || ref.indexOf(":") === -1) {
    throw new Error("Malformed workbook - no !ref property");
  }

  range.min = dc(ref.split(":")[0]);
  range.max = dc(ref.split(":")[1]);
  // console.log(sheet, "sheet");
  return { sheet, range, data };
}

/**
 * Find the start position of a table in the given sheet. `colMap` describes
 * the table columns as an object with key prop -> column title. Returns an
 * object with keys `columns` (maps prop -> 0-indexed column number) and
 * `firstRow`, the number of the first row of the table (will be `-1`) if the
 * table was not found.
 */
// colMap: prop -> col name
export function findTable(
  sheet: XLSX.WorkSheet,
  range: Range,
  colMap: Record<string, string>
) {
  let firstRow = -1;
  const colsToFind = _.keys(colMap).length;

  // colmap lowercase title -> prop
  const colLookup = _.reduce(
    colMap,
    (m, v, k) => {
      m[_.isString(v) ? v.toLowerCase() : v] = k;
      return m;
    },
    {} as Record<string, string>
  );

  // colmap props -> 0-indexed column
  const columns = _.reduce(
    colMap,
    (m, v, k) => {
      m[k] = -1;
      return m;
    },
    {} as Record<string, number>
  );

  // Look for header row and extract columns
  for (let r = range.min.r; r <= range.max.r - 1; ++r) {
    let colsFound = 0;

    for (let c = range.min.c; c <= range.max.c; ++c) {
      const cell = sheet[ec(r, c)];

      if (cell && cell.v !== undefined) {
        const prop = colLookup[cell.t === "s" ? cell.v.toLowerCase() : cell.v];
        if (prop) {
          columns[prop] = c;
          ++colsFound;
        }
      }
    }

    if (colsFound === colsToFind) {
      firstRow = r + 1;
      break;
    }
  }

  return { columns, firstRow };
}

const format = (value: any) => {
  if (typeof value === "number") {
    value = String(value);
  }
  if (typeof value === "string") {
    value = value.trim();
  }
  return value;
};

/**
 * Given the `cols` and `firstRow` as returned by `findTable()`, return a list
 * of objects of all table values. Continues to the end of the sheet unless
 * passed a function `stop` that takes a mapped row object as an argument and
 * returns `true` for that row.
 */
export function readTable<T>(
  sheet: XLSX.WorkSheet,
  range: Range,
  columns: Record<string, number>,
  firstRow: number,
  stop?: (row: T) => boolean
) {
  const data: T[] = [];

  for (let r = firstRow; r <= range.max.r; ++r) {
    const row = _.reduce(
      columns,
      (m, c, k) => {
        const cell = sheet[ec(r, c)];
        m[k] = cell ? format(cell.v) : null;
        return m;
      },
      {} as Record<string, any>
    ) as T;

    if (stop && stop(row)) {
      break;
    }

    data.push(row);
  }

  return data;
}

/**
 * Turn an Excel numeric date into a JavaScript Date object.
 */
export function convertExcelDate(excelDate: number) {
  return new Date((excelDate - 25569) * 86400 * 1000);
}

/**
 * Turn a JavaScript Date into an Excel numeric date
 */
export function toExcelDate(jsDate: Date) {
  return Number(jsDate.getTime() / (1000 * 60 * 60 * 24) + 25569);
}
