import { createServices } from '@vs/vsf-boot';

export default createServices({
  test: {
    method: 'get',
    url: '/api/test',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  login: {
    method: 'post',
    url: '/api/user-account/login-end',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  register: {
    method: 'post',
    url: '/api/register',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  loginCode: {
    method: 'post',
    url: '/api/user-account/get-verify-three',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  registerCode: {
    method: 'post',
    url: '/api/sendRegisterCode',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
