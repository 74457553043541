import { isEmpty } from "lodash";
import { read } from "xlsx";
import * as XLSX from "xlsx";
import { message } from "@vs/vsf-kit";
// import fs from "fs";

import { findSheet, findTable, readTable } from "./table-helper";
import { isUTF8 } from "..";

export type ExcelTable<T extends Record<string, any>> = {
  name: string;
  data: T[];
};

export const extractTables = <T extends Record<string, any>>(
  excelFile: File,
  fileNames: Record<string, string>,
  required?: (keyof T)[] | boolean
) => {
  return new Promise<any>((resolve, reject) => {
    // const fileContent = fs.readFileSync(excelFile as any, "binary");
    const reader = new FileReader();
    // reader.readAsText(excelFile, "UTF-8");
    // reader.readAsArrayBuffer(excelFile);
    let i = 0;
    reader.onload = (e) => {
      const data = e.target?.result as any;
      const btyes = new Uint8Array(data);
      let f = isUTF8(btyes);
      console.log(f, "utf-8编码");
      if (data) {
        if (!f) return message?.error("请使用utf-8编码文件");
        i < 1 && reader.readAsText(excelFile, "UTF-8");
        try {
          const workbook = read(data, {
            type: "binary",
            raw: true,
          });
          const tables = Object.keys(workbook.Sheets).map((key) => {
            const { sheet, range, data } = findSheet(workbook, key);
            const arrKey = Object.keys(sheet ?? {});
            const sliceArr = arrKey?.filter((v) => !v.includes("!"));
            const maxR = range?.max?.r ? range?.max?.r + 1 : 1;
            const maxC = range?.max?.c ? range?.max?.c + 1 : 1;
            // const length = sliceArr?.length / maxR;
            const findArr: any = [];
            Array?.from({ length: maxR })?.map((v, i) => {
              findArr?.push(sliceArr?.slice(i * maxC, (i + 1) * maxC));
            });
            const tableHeaderKey = findArr?.[0];
            const tableDataKey = findArr?.slice(1, findArr?.length);
            const findArrKey = tableHeaderKey?.map((v) => sheet && sheet[v]?.v);
            const tableData = tableDataKey?.map((v) => {
              return v?.map((item) => sheet && sheet[item]?.v)?.join(",");
            });
            return {
              excelFile,
              // tableHeaderKey: findArrKey?.join(","),
              tableHeaderKey: findArrKey,
              tableData,
              // tableData2: data?.map((v: any) => v?.join(",")),
              tableData2: data,
            };
          });
          i > 0 && resolve(tables);
          i = i + 1;
        } catch (error) {
          reject(error);
        }
      } else {
        reject();
      }
    };
    console.log(excelFile, "excelFile");
    reader.readAsArrayBuffer(excelFile);
  });
};
